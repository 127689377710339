import { createSlice } from '@reduxjs/toolkit';
import { addQuestionBank, deleteQuestionBank, getQuestionBank, getQuestionData } from 'services/store/actions/questionbankAction';

interface QusBankState {
    loading: boolean;
    questionbankData: any; // Define the type of userInfo
    error: any; // Define the type of error
    success: boolean;
    loading1: boolean;
    addquestionbank: any;
    error1: any;
    success1: boolean;
    loading2: boolean;
    deletequestionbanks: any;
    error2: any;
    success2: boolean;
    //get question data
    loading3: boolean;
    questionInfo: any;
    error3: any;
    success3: boolean;
}

const initialState: QusBankState = {
    loading: false,
    questionbankData: null,
    error: null,
    success: false,
    loading1: false,
    addquestionbank: null,
    error1: null,
    success1: false,
    loading2: false,
    deletequestionbanks: null,
    error2: null,
    success2: false,
    //question data
    loading3: false,
    questionInfo: null,
    error3: null,
    success3: false,
};

const questionSlices = createSlice({
    name: 'questionbank',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // questionbank user
            .addCase(getQuestionBank.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getQuestionBank.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.questionbankData = payload;
                state.success = true;
            })
            .addCase(getQuestionBank.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //get question data
            .addCase(getQuestionData.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(getQuestionData.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.questionInfo = payload;
                state.success3 = true;
            })
            .addCase(getQuestionData.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            // addquestionbank user
            .addCase(addQuestionBank.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(addQuestionBank.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.success1 = true;
                state.addquestionbank = payload;
            })
            .addCase(addQuestionBank.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.success1 = false;
                state.error1 = payload;
            })
            // deletequestionbank user
            .addCase(deleteQuestionBank.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(deleteQuestionBank.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.success2 = true;
                state.deletequestionbanks = payload;
            })
            .addCase(deleteQuestionBank.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.success2 = false;
                state.error2 = payload;
            });
    },
});
export default questionSlices.reducer;
