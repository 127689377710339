import React, { useEffect, useState } from 'react';
import { Form, Input, Checkbox, Button } from 'components/shared/FormComponent';
import { message, Image } from 'components/shared/AntComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import KovinaLogo from 'assets/img/logo.png';
import LoginImage from 'assets/img/login-bg-2.png';
import { userLogin } from 'services/store/actions/authAction';
import 'assets/styles/auth.scss';

type FieldType = {
    username?: string;
    password?: any;
    remember?: any;
};

const LoginPage: React.FC = () => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { userInfo, loading, error, success } = useSelector((state: any) => state.auth);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success : false;
    const [showErrmsg, setShowErrmsg] = useState(false);
    const errormsg = showErrmsg ? error : false;

    const submitForm = (values: any) => {
        const inputJson = {
            username: values.username,
            password: values.password,
        };
        dispatch(userLogin(inputJson) as any);
        setShowSuccessmsg(true);
        setShowErrmsg(true);
    };

    useEffect(() => {
        if (successmsg && userInfo?.message !== 'Failed to login') {
            history('/dashboard');
            setShowSuccessmsg(false);
        } else if (successmsg && userInfo?.messge === 'Failed to login') {
            message.error('Login failed');
        }
        if (errormsg && userInfo?.message === 'Failed to login') {
            message.error('Login failed');
            history('/');
            setShowErrmsg(false);
        }
    }, [successmsg, errormsg]);

    const navigateScreen = () => {
        history('/forgot-password');
    };

    return (
        <div className="p-0 login-container bg-white m-0">
            <div className="row m-0 p-0 h-100">
                <div className="col-md-8 ps-0 pe-0 login-bg-container ">
                    <Image src={LoginImage} preview={false} height="100%" width="100%" />
                </div>
                <div className="col-md-4 my-auto px-0 mx-auto">
                    <div className="w-75 mx-auto">
                        <Image src={KovinaLogo} className="mb-4" preview={false} />
                        <h2 className="text-primary fw-600">Login to your Account</h2>
                        <small>See what is going on with your business</small>
                        <Form form={form} onFinish={submitForm} layout="vertical" className="col-md-12 mt-4">
                            <Form.Item
                                name="username"
                                label="UserName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="Password"
                                className="mb-2"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Checkbox>Remember me</Checkbox>
                            <Form.Item>
                                <Button className="w-100 bg-primary mt-4 p-2 fs-18 fw-bold " type="primary" htmlType="submit" loading={loading}>
                                    Login
                                </Button>
                            </Form.Item>
                            <div className="text-end">
                                <a onClick={() => navigateScreen()} className="ms-auto text-end">
                                    Forgot your password?
                                </a>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
