import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'services/store/slices/authSlice';
import questionpaperReducer from 'services/store/slices/questionPaperSlice';
import questionbankReducer from 'services/store/slices/questionbankSlice';
import candidateReducer from 'services/store/slices/candidateSlice';
import examReducer from 'services/store/slices/examSlice';
import masterDataSlice from './store/slices/masterDataSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        questionbank: questionbankReducer,
        candidate: candidateReducer,
        questionpaper: questionpaperReducer,
        exam: examReducer,
        master: masterDataSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
