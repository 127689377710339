import React, { useState, useEffect } from 'react';
import { message, Spin } from 'components/shared/AntComponent';
import { Button, Form, Select, Input, InputNumber, DatePicker } from 'components/shared/FormComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionPaper } from 'services/store/actions/questionPaperAction';
import { addExam, getExamDetail } from 'services/store/actions/examAction';
import dayjs from 'dayjs';
import { getCandidateList } from 'services/store/actions/candidateAction';

const AddExam: React.FC = () => {
    const history = useNavigate();
    const [form] = Form.useForm();
    const location = useLocation();
    const dispatch = useDispatch();
    const { questionpaperData, loading: loadingQuestions } = useSelector((state: any) => state.questionpaper);
    const { candidateData, loading: loadingCandidates } = useSelector((state: any) => state.candidate);
    const { success1, loading1, error1, examDetailInfo, loading4 } = useSelector((state: any) => state.exam);
    const [questionCount, setQuestionCount] = useState(0);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : false;
    const [showErrmsg, setShowErrMsg] = useState(false);
    const errormsg = showErrmsg ? error1 : false;

    useEffect(() => {
        const inputJson = {
            Input: {
                status: 'Active',
            },
            DataFinder: {
                pagesize: 10000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: '',
            },
        };
        dispatch(getQuestionPaper(inputJson) as any);
    }, []);

    useEffect(() => {
        if (examDetailInfo?.data) {
            loadData();
        }
    }, [examDetailInfo]);

    function getCandidate() {
        const inputJson = {
            Input: {
                status: 'Active',
                candidate_type: 1,
            },
            DataFinder: {
                pagesize: 10000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: '',
            },
        };
        dispatch(getCandidateList(inputJson) as any);
    }

    useEffect(() => {
        getCandidate();
    }, []);

    const loadData = () => {
        const quesVal = examDetailInfo?.data?.associated_papers?.split(',');
        const candidateVal = examDetailInfo?.data?.associated_candidates?.split(',');
        const date = dayjs(examDetailInfo?.data?.exam_date);
        form.setFieldsValue({
            examTitle: location.state.id !== 0 ? examDetailInfo?.data?.exam_title : '',
            description: location.state.id !== 0 ? examDetailInfo?.data?.description : '',
            totalQuestions: location.state.id !== 0 ? examDetailInfo?.data?.total_marks : '',
            duration: location.state.id !== 0 ? examDetailInfo?.data?.exam_duration : '',
            questionPapers: location.state.id !== 0 ? quesVal : [],
            associateCandidates: location.state.id !== 0 ? candidateVal : [],
            examDate: location.state.id !== 0 ? date : null,
        });
    };

    const handleChangeQuestion = (value: any) => {
        if (questionpaperData && questionpaperData.AccountDetail) {
            const commonValues = questionpaperData?.AccountDetail.filter((val: any) => value.includes(val.id.toString())).map((item: any) => item.total_question);
            const sum = commonValues.map(Number).reduce((acc: any, curr: any) => acc + curr, 0);
            setQuestionCount(sum);
            form.setFieldsValue({ totalQuestions: sum });
        }
    };

    const handleChangeQuestion1 = (value: any) => {
        const question = questionpaperData?.AccountDetail.find((item: any) => item.id == value);
        if (question) {
            setQuestionCount(Math.abs(questionCount - question.total_question));
            form.setFieldsValue({ totalQuestions: Math.abs(questionCount - question.total_question) });
        }
    };

    const submitForm = (values: any) => {
        const val = values;
        const inputJson = {
            examid: location.state.id || 0,
            exam_title: val.examTitle,
            associated_papers: val.questionPapers?.toString() || '',
            description: val.description || '',
            total_marks: val.totalQuestions || '',
            exam_duration: val.duration || '',
            exam_date: val.examDate ? dayjs(val.examDate).format('YYYY-MM-DD') : '',
            associated_candidates: val.associateCandidates?.toString() || '',
        };
        dispatch(addExam(inputJson) as any);
        setShowSuccessmsg(true);
        setShowErrMsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            if (location.state.id === 0) {
                message.success('Exam created successfully');
            } else {
                message.success('Exam updated successfully');
            }

            history('/exam');
            setShowSuccessmsg(false);
        }
        if (errormsg) {
            if (errormsg?.data) {
                message.error(errormsg?.data);
            } else {
                message.error("Exam couldn't be created");
            }
            setShowErrMsg(false);
        }
    }, [successmsg, errormsg]);

    const goToPreviousScreen = () => {
        history('/exam');
    };

    const getDetail = () => {
        dispatch(getExamDetail(location.state.id) as any);
    };

    useEffect(() => {
        if (location.state.id !== 0) {
            getDetail();
        }
    }, []);

    return (
        <div>
            <div className="my-3 px-0">
                <div className="row m-0">
                    <h5 className="my-auto col text-start ps-2 text-secondary fw-bold">{location.state.id === 0 ? 'Create Exam' : 'Update Exam'}</h5>
                    <div className="ms-auto col text-end my-auto pe-2">
                        <Button type="primary" className="col-auto ms-2 btn-back" onClick={goToPreviousScreen}>
                            Back
                        </Button>
                    </div>
                </div>
            </div>
            <Spin spinning={loading4}>
                <div className="bg-white p-2">
                    <Form form={form} onFinish={submitForm} layout="vertical">
                        <div className="px-2 py-3">
                            <div className="row m-0">
                                <Form.Item className="col-md-3 col-lg-3" label="Exam Title" name="examTitle" rules={[{ required: true, message: 'This field is required' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item className="col-md-3 col-lg-3" label="Date of Exam" name="examDate" rules={[{ required: true, message: 'This field is required' }]}>
                                    <DatePicker className="w-100" format="DD-MM-YYYY" />
                                </Form.Item>
                                <Form.Item className="col" label="Description" name="description">
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="row m-0">
                                <Form.Item className="col-md-3 col-lg-3" label="Select Question papers" name="questionPapers" rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        className="w-100"
                                        mode="multiple"
                                        loading={loadingQuestions}
                                        options={questionpaperData?.AccountDetail.map((item: any) => ({
                                            label: item.title,
                                            value: item.id.toString(),
                                        }))}
                                        onChange={handleChangeQuestion}
                                        onDeselect={handleChangeQuestion1}
                                    />
                                </Form.Item>

                                <Form.Item className="col-md-3 col-lg-3 show-undisabled" label="Total Questions" name="totalQuestions">
                                    <Input disabled className='custom-disable-input'/>
                                </Form.Item>
                                <Form.Item className="col-md-3 col-lg-3" label="Duration (In Minutes)" name="duration" rules={[{ required: true, message: 'This field is required' }]}>
                                    <InputNumber className="w-100" maxLength={2} />
                                </Form.Item>
                                <Form.Item className="col-md-3 col-lg-3" label="Associate Candidates" name="associateCandidates">
                                    <Select
                                        mode="multiple"
                                        className="w-100"
                                        options={candidateData?.AccountDetail.map((item: any) => ({
                                            label: `${item.fname} ${item.lname}`,
                                            value: item.id.toString(),
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row m-0 px-3 mb-3">
                            <Button type="default" className="col-auto ms-auto bg-secondary text-white rounded-0 me-2" onClick={goToPreviousScreen}>
                                Cancel
                            </Button>
                            <Button type="primary" className="col-auto me-1" htmlType="submit" loading={loading1}>
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            </Spin>
        </div>
    );
};

export default AddExam;
