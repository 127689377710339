import React from 'react';
import { SearchOutlined } from 'components/shared/AntIcons';
import { Table, Calendar, Flex } from 'components/shared/AntComponent';
import { Input, Button } from 'components/shared/FormComponent';
import type { Dayjs } from 'dayjs';
import type { CalendarProps } from 'antd';
import ExamGraph from 'assets/img/graph.png';

const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>['mode']) => {
    console.log(value.format('YYYY-MM-DD'), mode);
};

const series = [{ name: 'Net Profit', data: [44, 55, 57, 56, 61, 58, 63, 60, 66] }];

const options = {
    chart: {
        type: 'line',
        toolbar: {
            show: false,
        },
    },
    title: {
        text: 'New Request vs Released Request',
        align: 'left',
        floating: false,
        offsetX: 10,
        offsetY: 25,
        style: {
            fontWeight: 'bold',
            FontFamily: 'Lato',
        },
    },
    legend: {
        show: true,
        position: 'top',
        align: 'right',
        horizontalAlign: 'left',
        fontFamily: 'Lato',
        markers: {
            width: 15,
            height: 3,
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'smooth',
        width: 2,
    },
    colors: ['#ffa902', '#7a6efe', '#24a8fa'],
    xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    },
    tooltip: {
        x: {
            format: 'dd/MM/yy ',
            max: 4,
        },
    },
};
const dataSource = [
    {
        key: '1',
        test_name: 'Aptitude Test',
        test_category: 'APT',
        subjects: 'General Knowledge',
    },
    {
        key: '2',
        test_name: 'Aptitude Test',
        test_category: 'APT',
        subjects: 'General Knowledge',
    },
    {
        key: '3',
        test_name: 'Aptitude Test',
        test_category: 'APT',
        subjects: 'General Knowledge',
    },
    {
        key: '4',
        test_name: 'Aptitude Test',
        test_category: 'APT',
        subjects: 'General Knowledge',
    },
];

const columns = [
    {
        title: 'Test Name',
        dataIndex: 'test_name',
        key: 'test_name',
    },
    {
        title: 'Test Category',
        dataIndex: 'test_category',
        key: 'test_category',
    },
    {
        title: 'Subjects included',
        dataIndex: 'subjects',
        key: 'subjects',
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: () => {
            return <Button type="primary" className='btn-blue'>Active</Button>;
        },
    },
];

const Dashboard: React.FC = () => (
    <div>
        <div className="row m-0 dash-row">
            <div className="col-md-9 px-0 dash-left-card">
                <div className="py-2 px-3 bg-white d-flex mb-2 shadow-sm">
                    <p className="my-auto text-dark head-text">
                        Total User Registered <span className="text-secondary fs-4 ps-2">630</span>
                    </p>
                    <Input className="col-md-3 ms-auto" prefix={<SearchOutlined size={18} />} placeholder="Search" />
                </div>
                <div>
                    <Flex gap="middle" className="stat-cards-box me-1" justify="space-between">
                        <div className="stat-card bg-blue text-white rounded col">
                            <div className="heading-card mb-3 p-2">TEST COUNT</div>
                            <div className="d-flex px-3 py-1 w-100">
                                {/* <FaFileCircleCheck size={55} color="#fff" /> */}
                                <div className="text-end ms-auto">
                                    <span className="text-white fs-5">#52</span>
                                    <br />
                                    <span>Total 41 Active Tests</span>
                                </div>
                            </div>
                            <div className="text-end pe-3">
                                <p className="my-2 sm-text">View Details</p>
                            </div>
                        </div>
                        <div className="stat-card bg-green text-white rounded col">
                            <div className="heading-card mb-3 p-2">TEST COUNT</div>
                            <div className="d-flex w-100 px-3 py-1">
                                {/* <PiSealQuestionFill size={55} color="#fff" /> */}
                                <div className="text-end ms-auto">
                                    <span className="text-white fs-5">#52</span>
                                    <br />
                                    <span>Total 41 Active Tests</span>
                                </div>
                            </div>
                            <div className="text-end pe-3">
                                <p className="my-2 sm-text">View Details</p>
                            </div>
                        </div>
                        <div className="stat-card bg-red text-white rounded col">
                            <div className="heading-card mb-3 p-2">TEST COUNT</div>
                            <div className="d-flex px-3 py-1">
                                {/* <FaUserLarge size={55} color="#fff" /> */}
                                <div className="text-end ms-auto">
                                    <span className="text-white fs-5">#52</span>
                                    <br />
                                    <span>Total 41 Active Tests</span>
                                </div>
                            </div>
                            <div className="text-end pe-3">
                                <p className="my-2 sm-text">View Details</p>
                            </div>
                        </div>
                        <div className="stat-card bg-brown text-white rounded col">
                            <div className="heading-card mb-3 p-2">TEST COUNT</div>
                            <div className="d-flex px-3 py-1">
                                {/* <HiUserGroup size={50} color="#fff" /> */}
                                <div className="text-end ms-auto">
                                    <span className="text-white fs-5">#52</span>
                                    <br />
                                    <span>Total 41 Active Tests</span>
                                </div>
                            </div>
                            <div className="text-end pe-3">
                                <p className="my-2 sm-text">View Details</p>
                            </div>
                        </div>
                        <div className="stat-card bg-blue text-white rounded col">
                            <div className="heading-card mb-3 p-2">TEST COUNT</div>
                            <div className="d-flex px-3 py-1 ">
                                {/* <FaFileCircleCheck size={50} color="#fff" /> */}
                                <div className="text-end ms-auto">
                                    <span className="text-white fs-5">#52</span>
                                    <br />
                                    <span>Total 41 Active Tests</span>
                                </div>
                            </div>
                            <div className="text-end pe-3">
                                <p className="my-2 sm-text">View Details</p>
                            </div>
                        </div>
                    </Flex>
                    <div className="my-2 d-flex">
                        <img src={ExamGraph} alt="graph" className="col-md-8" width="100%" height="auto" />
                        <div className="bg-white col ms-2"></div>
                        {/* <ReactApexChart options={options} series={series} type="area" width="100%" height="208vh" /> */}
                    </div>
                    <div className="bg-white mb-2 custom-table">
                        <h6 className="p-3 mb-0 text-start fw-bold">TEST APPLICATIONS STATISTICS</h6>
                        <Table dataSource={dataSource} columns={columns} />
                    </div>
                </div>
            </div>
            <div className="col-md-3 pe-0 h-100 dash-right-card">
                <div>
                    <Calendar className="shadow-sm" fullscreen={false} onPanelChange={onPanelChange} />
                </div>
                <div className="my-2 shadow-sm bg-white p-2 inbox-container">
                    <h6 className="text-start p-2 text-dark fw-bold">Inbox - messages for you</h6>
                    <div className="row m-0">
                        <div className="col-md-6">
                            <Button type="primary" className="w-100">
                                All
                            </Button>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Button type="primary" className="w-100">
                                All Starred
                            </Button>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Button type="primary" className="w-100">
                                Read
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <Button type="primary" className="w-100">
                                Unread
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Dashboard;
