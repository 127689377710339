import React, { useState, useEffect } from "react";
import {Form, Input, Button } from 'components/shared/FormComponent';
import { Image, } from 'components/shared/AntComponent';
import LeftImg from 'assets/img/personal-info-removebg-preview.png';
import LogoImg from 'assets/img/logo.png';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GuestInfo: React.FC = () => {
    const history = useNavigate();

    const storedGuestInfoData = localStorage.getItem("guestInfoData");
    const guestInfo = storedGuestInfoData ? JSON.parse(storedGuestInfoData) : null;
   
    const guestInfoData = guestInfo || null;
    const [next, setNext] = useState(false);

    const gotoTest = () => {
        history('/written-test');
    }

    return (
        <div className="p-3 row m-0 h-100 bg-guest">
            <div className="col-auto me-auto text-center my-auto guest-left-img">
                <div><Image src={LogoImg} preview={false} /></div>
                <Image src={LeftImg} height="600px" preview={false} />
            </div>
            <div className="col my-4 bg-white me-4 p-4 shadow">
            <div className="text-center kovina-logo"><Image src={LogoImg} preview={false} className="mb-5 mx-auto"/></div>
                <div className="d-flex text-start "><h1 className="my-auto text-secondary fw-bold">Welcome</h1>
                    <h2 className="my-auto text-primary fw-bold ms-3 text-capitalize" >{guestInfoData?.candidateinfo?.fname} {guestInfoData?.candidateinfo?.mname} {guestInfoData?.candidateinfo?.lname}</h2>
                </div>
                {!next ? <div className="h-75">
                    <div className="text-start">
                        <span className="text-start">Please make sure that the below details are correct. If not, kindly contact the administrator or HR immediately</span>
                    </div>
                    <div className="form text-start mt-4 row mx-0">
                    <div className="title-card p-2 text-start">
                        <h6 className="my-auto text-secondary fw-bold ">Personal Details</h6>
                    </div>
                        <Form layout="vertical" className="px-0 my-3">
                            <div className="row m-0 px-0" >
                                <Form.Item label="First Name" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.fname} />
                                </Form.Item>
                                <Form.Item label="Middle Name" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.mname} />
                                </Form.Item>
                                <Form.Item label="Last Name" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.lname} />
                                </Form.Item>
                                <Form.Item label="Gender" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.gender} />
                                </Form.Item>
                                <Form.Item label="DOB" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.dob} />
                                </Form.Item>
                                <Form.Item label="Email" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.email} />
                                </Form.Item>
                                <Form.Item label="Mobile No" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.mobile_number} />
                                </Form.Item>
                                <Form.Item label="Alternate Mobile No" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.alternate_number} />
                                </Form.Item>
                                <Form.Item label="City" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.city} />
                                </Form.Item>
                                <Form.Item label="State" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.state} />
                                </Form.Item>
                                <Form.Item label="Country" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.country} />
                                </Form.Item>
                                <Form.Item label="zip" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.zip} />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="title-card p-2 text-start">
                        <h6 className="my-auto text-secondary fw-bold ">Education Details</h6>
                    </div>
                    <Form layout="vertical" className="px-0 my-3">
                        <div className="row m-0 px-0 text-start">
                            <span className="fw-bold mb-2 text-head">Bachelor Degree</span>
                            <Form.Item label="Course Name" className="col-md-3">
                                <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.UG_course} />
                            </Form.Item>
                            <Form.Item label="College Name" className="col-md-3">
                                <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.UG_college} />
                            </Form.Item>
                            <Form.Item label="University Name" className="col-md-3">
                                <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.UG_university} />
                            </Form.Item>
                            <Form.Item label="Percentage/CGPA" className="col-md-3">
                                <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.UG_percentage} />
                            </Form.Item>
                        </div>
                        {guestInfoData?.candidateinfo?.PG_college ?
                            <div className="row m-0 px-0 text-start">
                                <span className="fw-bold mb-2 text-head">Master Degree</span>
                                <Form.Item label="Course Name" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.PG_course} />
                                </Form.Item>
                                <Form.Item label="College Name" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.PG_college} />
                                </Form.Item>
                                <Form.Item label="University Name" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.PG_university} />
                                </Form.Item>
                                <Form.Item label="Percentage/CGPA" className="col-md-3">
                                    <Input disabled className="custom-disable-input" value={guestInfoData?.candidateinfo?.PG_percentage} />
                                </Form.Item>
                            </div> : ""}
                    </Form>
                    <div className="text-end mt-5 ">
                        <Button className="text-white bg-primary" type="primary" onClick={() => setNext(true)}>Next</Button>
                    </div>
                </div>
                    :
                    <div className="h-75 pb-4">
                        <div className="text-start mt-4">
                            <span className="text-start">Please read the instructions carefully</span>
                        </div>
                        <div className="text-start my-3 h-100">
                            <span className="fw-bold text-decoration-underline">General Instructions:</span>
                            <div className="mt-2 ">
                                <ol type="1">
                                    <li>Total duration of examination is 1 hour 30 minutes.</li>
                                    <li>The clock will be set at the server. The countdown timer in the top right corner of screen will display the remaining time available
                                        for you to complete the examination. When the timer reaches zero, the examination will end by itself.
                                        You will not be required to end or submit your examination.
                                    </li>
                                    <li>The Question Palette displayed on the right side of screen will show the status of each question using one of the
                                        following symbols:
                                    </li>
                                    <li>You can click on the next button which appears on the bottom right to move to the next question.</li>
                                    <li>You can click on the previous button which appears on the bottom left of the screen to go to previous question.</li>
                                    <li>You can click on the question number to navigate to that specific question.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <Button type="default" onClick={() => setNext(false)}>Previous</Button>
                            <Button className="bg-primary text-white" type="primary" onClick={() => gotoTest()}>Next</Button>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default GuestInfo;