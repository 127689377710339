import React, { useEffect, useState } from 'react';
import { Table, message, Popconfirm, Tooltip, Avatar } from 'components/shared/AntComponent';
import { Input, Button } from 'components/shared/FormComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { TableProps } from 'antd';
import { EditIcon } from 'assets/icons/CustomIcons';
import { DeleteFilled, SearchOutlined } from 'components/shared/AntIcons';
import dayjs from 'dayjs';
import { deleteExam, getExam } from 'services/store/actions/examAction';

interface DataType {
    key: number;
    examcode: number;
    exam_title: string;
    exam_date: any;
    total_marks: string;
    exam_duration: string;
    description: any;
}

const ExamPage: React.FC = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { examData, loading, success2, error2 } = useSelector((state: any) => state.exam);
    const [searchValue, setSearchValue] = useState('');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const data = examData?.examlist || [];
    const totalRecords = examData?.DataFinder?.totalrecords || 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrMsg, setShowErrMsg] = useState(false);
    const errMsg = showErrMsg ? (error2 ? true : false) : false;
    const avatarColors = ['#48bd97', '#d4b12a', '#4868b1', '#7dc242', '##df8967'];

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Code',
            dataIndex: 'examcode',
            key: 'examcode',
        },
        {
            title: 'Title',
            dataIndex: 'exam_title',
            key: 'exam_title',
            width: 400,
        },
        {
            title: 'Exam Date',
            dataIndex: 'exam_date',
            key: 'exam_date',
            render: (record) => {
                const date = dayjs(record.exam_date);
                return date.format('MM/DD/YYYY');
            },
        },
        {
            title: 'Total Marks',
            dataIndex: 'total_marks',
            key: 'total_marks',
            align: 'center',
        },
        {
            title: 'Duration',
            dataIndex: 'exam_duration',
            key: 'exam_duration',
            align: 'center',
        },
        {
            title: 'Candidate',
            dataIndex: 'description',
            key: 'description',
            render: (_, record: any) => {
                return (
                    <div>
                        {record?.candidates_list?.map((item: any, i: number) => {
                            return (
                                <Avatar.Group key={i}>
                                    <Avatar className="text-capitalize" style={{ backgroundColor: `${avatarColors[i % avatarColors.length]}` }}>
                                        {item.fname[0]}
                                    </Avatar>
                                </Avatar.Group>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            title: 'Exam Link',
            dataIndex: 'uid',
            key: 'uid',
            render: (_, record: any) => {
                const url = window.origin;
                return (
                    <Button type="primary" className='bg-success' value={`${url}/guest-login/${record.uid}`} onClick={() => gotoExamPage(`${url}/guest-login/${record.uid}`)}>
                        Copy Link
                    </Button>
                );
            },
            align: 'center',
        },
        {
            title: 'Action',
            dataIndex: 'tags',
            key: 'id',
            align: 'center',
            render: (_, record) => (
                <div className="d-flex w-100 justify-content-center">
                    <div className="pointer my-auto edit-icon" onClick={() => editQuestion(record)}>
                        <EditIcon size={32} />
                    </div>
                    <div className="my-auto delete-icon">
                        <Popconfirm title="Delete Exam" description="Are you sure to delete this exam?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                            <span className="pb-2 fs-17">
                                <DeleteFilled className="text-danger pointer" />
                            </span>
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
    ];

    const deleteQuestion = (val: any) => {
        dispatch(deleteExam(val.id) as any);
        setShowSuccessmsg(true);
        setShowErrMsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Exam Deleted Successfully');
            setPageIndex(1);
            getExamDetails(pageSize, 1, '');
            setShowSuccessmsg(false);
        }
        if (errMsg) {
            setShowErrMsg(false);
            message.error("Exam couldn't be deleted");
        }
    }, [successmsg, errMsg]);

    const getExamDetails = (size: number, page: number, search: string) => {
        const inputJson = {
            pagesize: size,
            currentpage: page,
            sortbycolumn: '',
            sortby: 'asc',
            searchdata: search || '',
        };
        dispatch(getExam(inputJson) as any);
    };
    useEffect(() => {
        getExamDetails(pageSize, pageIndex, searchValue);
    }, []);

    const gotoExamPage = (url: any) => {
        navigator.clipboard.writeText(url);
        message.success('url copied successfully');
    };

    const editQuestion = (val: any) => {
        history('/exam/add-exam', {
            state: {
                id: val.id,
                examData: val,
            },
        });
    };
    // Pagination configuration
    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],
        current: pageIndex,
        pageSize: pageSize,
        total: totalRecords,
        onChange: (page: number, pageSize: number) => {
            setPageIndex(page);
            setPageSize(pageSize);
            // getAnalyticsQuestion(page, pageSize, selectedLevel,searchValue);
        },
        itemRender: (current: any, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };

    const handleSearch = (e: any) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            setPageIndex(1);
            // getAnalyticsQuestion(1, pageSize, selectedLevel,e.target.value);
        }
    };
    const resetSearch = (e: any) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setSearchValue(e.target.value);
            setPageIndex(1);
            // getAnalyticsQuestion(1, pageSize, selectedLevel,e.target.value);
        }
    };

    const gotoAddScreen = () => {
        history('/exam/add-exam', {
            state: {
                id: 0,
                examData: null,
            },
        });
    };

    return (
        <div className="my-3">
            <div className="row m-0">
                <h5 className="col-md-3 text-start my-auto text-secondary fw-bold">Exam</h5>
                <div className="col-md-8 pe-1 d-flex ms-auto">
                    <div className="col-md-4 text-start pe-1 ms-auto d-flex">
                        <Input
                            prefix={<SearchOutlined />}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => handleSearch(e)}
                            onKeyUp={(e) => resetSearch(e)}
                            placeholder="Search"
                        />
                        <Button type="primary" onClick={gotoAddScreen} className="bg-primary ms-2">
                            Add
                        </Button>
                    </div>
                </div>
            </div>
            <div className="m-2 custom-table">
                <Table columns={columns} className='table-responsive' dataSource={data} rowKey="id" loading={loading} pagination={paginationConfig} />
            </div>
        </div>
    );
};

export default ExamPage;
