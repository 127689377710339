import React, { useState, useEffect } from 'react';
import { Input, Button } from 'components/shared/FormComponent';
import { Table, message, Popconfirm, TableProps } from 'components/shared/AntComponent';
import { DeleteFilled, SearchOutlined } from 'components/shared/AntIcons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getBatchDetails } from 'services/store/actions/masterDataAction';
import { EditIcon, EyeIcon } from 'assets/icons/CustomIcons';
import AddBatchModal from './modal/AddBatchModal';
import { useNavigate } from 'react-router-dom';

interface DataType {
    key: number;
    sno: number;
    Mdquestions_name: string;
    answer: string;
    level_of_difficulty: string;
    created_on: string;
}

const Batch: React.FC = () => {
    const { batchInfo, loading } = useSelector((state: any) => state.master);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const data = !loading ? batchInfo?.BatchDetail : [];
    const totalRecords = !loading ? batchInfo?.DataFinder?.totalrecords : 0;
    const [openModal, setOpenModal] = useState(false);
    const [rowData, setRowData]: any = useState(null);

    const batchOptions = !loading
        ? batchInfo?.BatchDetail?.map((item: any) => {
              return {
                  label: item.batch_name,
                  value: item.id?.toString(),
              };
          }) || []
        : [];
    batchOptions.push({ label: 'All', value: '0' });

    function getBatchData(page: number, size: number, search: string) {
        const inputJson = {
            UserInput: {
                batch_id: 0,
            },
            DataFinder: {
                pagesize: size,
                currentpage: page,
                sortbycolumn: '',
                sortby: 'desc',
                searchdata: search,
            },
        };
        dispatch(getBatchDetails(inputJson) as any);
    }

    useEffect(() => {
        getBatchData(pageIndex, pageSize, searchValue);
    }, []);

    const handleSearch = (e: any) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getBatchData(1, pageSize, e.target.value);
        }
    };
    const resetSearch = (e: any) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getBatchData(1, pageSize, e.target.value);
        }
    };
    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            render: (id: any, record: any, index: any) => {
                if (pageIndex === 1) {
                    return index + 1;
                } else {
                    return (pageIndex - 1) * pageSize + (index + 1);
                }
            },
        },
        {
            title: 'Batch No',
            dataIndex: 'batchno',
            key: 'batchno',
        },
        {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            key: 'batch_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Batch Start Date',
            dataIndex: 'batch_st_date',
            key: 'batch_st_date',
            render: (batch_st_date: any) => {
                const originalDate = new Date(batch_st_date) || null;
                return data ? originalDate?.toLocaleString() : null;
            },
        },
        {
            title: 'Batch End Date',
            dataIndex: 'batch_en_date',
            key: 'batch_en_date',
            render: (batch_en_date: any) => {
                const originalDate = new Date(batch_en_date) || null;
                return data ? originalDate?.toLocaleString() : null;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: any) => {
                return <div>{status}</div>;
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, record: any) => {
                return (
                    <div className="d-flex w-100">
                        <div className="pointer my-auto pb-3" onClick={() => editQuestion(record)}>
                            <EditIcon size={34} />
                        </div>
                        <div className="text-warning pointer eye-icon me-2 my-auto" onClick={() => viewDetails(record)}>
                            <EyeIcon size={20} />
                        </div>
                        <div className="my-auto delete-icon">
                            <Popconfirm title="Delete Question" description="Are you sure to delete this question?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                                <DeleteFilled className="text-danger pointer del-icon" />
                            </Popconfirm>
                        </div>
                    </div>
                );
            },
        },
    ];
    const viewDetails = (val: any) => {
        navigate('/batch/view-details', { state: { id: '2', data: val, option: batchOptions } });
    };
    const editQuestion = (val: any) => {
        // setRowData(val);
        // setOpenModal(true);
        navigate('/batch/add-batch', { state: { id: val.id, data: val, type: '1' } });
    };
    const deleteQuestion = (val: any) => {};

    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],
        current: pageIndex,
        pageSize: pageSize,
        total: totalRecords,
        onChange: (page: number, pageSize: number) => {
            setPageIndex(page);
            setPageSize(pageSize);
            getBatchData(page, pageSize, searchValue);
        },
        itemRender: (current: any, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };
    const showModal = () => {
        navigate('/batch/add-batch', { state: { id: 0, data: null, type: '1' } });
    };
    const closeModal = () => {
        setOpenModal(false);
        setRowData(null);
    };

    const callbackFunc = () => {
        setPageIndex(1);
        getBatchData(1, pageSize, searchValue);
    };
    return (
        <div className="my-3">
            <div className="row m-0">
                <h5 className="col-md-3 text-start text-secondary my-auto fw-bold">Batch</h5>
                <div className="col-md-8 pe-1 d-flex ms-auto">
                    <div className="col-md-4 text-start pe-0 ms-auto d-flex">
                        <Input
                            prefix={<SearchOutlined />}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => handleSearch(e)}
                            onKeyUp={(e) => resetSearch(e)}
                            placeholder="Search"
                        />
                        <Button type="primary" className="bg-primary ms-2" onClick={() => showModal()}>
                            Add
                        </Button>
                    </div>
                </div>
            </div>
            <div className="my-2 ms-2 me-1 custom-table">
                <Table columns={columns} dataSource={data} rowKey="id" loading={loading} pagination={paginationConfig} />
            </div>
            <AddBatchModal openModal={openModal} closeModal={closeModal} rowData={rowData} callbackFunc={callbackFunc} />
        </div>
    );
};

export default Batch;
