import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from 'components/pages/Dashboard';
import LayoutPage from 'components/layout/LayoutPage';
import Aptitude from 'components/question-bank/Aptitude';
import AddQuestion from 'components/question-bank/modal/AddQuestion';
import Analytics from 'components/question-bank/Analytics';
import Technical from 'components/question-bank/Technical';
import InterviewList from 'components/candidate/Interviewee';
import InternCandidate from 'components/candidate/InternCandidate';
import AddCandidateForm from 'components/candidate/modal/AddCandidateForm';
import IntervieweeDetail from 'components/candidate/interviewee-sub-screens/IntervieweeDetail';
import QuestionPaper from 'components/question-paper/QuestionPaper';
import AddQuestionPaper from 'components/question-paper/modal/AddQuestionPaper';
import ExamPage from 'components/exam/ExamPage';
import AddExam from 'components/exam/modal/AddExam';
import Project from 'components/candidate/Project';
import InternPayment from 'components/candidate/intern-sub-screens/InternPayment';
import RegistrationForm from 'components/pages/RegistrationForm';
import Batch from 'components/master-data/Batch';
import ProjectMaster from 'components/master-data/ProjectMaster';
import AddBatch from 'components/master-data/modal/AddBatch';
import ViewBatchProjectDetail from 'components/master-data/sub-screens/ViewBatchProjectDetail';

const PrivateRoute: React.FC = () => {
    return (
        <div>
            <LayoutPage>
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/aptitude/*" element={<Aptitude />} />
                    <Route path="/analytics/*" element={<Analytics />} />
                    <Route path="/technical/*" element={<Technical />} />
                    <Route path="/aptitude/add-questionbank" element={<AddQuestion />} />
                    <Route path="/analytics/add-questionbank" element={<AddQuestion />} />
                    <Route path="/technical/add-questionbank" element={<AddQuestion />} />
                    <Route path="/candidate/interviewee" element={<InterviewList />} />
                    <Route path="/candidate/internship" element={<InternCandidate />} />
                    <Route path="/candidate/project" element={<Project />} />
                    <Route path="/candidate/add-interviewee-candidate" element={<AddCandidateForm />} />
                    <Route path="/candidate/add-internship-candidate" element={<AddCandidateForm />} />
                    <Route path="/candidate/add-project-candidate" element={<AddCandidateForm />} />
                    <Route path="/candidate/internship-detail" element={<InternPayment />} />
                    <Route path="/candidate/interviewee-candidate-detail" element={<IntervieweeDetail />} />
                    <Route path="/question-paper/*" element={<QuestionPaper />} />
                    <Route path="/question-paper/add-question-paper" element={<AddQuestionPaper />} />
                    <Route path="/exam/*" element={<ExamPage />} />
                    <Route path="/exam/add-exam" element={<AddExam />} />
                    <Route path="/batch" element={<Batch />} />
                    <Route path="/batch/add-batch" element={<AddBatch />} />
                    <Route path="/project" element={<ProjectMaster />} />
                    <Route path="/project/add-project" element={<AddBatch />} />
                    <Route path="/batch/view-details" element={<ViewBatchProjectDetail />} />
                    <Route path="/project/view-details" element={<ViewBatchProjectDetail />} />
                </Routes>
            </LayoutPage>
        </div>
    );
};

export default PrivateRoute;
