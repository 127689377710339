import React, { useEffect, useState } from 'react';
import { message, Popconfirm, Spin } from 'components/shared/AntComponent';
import { DeleteFilled, PlusSquareOutlined } from 'components/shared/AntIcons';
import { Button, Form, Select, Input } from 'components/shared/FormComponent';
import { useNavigate, useLocation } from 'react-router-dom';
import { addQuestionBank, getQuestionData } from 'services/store/actions/questionbankAction';
import { useDispatch, useSelector } from 'react-redux';

const { TextArea } = Input;
const { Option } = Select;

const AddQuestion: React.FC = () => {
    const history = useNavigate();
    const [form] = Form.useForm();
    const location = useLocation();
    const dispatch = useDispatch();
    const { loading1, success1, error1, questionInfo, loading3 } = useSelector((state: any) => state.questionbank);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : false;
    const [showErrMsg, setShowErrMsg] = useState(false);
    const errormsg = showErrMsg ? error1 : false;
    const tblValues = questionInfo ? questionInfo?.data[0] : [];
    const [options, setOptions] = useState([
        {
            label: 'A',
            value: 'A',
        },
        {
            label: 'B',
            value: 'B',
        },
        {
            label: 'C',
            value: 'C',
        },
        {
            label: 'D',
            value: 'D',
        },
    ]);

    const alphabetArray = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    const addOption = () => {
        const newOptionLabel = String.fromCharCode(options.length + 65);
        const newOption: any = { label: newOptionLabel, value: newOptionLabel };
        const fomval = form.getFieldValue('option');
        const formValue = fomval?.map((item: any, i: number) => {
            return { [0]: String.fromCharCode(65 + i), option: item?.option };
        });
        form.setFieldsValue({ option: formValue });
        setOptions([...options, newOption]);
    };

    const removeOption = (ind: any) => {
        const updatedOptions = options.filter((_, index) => index !== ind);
        setOptions(updatedOptions);
        const answerValue = form.getFieldValue('answer');
        const isItemPresent = updatedOptions.find((item) => item.value === answerValue);
        if (!isItemPresent) {
            form.setFieldsValue({
                answer: '',
            });
        }
    };

    useEffect(() => {
        if (location.state.questionid !== 0) {
            dispatch(getQuestionData(location.state?.questionid) as any);
        }
    }, [location.state?.questionid]);

    const goToPreviousScreen = () => {
        if (location.state?.id === 1) {
            history('/aptitude');
        } else if (location.state?.id === 2) {
            history('/analytics');
        } else {
            history('/technical');
        }
    };

    const submitForm = async () => {
        try {
            const value = await form.validateFields();
            const val = value.option;
            const options = [];
            let selectedAns = '';
            if (val.length > 0) {
                for (let i = 0; i < val.length; i++) {
                    if (val[i][0] == value.answer) {
                        options.push({
                            option_name: val[i].option,
                            Mdoption_hint: null,
                            answer_option: true,
                        });
                        selectedAns = val[i].option;
                    } else {
                        options.push({
                            option_name: val[i].option,
                            Mdoption_hint: null,
                            answer_option: false,
                        });
                    }
                }
            }
            const inputJson = {
                Mdquestions_id: location.state.questionid || 0,
                Mdquestions_name: value.questionName,
                Mdquestions_type: '',
                level_of_difficulty: value.levelOfDifficulty,
                Mdtechnology_hint: value.description,
                questions_category: location.state?.id,
                answer: selectedAns,
                options: options,
            };
            dispatch(addQuestionBank(inputJson) as any);
            setShowSuccessmsg(true);
            setShowErrMsg(true);
        } catch (errorinfo) {
            console.log('Failed', errorinfo);
        }
    };

    const loadData = () => {
        if (location.state.questionid !== 0) {
            let ans = '';
            const option = [];
            const answerOption = [];
            for (let i = 0; i < tblValues?.options?.length; i++) {
                if (tblValues?.options[i].answer_option) {
                    ans = String.fromCharCode(65 + i);
                }
                option.push({
                    [0]: String.fromCharCode(65 + i),
                    option: tblValues?.options[i]?.option_name,
                });
                const newOptionLabel = String.fromCharCode(i + 65); // Convert 0, 1, 2, ... to A, B, C, ...
                answerOption.push({ label: newOptionLabel, value: newOptionLabel });
            }
            form.setFieldsValue({
                questionName: tblValues?.Mdquestions_name || '',
                levelOfDifficulty: tblValues?.level_of_difficulty || '',
                description: tblValues?.Mdtechnology_hint || '',
                option: tblValues ? option : ['A', 'B', 'C', 'D'],
                answer: ans || '',
            });
            setOptions(answerOption);
        } else {
            form.setFieldsValue({
                questionName: '',
                levelOfDifficulty: '',
                description: '',
                option: options.map((option) => option.value),
                answer: '',
            });
        }
    };

    useEffect(() => {
        if (questionInfo?.data) {
            loadData();
        }
    }, [questionInfo?.data]);

    useEffect(() => {
        if (successmsg) {
            setShowSuccessmsg(false);
            if (location.state.questionid === 0) {
                message.success('Question saved successfully!');
                form.resetFields();
                loadData();
            } else {
                message.success('Question updated successfully!');
                if (location.state.id === 1) {
                    history('/aptitude');
                } else if (location.state.id === 2) {
                    history('/analytics');
                } else {
                    history('/technical');
                }
                form.resetFields();
                loadData();
            }
        }
        if (errormsg) {
            setShowErrMsg(false);
            message.error("Question couldn't be saved");
        }
    }, [successmsg, errormsg]);
    const initialValues = {
        questionName: '',
        levelOfDifficulty: '',
        description: '',
        option: options.map((option) => option.value),
        answer: '',
    };
    return (
        <div>
            <div className="my-2 px-0">
                <div className="row m-0">
                    <h5 className="my-auto col text-start ps-2 text-secondary fw-bold">{location.state?.title}</h5>
                    <div className="ms-auto col text-end my-auto pe-2">
                        <Button type="primary" className="col-auto ms-2 btn-back" onClick={goToPreviousScreen}>
                            Back
                        </Button>
                    </div>
                </div>
            </div>
            <div className="bg-white mx-2">
                <Spin spinning={loading3}>
                    <Form form={form} layout="vertical" onFinish={submitForm} initialValues={initialValues}>
                        <div className="px-2 py-3">
                            <div className="row m-0">
                                <Form.Item
                                    label="Question Name"
                                    name="questionName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                                <Form.Item label="Description" name="description">
                                    <TextArea rows={3} />
                                </Form.Item>
                            </div>
                            <div className="ps-2">
                                <Form.List name="option">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Button
                                                type="default"
                                                className="btn-warning mb-3 fs-15"
                                                onClick={() => {
                                                    add();
                                                    addOption();
                                                }}
                                            >
                                              <span className='fs-18'>+</span> Add Option
                                            </Button>
                                            <div className="row m-0 dynamic-option">
                                                {fields.map((field, index) => (
                                                    <div className="col-md-6 ps-0" key={field.key}>
                                                        <Form.Item
                                                            name={[field.name, 'option']}
                                                            className={`${index >= 4 ? 'extraOption' : ''} w-100`}
                                                            label={
                                                                <div className="d-flex w-100">
                                                                    <div className="col">Option {alphabetArray[index]}</div>
                                                                    {index >= 4 && (
                                                                        <div className="ms-auto col-auto">
                                                                            <Popconfirm
                                                                                title="Delete Option"
                                                                                description="Are you sure to delete this option?"
                                                                                onConfirm={() => {
                                                                                    remove(field.name);
                                                                                    removeOption(field.name);
                                                                                }}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                            >
                                                                                <DeleteFilled className="text-danger text-end ms-auto" />
                                                                            </Popconfirm>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'This field is required',
                                                                },
                                                            ]}
                                                        >
                                                            {/* Use appropriate input component */}
                                                            <Input />
                                                        </Form.Item>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            </div>

                            <div className="row m-0">
                                <Form.Item
                                    className="col-md-3 col-lg-3"
                                    label="Level of Difficulty"
                                    name="levelOfDifficulty"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <Select
                                        getPopupContainer={(trigger: any) => trigger.parentNode}
                                        options={[
                                            {
                                                value: 'easy',
                                                label: 'Easy',
                                            },
                                            {
                                                value: 'medium',
                                                label: 'Medium',
                                            },
                                            {
                                                value: 'hard',
                                                label: 'Hard',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="col-md-3 col-lg-3 text-start"
                                    label="Choose Answer"
                                    name="answer"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <Select className="w-75">
                                        {options.map((option: any) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row m-0 px-3 ">
                            <Button type="default" className="col-auto px-4 ms-auto bg-secondary text-white rounded-0 me-2" onClick={goToPreviousScreen}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" className="px-4 col-auto me-3 mb-3 bg-success" loading={loading1}>
                                {location.state.questionid === 0 ? 'Save' : 'Update'}
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </div>
        </div>
    );
};

export default AddQuestion;
