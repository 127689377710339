import React, { useEffect } from 'react';
import { Image, Result } from 'components/shared/AntComponent';
import Logo from 'assets/img/logo.png';

const TestSuccessPage: React.FC = () => {
    const disableBackButton = () => {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = () => {
            window.history.go(1);
        };
    };
    useEffect(() => {
        disableBackButton();
        return () => {
            window.onpopstate = null; // Cleanup to avoid memory leaks
        };
    }, []);
    return (
        <div className="test-success bg-guest mt-5 pt-5">
            <div className="text-center h-100 my-auto mt-5 pt-5">
                <Image src={Logo} alt="kovina" width="auto" height="auto" preview={false} />
                <Result status="success" title="Your Test submitted successfully!" />
            </div>
        </div>
    );
};

export default TestSuccessPage;
