import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangePass, ConfirmPassword, SendEmail, userLogin, VerifyToken } from 'services/store/actions/authAction';

interface AuthState {
    loading: boolean;
    userInfo: any; // Define the type of userInfo
    error: any; // Define the type of error
    success: boolean;
    //confirm email
    loading1: boolean;
    emailInfo: any;
    error1: any;
    success1: boolean;
    //verify token
    loading3: boolean;
    tokenInfo: any;
    error3: any;
    success3: boolean;
    //reset pass
    loading4: boolean;
    resetpassInfo: any;
    error4: any;
    success4: boolean;
    //change password
    loading5: boolean;
    changePassInfo: any;
    error5: any;
    success5: boolean;
}

const initialState: AuthState = {
    loading: false,
    userInfo: null,
    error: null,
    success: false,
    //confirm email
    loading1: false,
    emailInfo: null,
    error1: null,
    success1: false,
    //verify token
    loading3: false,
    tokenInfo: null,
    error3: null,
    success3: false,
    //reset pass
    loading4: false,
    resetpassInfo: null,
    error4: null,
    success4: false,
    //changepass
    loading5: false,
    changePassInfo: null,
    error5: null,
    success5: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(userLogin.pending, (state: any) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(userLogin.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading = false;
                state.userInfo = action.payload;
                state.success = true;
            })
            .addCase(userLogin.rejected, (state: any, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
                state.success = false;
            })
            .addCase(SendEmail.pending, (state: any) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(SendEmail.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading1 = false;
                state.emailInfo = action.payload;
                state.success1 = true;
            })
            .addCase(SendEmail.rejected, (state: any, action: PayloadAction<any>) => {
                state.loading1 = false;
                state.error1 = action.payload;
                state.success1 = false;
            })
            .addCase(VerifyToken.pending, (state: any) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(VerifyToken.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading3 = false;
                state.tokenInfo = action.payload;
                state.success3 = true;
            })
            .addCase(VerifyToken.rejected, (state: any, action: PayloadAction<any>) => {
                state.loading3 = false;
                state.error3 = action.payload;
                state.success3 = false;
            })
            .addCase(ConfirmPassword.pending, (state: any) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(ConfirmPassword.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading4 = false;
                state.resetpassInfo = action.payload;
                state.success4 = true;
            })
            .addCase(ConfirmPassword.rejected, (state: any, action: PayloadAction<any>) => {
                state.loading4 = false;
                state.error4 = action.payload;
                state.success4 = false;
            })
            .addCase(ChangePass.pending, (state: any) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(ChangePass.fulfilled, (state: any, action: PayloadAction<any>) => {
                state.loading5 = false;
                state.changePassInfo = action.payload;
                state.success5 = true;
            })
            .addCase(ChangePass.rejected, (state: any, action: PayloadAction<any>) => {
                state.loading5 = false;
                state.error5 = action.payload;
                state.success5 = false;
            });
    },
});

export default authSlice.reducer;
