import { createSlice } from '@reduxjs/toolkit';
import {
    addCandidate,
    addInterviewDetails,
    getCandidateDetail,
    getCandidateInternList,
    getCandidateList,
    getCandidateProjectLists,
    getIndividualCandidate,
    getInterviewDetail,
    getPaymentDetails,
    payAmount,
    removeCandidate,
    saveRegistrationForm,
} from 'services/store/actions/candidateAction';

interface CandidateState {
    loading: boolean;
    candidateData: any; // Define the type of userInfo
    error: any; // Define the type of error
    success: boolean;
    loading1: boolean;
    addCandidateInfo: any;
    error1: any;
    success1: boolean;
    loading2: boolean;
    removeCandidateInfo: any;
    error2: any;
    success2: boolean;
    //intern
    loading3: boolean;
    internListInfo: any;
    error3: any;
    success3: boolean;
    //project list
    loading4: boolean;
    prjListInfo: any;
    error4: any;
    success4: boolean;
    //get individual candidate
    loading5: boolean;
    candidateInfo: any;
    error5: any;
    success5: boolean;
    //get candidate info
    loading6: boolean;
    candidInfo: any;
    error6: any;
    success6: boolean;
    loading7: boolean;
    addInteviewInfo: any;
    error7: any;
    success7: boolean;
    loading8: boolean;
    interviewListInfo: any;
    error8: any;
    success8: boolean;
    loading9: boolean;
    error9: any;
    success9: boolean;
    saveCandidInfo: any;
    loading10: boolean;
    error10: any;
    success10: boolean;
    saveAmtInfo: any;
    loading11: boolean;
    error11: any;
    success11: boolean;
    paymentInfo: any;
}
const initialState: CandidateState = {
    loading: false,
    candidateData: null,
    error: null,
    success: false,
    loading1: false,
    addCandidateInfo: null,
    error1: null,
    success1: false,
    loading2: false,
    removeCandidateInfo: null,
    error2: null,
    success2: false,
    //intern
    loading3: false,
    internListInfo: null,
    error3: null,
    success3: false,
    //project list
    loading4: false,
    prjListInfo: null,
    error4: null,
    success4: false,
    //get individual candidate
    loading5: false,
    candidateInfo: null,
    error5: null,
    success5: false,
    loading6: false,
    candidInfo: null,
    error6: null,
    success6: false,
    loading7: false,
    success7: false,
    addInteviewInfo: null,
    error7: null,
    loading8: false,
    interviewListInfo: null,
    error8: null,
    success8: false,
    loading9: false,
    success9: false,
    error9: null,
    saveCandidInfo: null,
    loading10: false,
    error10: null,
    success10: false,
    saveAmtInfo: null,
    loading11: false,
    error11: null,
    success11: false,
    paymentInfo: null,
};

const candidateSlice = createSlice({
    name: 'candidates',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCandidateList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getCandidateList.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.candidateData = payload;
                state.success = true;
            })
            .addCase(getCandidateList.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //intern
            .addCase(getCandidateInternList.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(getCandidateInternList.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.internListInfo = payload;
                state.success3 = true;
            })
            .addCase(getCandidateInternList.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //project
            .addCase(getCandidateProjectLists.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(getCandidateProjectLists.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.prjListInfo = payload;
                state.success4 = true;
            })
            .addCase(getCandidateProjectLists.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            // addquestionbank user
            .addCase(addCandidate.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(addCandidate.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.success1 = true;
                state.addCandidateInfo = payload;
            })
            .addCase(addCandidate.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.success1 = false;
                state.error1 = payload;
            })
            // deletequestionbank user
            .addCase(removeCandidate.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(removeCandidate.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.success2 = true;
                state.removeCandidateInfo = payload;
            })
            .addCase(removeCandidate.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.success2 = false;
                state.error2 = payload;
            })
            //get individual candidate
            .addCase(getIndividualCandidate.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(getIndividualCandidate.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.success5 = true;
                state.candidateInfo = payload;
            })
            .addCase(getIndividualCandidate.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.success5 = false;
                state.error5 = payload;
            })
            .addCase(getCandidateDetail.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(getCandidateDetail.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.success6 = true;
                state.candidInfo = payload;
            })
            .addCase(getCandidateDetail.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.success6 = false;
                state.error6 = payload;
            })
            .addCase(addInterviewDetails.pending, (state) => {
                state.loading7 = true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(addInterviewDetails.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.success7 = true;
                state.addInteviewInfo = payload;
            })
            .addCase(addInterviewDetails.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.success7 = false;
                state.error7 = payload;
            })
            .addCase(getInterviewDetail.pending, (state) => {
                state.loading8 = true;
                state.error8 = null;
                state.success8 = false;
            })
            .addCase(getInterviewDetail.fulfilled, (state, { payload }) => {
                state.loading8 = false;
                state.success8 = true;
                state.interviewListInfo = payload;
            })
            .addCase(getInterviewDetail.rejected, (state, { payload }) => {
                state.loading8 = false;
                state.success8 = false;
                state.error8 = payload;
            })
            .addCase(saveRegistrationForm.pending, (state) => {
                state.loading9 = true;
                state.error9 = null;
                state.success9 = false;
                state.saveCandidInfo = null;
            })
            .addCase(saveRegistrationForm.fulfilled, (state, { payload }) => {
                state.loading9 = false;
                state.success9 = true;
                state.saveCandidInfo = payload;
            })
            .addCase(saveRegistrationForm.rejected, (state, { payload }) => {
                state.loading9 = false;
                state.success9 = false;
                state.error9 = payload;
            })
            .addCase(payAmount.pending, (state) => {
                state.loading10 = true;
                state.error10 = null;
                state.success10 = false;
                state.saveAmtInfo = null;
            })
            .addCase(payAmount.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.success10 = true;
                state.saveAmtInfo = payload;
            })
            .addCase(payAmount.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.success10 = false;
                state.error10 = payload;
            })
            .addCase(getPaymentDetails.pending, (state) => {
                state.loading11 = true;
                state.error11 = null;
                state.success11 = false;
                state.paymentInfo = null;
            })
            .addCase(getPaymentDetails.fulfilled, (state, { payload }) => {
                state.loading11 = false;
                state.success11 = true;
                state.paymentInfo = payload;
            })
            .addCase(getPaymentDetails.rejected, (state, { payload }) => {
                state.loading11 = false;
                state.success11 = false;
                state.error11 = payload;
            });
    },
});
export default candidateSlice.reducer;
