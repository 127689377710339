import React, { useEffect, useState } from 'react';
import { Input, Button } from 'components/shared/FormComponent';
import { Table, message, Popconfirm } from 'components/shared/AntComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import type { TableProps } from 'antd';
import { deleteQuestionBank, getQuestionBank } from 'services/store/actions/questionbankAction';
import { EditIcon } from 'assets/icons/CustomIcons';
import { DeleteFilled, SearchOutlined } from 'components/shared/AntIcons';
import 'assets/styles/table.scss';

interface DataType {
    key: number;
    sno: number;
    Mdquestions_name: string;
    answer: string;
    level_of_difficulty: string;
    created_on: string;
}

const Technical: React.FC = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { questionbankData, loading, success2, error2 } = useSelector((state: any) => state.questionbank);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [selectedLevel, setSelectedLevel] = useState('all');
    const [searchValue, setSearchValue] = useState('');
    const data: DataType[] = questionbankData?.AccountDetail || [];
    const totalRecords = questionbankData?.DataFinder?.totalrecords || 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrMsg, setShowErrMsg] = useState(false);
    const errormsg = showErrMsg ? error2 : false;

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            render: (id, record, index) => {
                if (pageIndex === 1) {
                    return index + 1;
                } else {
                    return (pageIndex - 1) * pageSize + (index + 1);
                }
            },
        },
        {
            title: 'Questions',
            dataIndex: 'Mdquestions_name',
            key: 'Mdquestions_name',
            width: '55%',
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
            width: '10%',
        },
        {
            title: 'Difficulty',
            dataIndex: 'level_of_difficulty',
            key: 'level_of_difficulty',
        },
        {
            title: 'Submitted Date',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (created_on) => {
                const originalDate = new Date(created_on) || null;
                return data ? originalDate?.toLocaleString() : null;
            },
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                return (
                    <div className="d-flex w-100">
                        <div className="pointer my-auto pb-3" onClick={() => editQuestion(record)}>
                            <EditIcon size={34} />
                        </div>
                        <div className="my-auto delete-icon">
                            <Popconfirm title="Delete Question" description="Are you sure to delete this question?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                                <DeleteFilled className="text-danger pointer del-icon" />
                            </Popconfirm>
                        </div>
                    </div>
                );
            },
        },
    ];

    const editQuestion = (val: any) => {
        history('/technical/add-questionbank', {
            state: {
                title: 'Update Technical Question',
                id: 3,
                questionid: val?.id,
            },
        });
    };

    const deleteQuestion = (val: any) => {
        dispatch(deleteQuestionBank(val?.id) as any);
        setShowSuccessmsg(true);
        setShowErrMsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Question deleted successfully!');
            setShowSuccessmsg(false);
            setPageIndex(1);
            getAnalyticsQuestion(1, pageSize, selectedLevel, searchValue);
        }
        if (errormsg) {
            if (error2?.data) {
                message.error(error2?.data);
            } else {
                message.error("Question couldn't be deleted");
            }
            setShowErrMsg(false);
        }
    }, [successmsg, errormsg]);

    // Pagination configuration
    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],
        current: pageIndex,
        pageSize: pageSize,
        total: totalRecords,
        onChange: (page: number, pageSize: number) => {
            setPageIndex(page);
            setPageSize(pageSize);
            getAnalyticsQuestion(page, pageSize, selectedLevel, searchValue);
        },
        itemRender: (current: any, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };

    const gotoAddScreen = () => {
        history('/technical/add-questionbank', {
            state: {
                title: 'Add Technical Question',
                id: 3,
                questionid: 0,
            },
        });
    };

    function getAnalyticsQuestion(page: number, size: number, level: any, searchVal: any) {
        const inputJson = {
            Input: {
                status: 'Active',
                level_of_difficulty: level,
                q_typeid: 3,
            },
            DataFinder: {
                pagesize: size,
                currentpage: page,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: searchVal || '',
            },
        };
        dispatch(getQuestionBank(inputJson) as any);
    }

    useEffect(() => {
        getAnalyticsQuestion(pageIndex, pageSize, selectedLevel, searchValue);
    }, []);

    const handleSearch = (e: any) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getAnalyticsQuestion(1, pageSize, selectedLevel, e.target.value);
        }
    };
    const resetSearch = (e: any) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getAnalyticsQuestion(1, pageSize, selectedLevel, e.target.value);
        }
    };

    return (
        <div className="my-3">
            <div className="row m-0">
                <h5 className="col-md-3 text-start text-secondary my-auto fw-bold">Technical Questions</h5>
                <div className="col-md-8 pe-1 d-flex ms-auto">
                    <div className="col-md-4 text-start pe-0 ms-auto d-flex">
                        <Input
                            prefix={<SearchOutlined />}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => handleSearch(e)}
                            onKeyUp={(e) => resetSearch(e)}
                            placeholder="Search"
                        />
                        <Button type="primary" onClick={gotoAddScreen} className="bg-primary ms-2">
                            Add
                        </Button>
                    </div>
                </div>
            </div>
            <div className="my-2 ms-2 me-1 custom-table">
                <Table columns={columns} dataSource={data} rowKey="id" loading={loading} pagination={paginationConfig} />
            </div>
        </div>
    );
};

export default Technical;
