import React, { useState, useEffect } from 'react';
import { Progress, Image, Spin, Drawer, Popconfirm } from 'components/shared/AntComponent';
import { Radio, Button } from 'components/shared/FormComponent';
import Logo from 'assets/img/logo.png';
import { CheckOutlined, MenuOutlined } from 'components/shared/AntIcons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'assets/styles/test.scss';
import { submitExam } from 'services/store/actions/examAction';
import { FemaleIcon, MaleIcon } from 'assets/icons/CustomIcons';

const TestPage: React.FC = () => {
    const history = useNavigate();
    const [selected, setSelected] = useState(0);
    const [defaultChecked, setDefaultChecked] = useState('');
    const storedGuestInfoData = localStorage.getItem('guestInfoData');
    const guestInfo = storedGuestInfoData ? JSON.parse(storedGuestInfoData) : null;
    const { examsubmitInfo, loading5, error5, success5 } = useSelector((state: any) => state.exam);

    const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<{ question_id: number; option_id: number; index: number }[]>([]);
    const uniqueArray = guestInfo?.data?.filter((item: any, index: number, self: any) => index === self.findIndex((t: any) => t.id === item.id));
    const guestInfoData = uniqueArray || null;
    // const [selectedOptions, setSelectedOptions]:any = useState([] );
    const duration = Number(guestInfo?.exam_info?.exam_duration) * 60;
    const [timeLeft, setTimeLeft] = useState(duration);
    const [open, setOpen] = useState(false);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success5 : false;
    const [showErrmsg, setShowErrMsg] = useState(false);
    const errormsg = showErrmsg ? error5 : false;

    const length = uniqueArray?.length;
    const dispatch = useDispatch();

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    const disableBackButton = () => {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = () => {
            window.history.go(1);
        };
    };
    useEffect(() => {
        disableBackButton();
        return () => {
            window.onpopstate = null; // Cleanup to avoid memory leaks
        };
    }, []);
    const formatTime = (time: number) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };
    const handlePrevious = () => {
        const prevQues = selected - 1;
        prevQues >= 0 && setSelected(prevQues);
    };

    const handleNext = () => {
        const nextQues = selected + 1;
        nextQues < length && setSelected(nextQues);
    };

    const handleJumpQuestion = (id: any) => {
        setSelected(id - 1);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleOptionChange = (questionId: number, option_id: number, id: number) => {
        setSelected(id);

        // Update selected options state
        // setSelectedOptions((prevOptions) => {
        //     const updatedOptions = prevOptions.filter((option) => option.question_id !== questionId);
        //     return [...updatedOptions, { question_id: questionId, option_id: option_id, index: id+1 }];
        // });
        // Update selected options state
        setSelectedOptions((prevOptions) => {
            const updatedOptions = prevOptions.filter((option) => option.question_id !== questionId);
            return [...updatedOptions, { question_id: questionId, option_id: option_id, index: id + 1 }];
        });

        // Update selected questions state
        setSelectedQuestions((prevSelectedQuestions) => {
            if (prevSelectedQuestions.includes(id + 1)) {
                return prevSelectedQuestions.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedQuestions, id + 1];
            }
        });
    };
    // console.log("ksflksfjlkjflka", selectedOptions)

    const submitTest = () => {
        // let fdata=[];
        const fdata: any = [];
        selectedOptions.forEach((items, i) => {
            // if (items.id != 0) {
            fdata.push({
                questionid: items.question_id,
                optionid: items.option_id,
            });
            // }
        });

        // console.log('kfhkfalkajflas')

        // Prepare the payload for the API call
        const payload = {
            candidateid: guestInfo?.candidateinfo?.id,
            examid: guestInfo?.exam_info?.id,
            questionpaperid: guestInfo?.exam_info?.associated_papers,
            duration: timeLeft,
            examdata: fdata,
        };

        // Dispatch the examSubmit action
        dispatch(submitExam(payload) as any);
        setShowSuccessmsg(true);
        setShowErrMsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            history('/test-success');
        }
        if (errormsg) {
            history('/test-error');
        }
    }, [successmsg, errormsg]);

    useEffect(() => {
        if (timeLeft === 0) {
            submitTest();
        }
    }, [timeLeft]);

    //  the total number of selected questions
    const totalSelectedQuestions = Object.keys(selectedQuestions).length;

    return (
        <div className="bg-test row m-0">
            <div className="col-md-2 mt-3 timer-container ">
                <Progress type="circle" strokeColor="#7F265B" percent={(timeLeft / 60) * 100} format={() => <h6 className="mb-0 fs-5">{formatTime(timeLeft)}</h6>} status="active" />
            </div>
            <div className="text-center col quiz-box">
                <div className="my-5 mx-3">
                    <Image src={Logo} />
                </div>
                <Spin spinning={loading5} className="test-submit-spin" tip="Submitting test...">
                    <div className="row mx-0 my-5  text-questions">
                        {guestInfoData.map((questionSet: any, setId: any) => {
                            if (selected === setId) {
                                return (
                                    <div className="row mx-0 my-5  text-options" key={setId}>
                                        <div className="col-md-9 mx-auto my-5 text-answers p-3">
                                            <h3 className="text-dark">
                                                {setId + 1}. {questionSet.Mdquestions_name}
                                            </h3>
                                            <div className="option-section mx-auto mt-5 w-50">
                                                <Radio.Group
                                                    buttonStyle="solid"
                                                    // defaultValue={selectedOptions.some((option) => option.question_id === questionSet?.id)}
                                                    onChange={(e) => setDefaultChecked(e.target.value)}
                                                >
                                                    {questionSet.options.map((opt: any, optId: any) => (
                                                        <Radio.Button
                                                            value={opt.option_id}
                                                            key={optId}
                                                            className={`${selectedOptions.some((option) => option.option_id === opt?.option_id) ? 'ant-radio-button-wrapper-checked' : ''}`}
                                                            defaultChecked={selectedOptions.some((option) => option.option_id === opt?.option_id)}
                                                            onChange={() => handleOptionChange(questionSet.id, opt.option_id, setId)}
                                                            // onClick={() => handleQuestionButtonClick(setId)}
                                                        >
                                                            <div className="d-flex">
                                                                <span className="text-center col">{opt.option_name}</span>
                                                                {selectedOptions.some((option) => option.question_id === questionSet.id && option.option_id === opt.option_id) ? (
                                                                    <CheckOutlined className="ms-auto text-end my-auto text-success" size={18} />
                                                                ) : (
                                                                    ''
                                                                    // <CheckOutlined className="ms-auto text-end my-auto" color="#fff" size={18} />
                                                                )}
                                                            </div>
                                                        </Radio.Button>
                                                    ))}
                                                </Radio.Group>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between test-btns w-75 ms-auto ps-5">
                                            {setId == 0 ? (
                                                ''
                                            ) : (
                                                <Button className="btn-secondary" type="default" onClick={handlePrevious}>
                                                    Previous
                                                </Button>
                                            )}
                                            {setId == length - 1 ? (
                                                <Popconfirm title="" description="Are you sure to submit the test?" onConfirm={submitTest} okText="Yes" cancelText="No">
                                                    <Button className="mx-3 bg-success text-white" loading={loading5}>
                                                        Submit Test
                                                    </Button>
                                                </Popconfirm>
                                            ) : (
                                                <Button className="ms-auto me-3" type="primary" onClick={handleNext}>
                                                    Next
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                            return null; // Return null if selected question is not found
                        })}
                    </div>
                </Spin>
            </div>
            <div className="col-auto my-5 ms-auto sidebar-icons">
                <MenuOutlined className="bg-primary p-3 me-0 text-white" onClick={showDrawer} />
            </div>

            <div className=" p-0 ms-auto col-md-2 bg-white shadow text-start layout-sidebar">
                <h5 className="my-auto text-secondary mt-3 px-2 text-capitalize align-items-center d-flex">
                    {guestInfo?.candidateinfo?.gender?.toLowerCase() == 'male' ? <MaleIcon size={60} /> : <FemaleIcon size={60} />}
                    <p className="my-auto fs-17 fw-bold">
                        {guestInfo?.candidateinfo?.fname} {guestInfo?.candidateinfo?.mname} {guestInfo?.candidateinfo?.lname}
                    </p>
                </h5>
                <div className="my-2">
                    <div className="bg-light p-3">
                        <div className="d-flex mb-2">
                            <div className="not-visited p-2 me-2 border-gray bg-white"></div>
                            <span className="fs-15">Answered Questions</span>
                        </div>
                        <div className="d-flex">
                            <div className="visited p-2 me-2 "></div>
                            <span className="fs-15"> Not Visited/Unanswered Questions</span>
                        </div>
                    </div>
                </div>
                <div className="bg-primary px-3 py-2">
                    <h6 className="text-white mb-0">Questions</h6>
                </div>
                <Spin spinning={loading5} tip="Submitting test...">
                    <div className="py-3 ps-2 row m-0">
                        {guestInfoData?.map((item: any, index: number) => (
                            <Button
                                key={index + 1}
                                className={`bg-white border col-1 px-3 custom-btn-box m-1 ${selectedQuestions?.includes(index + 1) ? 'correct' : ''}`}
                                onClick={() => {
                                    handleJumpQuestion(index + 1);
                                }}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </div>
                </Spin>
            </div>

            <Drawer title="" onClose={onClose} open={open} placement="right" width={295}>
                <div className="p-0 ms-auto text-start">
                    <div className="d-flex">
                        {guestInfo?.candidateinfo?.gender?.toLowerCase() == 'male' ? <MaleIcon size={60} /> : <FemaleIcon size={60} />}
                        <h5 className="col my-auto text-secondary ">{guestInfo?.candidateinfo?.fname + '' + guestInfo?.candidateinfo?.lname}</h5>
                    </div>
                    <div className="my-2">
                        <div className="bg-light p-3">
                            <div className="d-flex mb-2">
                                <div className="not-visited p-2 me-2 border-gray bg-white"></div>
                                <span className="fs-15">Answered Questions</span>
                            </div>
                            <div className="d-flex">
                                <div className="visited p-2 me-2 "></div>
                                <span className="fs-15"> Not Visited/Unanswered Questions</span>
                            </div>
                        </div>
                    </div>
                    <div className="bg-primary px-3 py-2">
                        <h6 className="text-white mb-0">Questions</h6>
                    </div>
                    <div className="p-3 row m-0">
                        {guestInfoData?.map((item: any, index: number) => (
                            <Button
                                key={index + 1}
                                className={`bg-white border col-1 px-3 m-1 custom-btn-box ${selectedQuestions?.includes(index + 1) ? 'correct' : ''}`}
                                onClick={() => {
                                    handleJumpQuestion(index + 1);
                                    onClose();
                                }}
                            >
                                {index + 1}
                            </Button>
                        ))}
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default TestPage;
