import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'components/shared/FormComponent';
import { getBatchDetails } from 'services/store/actions/masterDataAction';
import { useDispatch, useSelector } from 'react-redux';
import type { TableProps } from 'antd';
import { Table, Popconfirm, message, Spin } from 'components/shared/AntComponent';
import { getCandidateInternList, removeCandidate } from 'services/store/actions/candidateAction';
import { EditIcon, EyeIcon } from 'assets/icons/CustomIcons';
import { DeleteFilled } from '@ant-design/icons';
import dayjs from 'dayjs';

interface DataType {
    key: number;
    regno: string;
    fname: number;
    lname: string;
    gender: string;
    // qualification: any;
    mobile_number: string;
    email: string;
    status: any;
}

const ViewBatchProjectDetail: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { internListInfo, loading3, loading2, success2, error2 } = useSelector((state: any) => state.candidate);
    const batchData = location.state?.data || null;
    const data = internListInfo?.AccountDetail || [];
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error2 : false;

    const goToPreviousScreen = () => {
        if (location.state.id === '2') {
            navigate('/batch');
        } else {
            navigate('/project');
        }
    };

    function getCandidate() {
        const inputJson = {
            Input: {
                status: 'Active',
                candidate_type: location.state.id === '2' ? 2 : 3,
                batch_id: location.state.id === '2' ? Number(location?.state?.data?.id) || 0 : 0,
                project_id: location.state.id === '2' ? 0 : Number(location.state?.data?.id) || 0,
            },
            DataFinder: {
                pagesize: 1000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: '',
            },
        };
        dispatch(getCandidateInternList(inputJson) as any);
    }

    useEffect(() => {
        getCandidate();
    }, []);

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Reg No',
            dataIndex: 'regno',
            key: 'regno',
            align: 'left',
            className: 'custom-cell-padding',
        },
        {
            title: 'First Name',
            dataIndex: 'fname',
            key: 'fname',
            width: 400,
            className: 'custom-cell-padding',
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            key: 'lname',
            className: 'custom-cell-padding',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
            className: 'custom-cell-padding',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            align: 'center',
            className: 'custom-cell-padding',
        },
        {
            title: 'Email ',
            dataIndex: 'email',
            key: 'email',
            className: 'custom-cell-padding',
        },
        {
            title: 'Submitted On',
            dataIndex: 'created_on',
            key: 'created_on',
            align: 'center',
            className: 'custom-cell-padding',
            render: (created_on: any) => {
                const originalDate = new Date(created_on) || null;
                return <div>{internListInfo ? originalDate?.toLocaleString() : null}</div>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            className: 'custom-cell-padding',
            render: (status: string) => {
                return <div className={`${status?.toLowerCase() === 'active' ? 'text-success' : 'text-danger'}`}>{status}</div>;
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="pointer my-auto text-end pb-3" onClick={() => editQuestion(record)}>
                            <EditIcon size={34} />
                        </div>
                        <div className="text-warning pointer eye-icon me-2" onClick={() => viewDetails(record)}>
                            <EyeIcon size={20} />
                        </div>
                        <div className="my-auto delete-icon">
                            <Popconfirm title="Delete Candidate" description="Are you sure to delete this Candidate?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                                <DeleteFilled className="text-danger pointer del-icon" />
                            </Popconfirm>
                        </div>
                    </div>
                );
            },
        },
    ];

    const viewDetails = (val: any) => {
        navigate('/candidate/internship-detail', { state: { data: val } });
    };

    const editQuestion = (item: any) => {
        navigate('/candidate/add-interviewee-candidate', {
            state: {
                title: location.state.id === '2' ? 'Update General Intern' : 'Update Project Intern ',
                id: location.state.id === '2' ? 2 : 3,
                cid: item.id,
                option: location?.state?.option || [],
            },
        });
    };

    const deleteQuestion = (item: any) => {
        dispatch(removeCandidate(item.id) as any);
        setShowSuccessmsg(true);
        setShowErrormsg(true);
    };
    useEffect(() => {
        if (successmsg) {
            message.success('Candidate Deleted Successfully');
            setShowSuccessmsg(false);
            getCandidate();
        }
        if (errormsg) {
            message.error("Candidate Couldn't be deleted");
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],

        itemRender: (current: any, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };

    const navigateAddScreen = () => {
        navigate('/candidate/add-interviewee-candidate', {
            state: {
                title: location.state.id === '2' ? 'Add General Intern' : 'Add Project Intern',
                id: 2,
                cid: 0,
                option: location.state?.option,
            },
        });
    };

    return (
        <div>
            <div className="d-flex mt-3 mx-0">
                <div className="p-2 col">
                    <h5 className="text-secondary fw-bold my-auto">{location.state.id === '2' ? 'Batch' : 'Project'} Details</h5>
                </div>
                <div className="ms-auto mt-auto col-auto">
                    <Button type="primary" className="col-auto mb-2 mx-2 btn-back" onClick={goToPreviousScreen}>
                        Back
                    </Button>
                </div>
            </div>
            <div className="ps-2 pe-2 py-0">
                <div className="bg-white d-flex flex-wrap w-100 p-3  my-auto">
                    <div className="col">
                        <div>
                            <h6 className="text-gray fw-bold">{location.state.id === '2' ? 'Batch' : 'Project'} #</h6>
                            <p className="mb-0">{location.state.id === '2' ? batchData?.batchno : batchData?.projectno}</p>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6 className="text-gray fw-bold">{location.state.id === '2' ? 'Batch' : 'Project'} Name</h6>
                            <p className="mb-0">{location.state.id === '2' ? batchData?.batch_name : batchData?.project_name}</p>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6 className="text-gray fw-bold">Description</h6>
                            <p className="mb-0">{batchData?.description}</p>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6 className="text-gray fw-bold">Amount</h6>
                            <p className="mb-0">Rs. {location.state.id === '2' ? batchData?.batch_amount || 0 : batchData?.project_amount || 0}</p>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6 className="text-gray fw-bold">Start Date</h6>
                            <p className="mb-0">
                                {location.state.id === '2'
                                    ? dayjs(new Date(batchData?.batch_st_date)).format('DD-MM-YYYY')
                                    : dayjs(new Date(batchData?.project_st_date)).format('DD-MM-YYYY')}
                            </p>
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <h6 className="text-gray fw-bold">End Date</h6>
                            <p className="mb-0">{location.state.id === '2' ? batchData?.batch_en_date : batchData?.project_en_date}</p>
                        </div>
                    </div>
                    <div className="col-md-1 my-auto">
                        {/* <h6 className="text-gray fw-bold text-center">Status</h6> */}
                        <p
                            className={`mb-0 text-center rounded-circle py-2 ${batchData?.status?.toLowerCase() === 'completed' ? 'text-white bg-success' : batchData?.status?.toLowerCase() === 'on going' ? 'text-dark bg-warning' : 'text-danger text-white'}`}
                        >
                            {batchData?.status}
                        </p>
                    </div>
                </div>
            </div>
            <div className="d-flex mx-0 mt-4">
                <h6 className="mx-2  text-primary fw-bold fs-17 col mb-0 my-auto">Associated Candidate List</h6>
                <div className="col-auto text-start pe-2">
                    <Button type="primary" onClick={navigateAddScreen} className="bg-primary">
                        Add
                    </Button>
                </div>
            </div>

            <div className="m-2 custom-table">
                <Spin spinning={loading2} className="text-danger" tip="Deleting...">
                    <Table columns={columns} dataSource={data} rowKey="id" loading={loading3} pagination={paginationConfig} />
                </Spin>
            </div>
        </div>
    );
};

export default ViewBatchProjectDetail;
