import { createSlice } from '@reduxjs/toolkit';
import { addQuestionPaper, getEachQuestionPaper, getQuestionPaper, getQuestions, removeQuestionPaper } from 'services/store/actions/questionPaperAction';

interface QusPaperState {
    loading: boolean;
    questionpaperData: any; // Define the type of userInfo
    error: any; // Define the type of error
    success: boolean;
    loading1: boolean;
    addPaperInfo: any;
    error1: any;
    success1: boolean;
    loading2: boolean;
    removePaperInfo: any;
    error2: any;
    success2: boolean;
    //get question data
    loading3: boolean;
    quesPaperInfo: any;
    error3: any;
    success3: boolean;
    //get each question
    loading4: boolean;
    paperInfo: any;
    error4: any;
    success4: boolean;
}

const initialState: QusPaperState = {
    loading: false,
    questionpaperData: null,
    error: null,
    success: false,
    loading1: false,
    addPaperInfo: null,
    error1: null,
    success1: false,
    loading2: false,
    removePaperInfo: null,
    error2: null,
    success2: false,
    //each question paper
    success3: false,
    loading3: false,
    quesPaperInfo: null,
    error3: null,
    loading4: false,
    paperInfo: null,
    error4: null,
    success4: false,
};

const questionpaperSlice = createSlice({
    name: 'questionspaper',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionPaper.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getQuestionPaper.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.questionpaperData = payload;
                state.success = true;
            })
            .addCase(getQuestionPaper.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            // addquestionbank user
            .addCase(addQuestionPaper.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(addQuestionPaper.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.success1 = true;
                state.addPaperInfo = payload;
            })
            .addCase(addQuestionPaper.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.success1 = false;
                state.error1 = payload;
            })
            // deletequestionbank user
            .addCase(removeQuestionPaper.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(removeQuestionPaper.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.success2 = true;
                state.removePaperInfo = payload;
            })
            .addCase(removeQuestionPaper.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.success2 = false;
                state.error2 = payload;
            })
            //each question paper
            .addCase(getEachQuestionPaper.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(getEachQuestionPaper.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.success3 = true;
                state.quesPaperInfo = payload;
            })
            .addCase(getEachQuestionPaper.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.success3 = false;
                state.error3 = payload;
            })
            //get each question paper detail
            .addCase(getQuestions.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(getQuestions.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.success4 = true;
                state.paperInfo = payload;
            })
            .addCase(getQuestions.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.success4 = false;
                state.error4 = payload;
            });
    },
});
export default questionpaperSlice.reducer;
