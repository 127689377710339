import { createSlice } from '@reduxjs/toolkit';
import { addExam, deleteExam, getExam, getExamDetail, getIndividualResult, guestLogin, submitExam } from 'services/store/actions/examAction';

interface ExamState {
  loading: boolean;
  examData: any; // Define the type of userInfo
  error: any; // Define the type of error
  success: boolean;
  loading1: boolean,
  addexamData: any,
  error1: any,
  success1: boolean,
  loading2: boolean,
  deleteexamData: any,
  error2: any,
  success2: boolean,
  //get question data
  loading3: boolean,
  guestInfo: any,
  error3: any,
  success3: boolean,
  //get exam detail,
  loading4: boolean,
  examDetailInfo: any,
  error4: any,
  success4: boolean,
  //submit exam
  loading5:boolean,
  examsubmitInfo:any,
  error5:any,
  success5:boolean
  //individual result
  loading6:boolean,
  sresultInfo:any,
  error6:any,
  success6:boolean
}

const initialState: ExamState = {
  loading: false,
  examData: null,
  error: null,
  success: false,
  loading1: false,
  addexamData: null,
  error1: null,
  success1: false,
  loading2: false,
  deleteexamData: null,
  error2: null,
  success2: false,
  //guest login
  loading3: false,
  guestInfo: null,
  error3: null,
  success3: false,
  //examdetail
  loading4: false,
  examDetailInfo: null,
  error4: null,
  success4: false,
  //submit exam
  loading5:false,
  examsubmitInfo:null,
  error5:null,
  success5:false,
  loading6:false,
  sresultInfo:null,
  error6:null,
  success6:false
}

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // questionpaper user
    builder
      .addCase(getExam.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false;
      })
      .addCase(getExam.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.examData = payload;
        state.success = true;
      })
      .addCase(getExam.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      // addquestionpaper user
      .addCase(addExam.pending, (state) => {
        state.loading1 = true
        state.error1 = null
        state.success1 = false;
      })
      .addCase(addExam.fulfilled, (state, { payload }) => {
        state.loading1 = false;
        state.addexamData = payload;
        state.success1 = true;
      })
      .addCase(addExam.rejected, (state, { payload }) => {
        state.loading1 = false;
        state.error1 = payload;
        state.success1 = false;
      })

      // deletequestionpaper user
      .addCase(deleteExam.pending, (state) => {
        state.loading2 = true
        state.error2 = null
        state.success2 = false;
      })
      .addCase(deleteExam.fulfilled, (state, { payload }) => {
        state.loading2 = false;
        state.deleteexamData = payload;
        state.success2 = true;
      })
      .addCase(deleteExam.rejected, (state, { payload }) => {
        state.loading2 = false;
        state.error2 = payload;
        state.success2 = false;
      })
      // guest login
      .addCase(guestLogin.pending, (state) => {
        state.loading3 = true
        state.error3 = null
        state.success3 = false;
      })
      .addCase(guestLogin.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.guestInfo = payload;
        state.success3 = true;
      })
      .addCase(guestLogin.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3 = false;
      })
      //get exam detail
      .addCase(getExamDetail.pending, (state) => {
        state.loading4 = true
        state.error4 = null
        state.success4 = false;
      })
      .addCase(getExamDetail.fulfilled, (state, { payload }) => {
        state.loading4 = false;
        state.examDetailInfo = payload;
        state.success4 = true;
      })
      .addCase(getExamDetail.rejected, (state, { payload }) => {
        state.loading4 = false;
        state.error4 = payload;
        state.success4 = false;
      })
      //submit exam
      .addCase(submitExam.pending, (state) => {
        state.loading5 = true
        state.error5 = null
        state.success5 = false;
      })
      .addCase(submitExam.fulfilled, (state, { payload }) => {
        state.loading5 = false;
        state.examsubmitInfo = payload;
        state.success5 = true;
      })
      .addCase(submitExam.rejected, (state, { payload }) => {
        state.loading5 = false;
        state.error5 = payload;
        state.success5 = false;
      })
      //get individual result
      .addCase(getIndividualResult.pending, (state) => {
        state.loading6 = true
        state.error6 = null
        state.success6 = false;
      })
      .addCase(getIndividualResult.fulfilled, (state, { payload }) => {
        state.loading6 = false;
        state.examsubmitInfo = payload;
        state.success6 = true;
      })
      .addCase(getIndividualResult.rejected, (state, { payload }) => {
        state.loading6 = false;
        state.error6 = payload;
        state.success6 = false;
      })
  },
})
export default examSlice.reducer