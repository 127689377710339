import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, message, Popconfirm, Tooltip } from 'components/shared/AntComponent';
import { Input, Button } from 'components/shared/FormComponent';
import { useNavigate } from 'react-router-dom';
import { DeleteFilled, SearchOutlined } from 'components/shared/AntIcons';
import { deleteQuestionBank, getQuestionBank } from 'services/store/actions/questionbankAction';
import type { TableProps } from 'antd';
import { getQuestionPaper, removeQuestionPaper } from 'services/store/actions/questionPaperAction';
import { EditIcon } from 'assets/icons/CustomIcons';

interface DataType {
    key: number;
    sno: number;
    papercode: string;
    question_paper_subject: string;
    total_question: string;
    duration: string;
    status: any;
    action: any;
}

const QuestionPaper: React.FC = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { questionpaperData, loading, success2, error2 } = useSelector((state: any) => state.questionpaper);
    const data: DataType[] = questionpaperData?.AccountDetail || [];
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchValue, setSearchValue] = useState('');
    const totalRecords = questionpaperData?.DataFinder?.totalrecords || 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrmsg, setShowErrmsg] = useState(false);
    const errormsg = showErrmsg ? error2 : false;

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'S.No',
            dataIndex: 'sl_no',
            key: 'sno',
            render: (id, record, index) => {
                if (pageIndex === 1) {
                    return index + 1;
                } else {
                    return (pageIndex - 1) * pageSize + (index + 1);
                }
            },
        },
        {
            title: 'Code',
            dataIndex: 'papercode',
            key: 'papercode',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 600,
            className:'title-name'
        },
        {
            title: 'Difficulty Level',
            dataIndex: 'question_paper_subject',
            key: 'question_paper_subject',
            render: (question_paper_subject) => {
                return <div className="text-capitalize">{question_paper_subject}</div>;
            },
        },
        {
            title: 'No.of Questions',
            dataIndex: 'total_question',
            key: 'total_question',
        },
        {
            title: 'Duration (mins)',
            dataIndex: 'duration',
            key: 'duration',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status) => {
                return <div className="bg-success text-white text-center w-75 py-1">{status}</div>;
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (record) => (
                <div className="d-flex w-100 justify-content-center">
                    <div className="pointer my-auto pb-3" onClick={() => editQuestion(record)}>
                        <EditIcon size={32} />
                    </div>
                    <div className="my-auto delete-icon">
                        <Popconfirm title="Delete Question Paper" description="Are you sure to delete this question paper?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                            <span className="pb-2 fs-17">
                                <DeleteFilled className="text-danger pointer" />
                            </span>
                        </Popconfirm>
                    </div>
                </div>
            ),
        },
    ];

    const editQuestion = (val: any) => {
        history('/question-paper/add-question-paper', {
            state: {
                id: val.id,
            },
        });
    };

    const deleteQuestion = (val: any) => {
        dispatch(removeQuestionPaper(val.id) as any);
        setShowSuccessmsg(true);
        setShowErrmsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            setShowSuccessmsg(false);
            message.success('Question paper deleted successfully');
            setPageIndex(1);
            getQuestionPaperDetails(1, pageSize, searchValue);
        }
        if (errormsg) {
            setShowErrmsg(false);
            if (error2?.data) {
                message.error(error2?.data);
            } else {
                message.error("Question paper couldn't be deleted");
            }
        }
    }, [successmsg, errormsg]);

    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],
        current: pageIndex,
        pageSize: pageSize,
        total: totalRecords,
        onChange: (page: number, pageSize: number) => {
            setPageIndex(page);
            setPageSize(pageSize);
            getQuestionPaperDetails(page, pageSize, searchValue);
        },
        itemRender: (current: any, type: any, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };

    const handleSearch = (e: any) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getQuestionPaperDetails(1, pageSize, e.target.value);
        }
    };
    const resetSearch = (e: any) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getQuestionPaperDetails(1, pageSize, e.target.value);
        }
    };

    const gotoAddScreen = () => {
        history('/question-paper/add-question-paper', {
            state: {
                id: 0,
            },
        });
    };

    function getQuestionPaperDetails(page: any, size: any, search: string) {
        const inputJson = {
            Input: {
                status: 'Active',
            },
            DataFinder: {
                pagesize: size,
                currentpage: page,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: search || '',
            },
        };
        dispatch(getQuestionPaper(inputJson) as any);
    }
    useEffect(() => {
        getQuestionPaperDetails(pageIndex, pageSize, searchValue);
    }, []);

    return (
        <div className="my-3">
            <div className="row m-0">
                <h5 className="col-md-3 text-start my-auto text-secondary fw-bold">Question Paper</h5>
                <div className="col-md-8 pe-1 d-flex ms-auto">
                    <div className="col-md-4 text-start pe-0 ms-auto d-flex">
                        <Input
                            prefix={<SearchOutlined />}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onKeyDown={(e) => handleSearch(e)}
                            onKeyUp={(e) => resetSearch(e)}
                            placeholder="Search"
                        />
                        <Button type="primary" onClick={gotoAddScreen} className="bg-primary ms-2">
                            Add
                        </Button>
                    </div>
                </div>
            </div>
            <div className="m-2 custom-table">
                <Table columns={columns} dataSource={data} rowKey="id" loading={loading} pagination={paginationConfig} />
            </div>
        </div>
    );
};

export default QuestionPaper;
