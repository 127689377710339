import React from 'react';
import { Button } from 'components/shared/FormComponent';
import { ArrowLeftOutlined } from 'components/shared/AntIcons';

interface ChildProps {
    goToPreviousScreen: () => void;
    data: any;
}

const TestResultScreen: React.FC<ChildProps> = ({ goToPreviousScreen, data }) => {
    const goBack = () => {
        goToPreviousScreen();
    };
    let questionNumber = 1; // Initialize question number
    return (
        <div>
            <div className="d-flex w-100 border-bottom">
                <h5 className="col-auto me-auto text-secondary fw-bold mt-2">Test Result</h5>
                <Button type="primary" className="col-auto ms-auto rounded-circle" icon={<ArrowLeftOutlined />} onClick={goBack}></Button>
            </div>
            <div className="col bg-white p-3 text-start">
                <div className="row m-0 test-height">
                    {data.map((questionSet: any[], setIndex: number) => (
                        <div className="row m-0" key={setIndex}>
                            {questionSet.map((item: any, index: any) => (
                                <div className="mb-3 col-md-6" key={index}>
                                    <div className="p-0">
                                        <p className="ques-result fs-16 mb-2">
                                            {questionNumber++}. {item.Mdquestions_name}
                                        </p>
                                    </div>
                                    {item?.options?.map((itm: any, idx: number) => {
                                        const isCorrect = itm.answer_option === true;
                                        const isSelected = item.your_answer === itm.option_id;
                                        const isNull = item.your_answer == null;
                                        const bgColorClass = isCorrect ? 'bg-light-success text-success fw-bold' : isSelected ? 'bg-danger-light text-danger fw-bold' : 'bg-lightgray';

                                        return (
                                            <div className={`ms-3 p-2 w-75 text-break ques-option mb-2 fs-15 ${isNull ? 'bg-lightgray fw-bold' : bgColorClass}`} key={idx}>
                                                <div className="d-flex align-items-center">
                                                    <div>{itm.option_name}</div>
                                                    {isSelected && !isCorrect && <div className="bg-danger-light text-danger fw-bold p-1 rounded ms-auto">your answer</div>}
                                                    {isCorrect && <div className="bg-light-success p-1 rounded ms-auto">correct answer</div>}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestResultScreen;
