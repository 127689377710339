import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/app.scss';
import 'assets/styles/form.scss';
import 'assets/styles/theme.scss';
import 'assets/styles/customTheme.scss';
import 'assets/styles/modal.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from 'components/authentication/LoginPage';
import { useSelector } from 'react-redux';
import PrivateRoute from 'components/routes/PrivateRoute';
import ForgotPassword from 'components/authentication/ForgotPassword';
import GuestLogin from 'components/external-user/GuestLogin';
import GuestInfo from 'components/external-user/GuestInfo';
import Page404 from 'components/pages/Page404';
import ResetPassword from 'components/authentication/ResetPassword';
import TestPage from 'components/external-user/conduct-test/TestPage';
import TestSuccessPage from 'components/external-user/conduct-test/TestSuccessPage';
import TestErrorPage from 'components/external-user/conduct-test/TestErrorPage';
import RegistrationForm from 'components/pages/RegistrationForm';

const App: React.FC = () => {
    const { userInfo } = useSelector((state: any) => state.auth);
    const isAuth = localStorage?.getItem('userid');
    return (
        <div className="App">
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/guest-login/:id" element={<GuestLogin />} />
                <Route path="/guest-details" element={<GuestInfo />} />
                <Route path="/page-not-found" element={<Page404 />} />
                <Route path="/change-password/:id" element={<ResetPassword />} />
                <Route path="/written-test" element={<TestPage />} />
                <Route path="/test-success" element={<TestSuccessPage />} />
                <Route path="/test-error" element={<TestErrorPage />} />
                <Route path="/candidate-registration-form" element={<RegistrationForm />} />
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/registration-form" element={<RegistrationForm />} />
                {isAuth || userInfo ? <Route path="*" element={<PrivateRoute />} /> : <Route path="*" element={<Navigate to="/login" />} />}
            </Routes>
        </div>
    );
};

export default App;
