import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, message, Spin } from 'components/shared/AntComponent';
import { Button, Input, Select } from 'components/shared/FormComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFilled, SearchOutlined } from 'components/shared/AntIcons';
import { EditIcon } from 'assets/icons/CustomIcons';
import { getCandidateProjectLists, removeCandidate } from 'services/store/actions/candidateAction';
import type { TableProps } from 'antd';
import 'assets/styles/table.scss';
import { getProjectDetails } from 'services/store/actions/masterDataAction';

interface DataType {
    key: number;
    regno: string;
    fname: number;
    lname: string;
    gender: string;
    // qualification: any;
    mobile_number: string;
    email: string;
    status: any;
}

const Project: React.FC = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { prjListInfo, loading4, success2, error2, loading2 } = useSelector((state: any) => state.candidate);
    const { projectInfo, loading2: prjLoading } = useSelector((state: any) => state.master);
    const [searchValue, setSearchValue] = useState('');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const data = prjListInfo?.AccountDetail || [];
    const totalRecords = prjListInfo?.DataFinder?.totalrecords || 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error2 : false;
    const [selectedPrj, setSelectedPrj] = useState('0');

    const prjOptions = !prjLoading
        ? projectInfo?.ProjectDetail?.map((item: any) => {
              return {
                  label: item.project_name,
                  value: item.id?.toString(),
              };
          }) || []
        : [];
    prjOptions.push({ label: 'All', value: '0' });
    const navigateAddScreen = () => {
        history('/candidate/add-project-candidate', {
            state: {
                title: 'Add Project Intern',
                id: 3,
                cid: 0,
                option: prjOptions,
            },
        });
    };

    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Reg No',
            dataIndex: 'regno',
            key: 'regno',
            align: 'left',
        },
        {
            title: 'First Name',
            dataIndex: 'fname',
            key: 'fname',
            width: 400,
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            key: 'lname',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            align: 'center',
        },
        {
            title: 'Email ',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Submitted On',
            dataIndex: 'created_on',
            key: 'created_on',
            align: 'center',
            render: (created_on: any) => {
                const originalDate = new Date(created_on)||null;
                return <div>{prjListInfo ? originalDate?.toLocaleString() : null}</div>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="pointer my-auto text-end pb-3" onClick={() => editQuestion(record)}>
                            <EditIcon size={34} />
                        </div>
                        <div className="my-auto delete-icon">
                            <Popconfirm title="Delete Candidate" description="Are you sure to delete this Candidate?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                                <DeleteFilled className="text-danger pointer del-icon" />
                            </Popconfirm>
                        </div>
                    </div>
                );
            },
        },
    ];

    const editQuestion = (item: any) => {
        history('/candidate/add-project-candidate', {
            state: {
                title: 'Update Project Intern',
                id: 3,
                cid: item.id,
                option: prjOptions,
            },
        });
    };

    const deleteQuestion = (item: any) => {
        dispatch(removeCandidate(item.id) as any);
        setShowSuccessmsg(true);
        setShowErrormsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Candidate Deleted Successfully');
            setShowSuccessmsg(false);
            setPageIndex(1);
            setSearchValue('');
            getCandidate(pageSize, 1, '', selectedPrj);
        }
        if (errormsg) {
            message.error("Candidate Couldn't be deleted");
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    function getCandidate(pageSize: number, pageIndex: number, search: string, prj: any) {
        const inputJson = {
            Input: {
                status: 'Active',
                candidate_type: 3,
                batch_id: 0,
                project_id: Number(prj) || 0,
            },
            DataFinder: {
                pagesize: pageSize,
                currentpage: pageIndex,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: search || '',
            },
        };
        dispatch(getCandidateProjectLists(inputJson) as any);
    }

    useEffect(() => {
        getCandidate(pageSize, pageIndex, searchValue, selectedPrj);
    }, []);

    function getProject() {
        const inputJson = {
            UserInput: {
                project_id: 0,
            },
            DataFinder: {
                pagesize: 1000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'desc',
                searchdata: '',
            },
        };
        dispatch(getProjectDetails(inputJson) as any);
    }

    useEffect(() => {
        getProject();
    }, []);

    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],
        current: pageIndex,
        pageSize: pageSize,
        total: totalRecords,
        onChange: (page: number, pageSize: number) => {
            setPageIndex(page);
            setPageSize(pageSize);
            getCandidate(pageSize, page, searchValue, selectedPrj);
        },
        itemRender: (current: any, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };
    const handleSearch = (e: any) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getCandidate(pageSize, 1, e.target.value, selectedPrj);
        }
    };
    const resetSearch = (e: any) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getCandidate(pageSize, 1, e.target.value, selectedPrj);
        }
    };
    const searchbyBtn = () => {
        setPageIndex(1);
        getCandidate(pageSize, 1, searchValue, selectedPrj);
    };
    const handleProjectChange = (e: any) => {
        setSelectedPrj(e);
        setPageIndex(1);
        getCandidate(pageSize, 1, searchValue, e);
    };
    return (
        <div className="my-3 custom-table">
            <div className="row mx-0 mb-2 mt-0">
                <h5 className="col-md-3 text-start my-auto text-secondary fw-bold">Project List</h5>
                <div className="col-md-6 ms-auto pe-1">
                    <div className="row m-0">
                        <div className="col-md-3 ms-auto pe-0">
                            <Input
                                onKeyDown={(e) => handleSearch(e)}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyUp={(e) => resetSearch(e)}
                                prefix={<SearchOutlined onClick={() => searchbyBtn()} />}
                                placeholder="Search"
                            />
                        </div>
                        <div className="col-md-3 pe-0">
                            <Select
                                className="col-md-12"
                                options={prjOptions}
                                showSearch
                                defaultValue={selectedPrj}
                                value={selectedPrj}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => {
                                    return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                                filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                onChange={(e) => handleProjectChange(e)}
                                notFoundContent={
                                    <div className="text-center p-4">
                                        {prjLoading ? (
                                            <span>
                                                {/* <LoadingOutlined /> */}
                                                Loading...
                                            </span>
                                        ) : (
                                            <span>No project available</span>
                                        )}
                                    </div>
                                }
                            ></Select>
                        </div>
                        <div className="col-auto text-start pe-1 ">
                            <Button type="primary" onClick={navigateAddScreen} className="bg-primary">
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Spin spinning={loading2} className="text-danger" tip="Deleting...">
                <Table className="mx-2" columns={columns} dataSource={data} rowKey="id" loading={loading4} pagination={paginationConfig} />
            </Spin>
        </div>
    );
};

export default Project;
