import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Modal, Popconfirm, message, Spin, Skeleton } from 'components/shared/AntComponent';
import { Button, Checkbox, Input, Radio, InputNumber } from 'components/shared/FormComponent';
import { ApexOptions } from 'apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeleteFilled, DownCircleFilled, DownSquareFilled } from 'components/shared/AntIcons';
import { EditIcon } from 'assets/icons/CustomIcons';
// import { FaPencilAlt } from 'react-icons/fa';
// import { TiArrowBack } from "react-icons/ti";
// import { FaCircleChevronRight } from "react-icons/fa6";
import dayjs from 'dayjs';
// import { HiTrash } from 'react-icons/hi2';
import { getQuestionBank } from 'services/store/actions/questionbankAction';
import { addQuestionPaper, getEachQuestionPaper, getQuestions } from 'services/store/actions/questionPaperAction';
import { DownSquareOutlined } from '@ant-design/icons';
// import { LuChevronDownSquare, LuChevronUpSquare } from "react-icons/lu";

const { TextArea } = Input;

interface DataType {
    options: any;
    id: any;
    sno: number;
    Mdquestions_name: string;
    answer: string;
    level_of_difficulty: string;
    created_on: string;
}

const AddQuestionPaper: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useNavigate();
    const { questionbankData, loading, success2, error2 } = useSelector((state: any) => state.questionbank);
    const { quesPaperInfo, success1, loading1, error1, loading3 } = useSelector((state: any) => state.questionpaper);
    const data: DataType[] = loading ? [] : questionbankData?.AccountDetail || [];
    const [rowValue, setRowValue]: any = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState('all');
    const [paperTitle, setPaperTitle] = useState('');
    const [modalTitle, setModalTitle] = useState('Add Aptitude');
    const [activeTab, setActiveTab] = useState(1);
    const [expandVal, setExpandVal]: any = useState([]);
    const [selectedVal, setSelectedVal]: any = useState([]);
    const [selectedVal1, setSelectedVal1]: any = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    //right side cardn
    const [count, setCount] = useState(0);
    const [hour1, setHour1]: any = useState('0');
    const [hour2, setHour2]: any = useState('0');
    const [mins1, setMins1]: any = useState('0');
    const [mins2, setMins2]: any = useState('0');
    const [graphCount, setGraphCount] = useState([{ aptitude: 0, analytics: 0, technical: 0 }]);
    let counter = 0,
        counter1 = 0;
    //validate fields
    const [isTitleValid, setIsTitleValid] = useState(false);
    const [isHourValid, setIsHourValid] = useState(false);
    const [isEmptyVal, setIsEmptyVal] = useState(false);
    //modal and success
    const [modalData, setModalData]: any = useState([]);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : false;
    const [showErrMsg, setShowErrMsg] = useState(false);
    const errMsg = showErrMsg ? (error1 ? true : false) : false;
    //graph data
    const [serviceReqSeries, setServiceReqSeries] = useState([graphCount[0]?.aptitude || 0, graphCount[0]?.analytics || 0, graphCount[0]?.technical || 0]);

    const serviceReqOptions: ApexOptions = {
        labels: ['Aptitude', 'Analytics', 'Technical'],
        colors: ['#C7E2E3', '#495271', '#B4B4B4'],
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            fontSize: '10px',
            position: 'bottom',
            horizontalAlign: 'center',
            markers: {
                width: 8,
                height: 8,
                radius: 5,
                offsetX: -5,
            },
        },
        stroke: {
            width: 0,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: false,
                        total: {
                            show: false,
                        },
                    },
                },
            },
        },
    };

    useEffect(() => {
        if (location.state.id === 0) {
            setCount(0);
            if (questionbankData?.DataFinder) {
                setCount(questionbankData?.DataFinder?.totalrecords);
            }
        } else {
            if (quesPaperInfo?.question_listids) {
                setCount(0);
                setSelectedVal(quesPaperInfo?.question_listids);
                setPaperTitle(quesPaperInfo?.question_info?.title);
                setCount(quesPaperInfo?.question_listids?.length);
                const minutes = Number(quesPaperInfo?.question_info?.duration);
                const hours = Math.floor(minutes / 60);
                const remainingMinutes = minutes % 60;
                const part1 = Math.floor(remainingMinutes / 10);
                const part2 = remainingMinutes % 10;
                setHour1(0);
                setHour2(hours);
                setMins1(part1);
                setMins2(part2);
            }
        }
    }, [quesPaperInfo, location?.state, questionbankData]);

    const handleChangeText = (e: any) => {
        setPaperTitle(e.target.value);
        const len = e.target.value.length;
        if (len > 0) {
            setIsTitleValid(false);
        }
    };
    useEffect(() => {
        if (location.state.id !== 0) {
            setIsEdit(false);
            getquesPaper(location.state?.id);
        }
    }, [location.state]);

    const handleBack = () => {
        history('/question-paper');
    };

    const openModal = () => {
        if (activeTab == 1) {
            setModalTitle('Add Aptitude Questions');
        } else if (activeTab == 2) {
            setModalTitle('Add Analytics Questions');
        } else {
            setModalTitle('Add Technical Questions');
        }
        setIsModalOpen(true);
        setSelectedVal1([]);
        setModalData(questionbankData?.AccountDetail || []);
    };

    const handleExpand = (id: string) => {
        let myArray = expandVal;
        const valueExists = myArray.includes(id);
        if (!valueExists) {
            myArray = [...myArray, id];
        } else {
            if (myArray.includes(id)) {
                myArray = expandVal.filter((item: any) => item != id);
            }
        }
        setExpandVal(myArray);
    };

    function getAptitudeQuestions(id: number, level: string) {
        const inputJson = {
            Input: {
                status: 'Active',
                level_of_difficulty: level,
                q_typeid: id,
            },
            DataFinder: {
                pagesize: 1000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: '',
            },
        };
        dispatch(getQuestionBank(inputJson) as any);
    }

    useEffect(() => {
        getAptitudeQuestions(activeTab, selectedLevel);
    }, []);

    const handleTypeChange = (e: any) => {
        const value = e.target.value;
        setActiveTab(value);
        getAptitudeQuestions(value, selectedLevel);
    };

    const handleChecked = (id: any) => (e: any) => {
        const checked = e.target.checked;
        let myArray = selectedVal;
        const valueExists = myArray.includes(id);
        if (checked && !valueExists) {
            myArray = [...myArray, id];
        } else {
            if (myArray.includes(id)) {
                myArray = selectedVal.filter((item: any) => item != id);
            }
        }
        setSelectedVal([]);
        setSelectedVal(myArray);
        if (myArray?.length >= 10) {
            setIsEmptyVal(false);
        }
        if (checked) {
            if (activeTab == 1) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, aptitude: obj.aptitude + 1 };
                    })
                );
            } else if (activeTab == 2) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, analytics: obj.analytics + 1 };
                    })
                );
            } else {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, technical: obj.technical + 1 };
                    })
                );
            }
        } else {
            if (activeTab == 1) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, aptitude: obj.aptitude - 1 };
                    })
                );
            } else if (activeTab == 2) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, analytics: obj.analytics - 1 };
                    })
                );
            } else {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, technical: obj.technical - 1 };
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (successmsg) {
            if (location.state.id === 0) {
                message.success('Question paper created successfully');
                setPaperTitle('');
                setGraphCount([{ aptitude: 0, analytics: 0, technical: 0 }]);
                setSelectedVal([]);
                setHour1('0');
                setHour2('0');
                setMins1('0');
                setMins2('0');
                setActiveTab(1);
                getAptitudeQuestions(1, selectedLevel);
                setIsEmptyVal(false);
            } else {
                message.success('Question paper updated successfully');
            }
            setShowSuccessmsg(false);
        }
        if (errMsg) {
            if (error1?.data) {
                message.error(error1?.data);
            } else {
                message.error("Question paper couldn't be added");
            }
            setShowErrMsg(false);
        }
    }, [successmsg, errMsg]);

    const submitPaper = () => {
        let h1 = 0,
            h2 = 0,
            h3 = 0,
            mins = '';
        if (hour1 != 0) {
            h1 = hour1 * 60;
            h3 = h1;
        } else if (hour2 != 0) {
            h2 = hour2 * 60;
            h3 = Number(h3) + Number(h2);
        }
        if (mins1 != 0) {
            mins = String(mins1) + mins2;
            h3 = Number(h3) + Number(mins);
        }

        const inputJson = {
            questionspaperid: location.state?.id || 0,
            title: paperTitle,
            total_question: selectedVal?.length?.toString() || 0,
            question_paper_subject: 'all',
            duration: h3.toString(),
            question_listids: selectedVal?.toString() || '',
        };
        if (paperTitle?.length == 0 || (hour2 == 0 && mins1 == 0) || selectedVal?.length < 10) {
            if (paperTitle?.length == 0) {
                setIsTitleValid(true);
            } else {
                setIsTitleValid(false);
            }
            if (hour2 == 0 && mins1 == 0) {
                setIsHourValid(true);
            } else {
                setIsHourValid(false);
            }
            if (selectedVal?.length < 10) {
                setIsEmptyVal(true);
            } else {
                setIsEmptyVal(false);
            }
        } else {
            dispatch(addQuestionPaper(inputJson) as any);
            setShowSuccessmsg(true);
            setShowErrMsg(true);
        }
    };

    const getquesPaper = (id: any) => {
        dispatch(getEachQuestionPaper(id) as any);
    };

    useEffect(() => {
        if (quesPaperInfo) {
            const a = Number(quesPaperInfo?.appt_cnt);
            const b = Number(quesPaperInfo?.ant_count);
            const c = Number(quesPaperInfo?.tech_count);
            setGraphCount([{ aptitude: a, analytics: b, technical: c }]);
            setServiceReqSeries([a, b, c]);
            setRowValue(quesPaperInfo);
        } else {
            setServiceReqSeries([graphCount[0]?.aptitude || 0, graphCount[0]?.analytics || 0, graphCount[0]?.technical || 0]);
        }
    }, [quesPaperInfo]);

    useEffect(() => {
        setServiceReqSeries([graphCount[0]?.aptitude || 0, graphCount[0]?.analytics || 0, graphCount[0]?.technical || 0]);
    }, [graphCount]);

    const removeQues = (id: number) => {
        let myArray = selectedVal;
        if (myArray.includes(id)) {
            myArray = selectedVal.filter((item: any) => item != id);
        }
        setSelectedVal([]);
        setSelectedVal(myArray);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const addQuestionData = () => {
        handleCancel();
        let val = selectedVal;
        if (selectedVal1?.length > 0) {
            for (let i = 0; i < selectedVal1.length; i++) {
                val = [...val, selectedVal1[i]];
            }
        }
        setSelectedVal(val);
        setSelectedVal1([]);
        setModalData([]);
    };
    const handleCheckedModal = (id: number) => (e: any) => {
        const checked = e.target.checked;
        let myArray = selectedVal1;
        const valueExists = myArray.includes(id);
        if (checked && !valueExists) {
            myArray = [...myArray, id];
        } else {
            if (myArray.includes(id)) {
                myArray = selectedVal1.filter((item: any) => item != id);
            }
        }
        setSelectedVal1([]);
        setSelectedVal1(myArray);
        if (checked) {
            if (activeTab == 1) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, aptitude: obj.aptitude + 1 };
                    })
                );
            } else if (activeTab == 2) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, analytics: obj.analytics + 1 };
                    })
                );
            } else {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, technical: obj.technical + 1 };
                    })
                );
            }
        } else {
            if (activeTab == 1) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, aptitude: obj.aptitude - 1 };
                    })
                );
            } else if (activeTab == 2) {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, analytics: obj.analytics - 1 };
                    })
                );
            } else {
                setGraphCount((current) =>
                    current.map((obj) => {
                        return { ...obj, technical: obj.technical - 1 };
                    })
                );
            }
        }
    };

    return (
        <div className="add-question-paper my-3 mx-2">
            <div className="border-0 header-card rounded-0">
                <div className="row m-0">
                    <div className="col-md-5 ps-0 my-auto form-card text-start">
                        <h5 className="ps-0 my-auto text-secondary fw-bold">{location.state.id !== 0 ? 'Update Question' : 'Add Question'}</h5>
                    </div>
                    <Button type="primary" className="my-auto col-auto ms-auto px-4 rounded-0 btn-back" onClick={handleBack}>
                        Back
                    </Button>
                </div>
            </div>
            <Spin spinning={loading3}>
                <div className="my-2 row mx-0">
                    <div className="col-md-9 bg-white question-selection p-3">
                        <div className="mb-4">
                            <h6 className="text-start fw-bold fs-16 mb-1 h6">
                                Exam Title
                                {/* {location.state?.id !== 0 ? (
                                    <span className="ms-1 pointer" onClick={() => setIsEdit(!isEdit)}>
                                        <EditIcon size={27} />
                                    </span>                                ) : (
                                    ''
                                )} */}
                            </h6>
                            {/* {location.state?.id !== 0 && !isEdit ? (
                                <div className="d-flex bg-light col-md-6 border">
                                    <span className="text-dark text-capitalize mb-0 p-2 col-md-auto text-start">{rowValue?.question_info?.title || ''}</span>
                                </div>
                            ) : ( */}
                            <div className="col-md-6 text-start">
                                <TextArea
                                    rows={1}
                                    placeholder="Enter Title"
                                    value={paperTitle}
                                    className={`bg-light text-capitalize rounded-0 ${isTitleValid ? 'border-danger' : ''}`}
                                    onChange={(e) => handleChangeText(e)}
                                />
                            </div>
                            {/* )} */}
                            {isTitleValid && <div className="text-danger text-start">This field is required</div>}
                        </div>
                        <h6 className="text-start fw-bold mb-2">Questions</h6>
                        <div className="row m-0 text-start question-radio ">
                            <Radio.Group className="col mb-0 px-0 custom-radio" defaultValue={activeTab} value={activeTab} onChange={handleTypeChange}>
                                <Radio className="text-secondary" value={1}>
                                    Aptitude
                                </Radio>
                                <Radio className="text-secondary" value={2}>
                                    Analytics
                                </Radio>
                                <Radio className="text-secondary" value={3}>
                                    Technical
                                </Radio>
                            </Radio.Group>
                            {location.state?.id !== 0 ? (
                                <div className="col-auto ms-auto my-auto pe-2">
                                    <Button type="primary" onClick={() => openModal()}>
                                        + Add Questions
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {location.state.id !== 0 ? (
                            <div className="px-2 py-3 my-3 question-list-cont custom-scrollbar border bg-light">
                                {loading ? (
                                    <Skeleton active />
                                ) : (
                                    <div>
                                        {data?.map((item: any, index: number) => {
                                            if (!selectedVal?.includes(item.id)) {
                                                return null;
                                            }

                                            counter++;
                                            const date = dayjs(item?.created_on);
                                            return (
                                                <div key={index} className="d-flex mb-2 w-100">
                                                    <div className="question-cntr w-100">
                                                        <div className="bg-lightOrange d-flex p-2 align-items-center">
                                                            <div>
                                                                <span className="text-secondary fw-bold pe-3">({counter})</span>
                                                            </div>
                                                            <div>
                                                                <span className="author-name text-gray fw-bold">Author : </span>
                                                                <span className="text-head">System Admin</span>
                                                            </div>
                                                            <div className="ms-5">
                                                                <span className="author-name text-gray fw-bold">Updated on : </span>
                                                                <span className="text-head ps-2">{date.format('DD-MM-YYYY HH:mm')}</span>
                                                            </div>
                                                            <div className="ms-auto d-flex qus-opr align-items-center justify-content-center">
                                                                <div className=" my-auto me-1 ">
                                                                    <DownCircleFilled
                                                                        className={`fs-14 ${item.level_of_difficulty == 'easy' ? 'text-success bg-success' : item.level_of_difficulty == 'hard' ? 'text-warning bg-warning' : 'text-danger bg-danger'} rounded-circle`}
                                                                    />
                                                                </div>
                                                                <Popconfirm
                                                                    title="Delete Question"
                                                                    description="Are you sure to remove this question?"
                                                                    onConfirm={() => removeQues(item.id)}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                >
                                                                    <span className="my-auto fs-17">
                                                                        <DeleteFilled className="text-danger" />
                                                                    </span>
                                                                </Popconfirm>
                                                                <div className="text-primary ms-1 mb-0 mt-1 pointer my-auto" onClick={() => handleExpand(item.id)}>
                                                                    {expandVal?.includes(item.id) ? (
                                                                        <DownSquareOutlined className="fs-17" rotate={-90} />
                                                                    ) : (
                                                                        <DownSquareOutlined className="fs-17" />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`p-2 d-flex question-contn bg-white ${expandVal?.includes(item.id) ? 'incheight' : ''}`}>
                                                            <div className="ps-2 pe-4 w-100 text-start">
                                                                <span className="question text-dark fs-15">{item.Mdquestions_name}</span>
                                                                <div className="row mx-3 my-1">
                                                                    {item.options?.map((opt: any, i: number) => (
                                                                        <div className={`col-md-6 fs-15 d-flex px-0 `} key={i}>
                                                                            <div className={`p-2 w-100 mb-2 ${opt.answer_option ? 'text-success fw-bold' : 'text-gray'}`}>
                                                                                <span className={`option-name fw-bold ${opt.answer_option ? 'text-success' : 'text-gray'}`}>
                                                                                    {String.fromCharCode(i + 65)}
                                                                                </span>
                                                                                <span className="ms-2 option"> {opt.option_name}</span>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="px-2 py-3 my-3 question-list-cont bg-light custom-scrollbar border">
                                {loading ? (
                                    <Skeleton active />
                                ) : (
                                    <>
                                        {data.map((item, index: number) => {
                                            const date = dayjs(item?.created_on);
                                            return (
                                                <div key={index} className="d-flex mb-3 w-100">
                                                    <Checkbox className="mb-auto" defaultChecked={selectedVal?.includes(item.id)} onChange={handleChecked(item.id)}></Checkbox>
                                                    <div className="question-cntr ms-2 w-100">
                                                        <div className="bg-lightOrange d-flex p-2 align-items-center">
                                                            <div>
                                                                <span className="text-primary fw-bold pe-3">
                                                                    ( {index + 1}/{count} )
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span className="author-name text-gray fw-bold">Author :</span> <span className="text-head">System Admin</span>
                                                            </div>
                                                            <div className="ms-5">
                                                                <span className="author-name text-gray fw-bold">Updated on :</span>
                                                                <span className="text-head ps-2">{date.format('DD-MM-YYYY HH:mm')}</span>
                                                            </div>
                                                            <div className="ms-auto d-flex qus-opr justify-content-center align-items-center">
                                                                <div className=" my-auto me-1 pt-1 ">
                                                                    <DownCircleFilled
                                                                        className={`fs-14 ${item.level_of_difficulty == 'easy' ? 'text-success bg-success' : item.level_of_difficulty == 'hard' ? 'text-warning bg-warning' : 'text-danger bg-danger'} rounded-circle`}
                                                                    />
                                                                </div>
                                                                <div className="text-primary ms-1 mb-0 mt-1 pointer my-auto" onClick={() => handleExpand(item.id)}>
                                                                    {expandVal?.includes(item.id) ? (
                                                                        <DownSquareOutlined className="fs-18" rotate={-90} />
                                                                    ) : (
                                                                        <DownSquareOutlined className="fs-18" />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`p-2 d-flex question-contn bg-white ${expandVal?.includes(item.id) ? 'incheight' : ''}`}>
                                                            <div className="ps-2 pe-4 w-100 text-start mt-2">
                                                                <span className="question text-dark fs-15">{item.Mdquestions_name}</span>
                                                                <div className="row mx-3 my-1">
                                                                    {item.options?.map((opt: any, i: number) => {
                                                                        return (
                                                                            <div className={`col-md-6 d-flex px-0 ${i % 2 == 0 ? 'px-0' : 'px-0'}`} key={i}>
                                                                                <div className={`p-2 w-100 fs-15 mb-2 ${opt.answer_option ? 'text-success fw-bold' : 'text-gray '}`}>
                                                                                    <span className={`option-name fw-bold ${opt.answer_option ? 'text-success' : 'text-gray '}`}>
                                                                                        {String.fromCharCode(i + 65)}
                                                                                    </span>
                                                                                    <span className="ms-2 option"> {opt.option_name}</span>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        )}
                        <div className="row mx-0 my-3">
                            <h6 className="ps-0 col-auto me-auto text-dark my-auto">
                                Currently <span className="text-primary fw-bold">{selectedVal?.length}</span> Question(s) in Total and
                                <span className="text-primary fw-bold"> {selectedVal?.length}</span> point(s) in total.
                            </h6>
                            <div className="col-auto ms-auto d-flex pe-0">
                                {isEmptyVal && <div className="text-danger me-2 my-auto">Select minimum of 10 questions in total</div>}
                                <Button className="rounded-0 text-white time-btn ms-auto border-0" type="primary" loading={loading1} onClick={submitPaper}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 ps-2 pe-0 question-selection">
                        <div className="bg-white p-3 ">
                            <div>
                                <h6 className="text-secondary fw-bold p-2 bg-lightgray text-center">Question Code</h6>
                                <h5 className="text-center my-4 text-head fw-light">Code {quesPaperInfo?.question_info?.papercode || '____'}</h5>
                            </div>
                            <div></div>
                            <div>
                                <h6 className="text-secondary fw-bold bg-lightgray p-2 bg-gray text-center">Test Time</h6>
                                <div className="mx-auto justify-content-between">
                                    <div className="d-flex form-card justify-content-center ">
                                        <div className="text-center">
                                            <p className="fw-bold">Hours</p>
                                            <div className="d-flex">
                                                <InputNumber
                                                    maxLength={1}
                                                    defaultValue={hour1}
                                                    value={hour1}
                                                    className={`mx-2 p-1 text-center fw-bold ${isHourValid ? 'border-danger' : ''}`}
                                                    type="number"
                                                    onChange={(e: any) => setHour1(e)}
                                                />
                                                <InputNumber
                                                    maxLength={1}
                                                    defaultValue={hour2}
                                                    value={hour2}
                                                    className={`me-2 p-1 text-center fw-bold ${isHourValid ? 'border-danger' : ''}`}
                                                    type="number"
                                                    onChange={(e: any) => {
                                                        setHour2(e);
                                                        if (e == 0 && mins1 == 0) {
                                                            setIsHourValid(true);
                                                        } else {
                                                            setIsHourValid(false);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <p className="fw-bold text-center">Minutes</p>
                                            <div className="d-flex">
                                                :
                                                <InputNumber
                                                    maxLength={1}
                                                    defaultValue={mins1}
                                                    value={mins1}
                                                    className={`mx-2 p-1 text-center fw-bold ${isHourValid ? 'border-danger' : ''}`}
                                                    type="number"
                                                    onChange={(e: any) => {
                                                        setMins1(e);
                                                        if (e == 0 && hour2 == 0) {
                                                            setIsHourValid(true);
                                                        } else {
                                                            setIsHourValid(false);
                                                        }
                                                    }}
                                                />
                                                <InputNumber
                                                    maxLength={1}
                                                    defaultValue={mins2}
                                                    value={mins2}
                                                    className={`p-1 text-center fw-bold ${isHourValid ? 'border-danger' : ''}`}
                                                    type="number"
                                                    onChange={(e: any) => setMins2(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {isHourValid && <div className="text-danger text-center mt-2">This field is required</div>}
                                </div>
                                <div className="mt-5 mb-4">
                                    <div className="d-flex p-2 bg-lightgray">
                                        <h6 className="text-secondary fw-bold ps-3 mb-0">Score</h6>
                                        <h6 className="text-secondary fw-bold ms-auto pe-3 mb-0">Questions</h6>
                                    </div>
                                    <div className="d-flex px-3 py-2 mt-3">
                                        <h6 className="ps-3">{selectedVal?.length || 0}</h6>
                                        <h6 className="ms-auto pe-3">{selectedVal?.length || 0}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white p-3 mt-2">
                            <h6 className="text-secondary fw-bold fs-16">Topic Analysis</h6>
                            <Chart options={serviceReqOptions} series={serviceReqSeries} type="donut" height="360vh" width="100%" />
                        </div>
                        <div className="bg-white p-3 mt-2">
                            <h6 className="text-secondary fw-bold fs-16 mb-4">Difficulty Level</h6>
                            <div className="d-flex w-100 justify-content-between">
                                <span>
                                    <DownCircleFilled className="text-success bg-success rounded me-2" />
                                    Low
                                </span>
                                <span>
                                    <DownCircleFilled className="text-warning bg-warning rounded me-2 " />
                                    Medium
                                </span>
                                <span>
                                    <DownCircleFilled className="text-danger bg-danger rounded me-2" />
                                    Hard
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
            <Modal title={modalTitle} open={isModalOpen} onCancel={handleCancel} onOk={() => addQuestionData()} width={1000}>
                <div className="my-3 p-2 bg-light question-list-cont-modal custom-scrollbar overflow-auto">
                    {loading ? (
                        <Skeleton active />
                    ) : (
                        <>
                            {modalData.map((item: any, index: number) => {
                                const date = dayjs(item?.created_on);
                                if (selectedVal?.includes(item.id)) {
                                    return null;
                                }
                                counter1++;
                                return (
                                    <div key={index} className=" d-flex mb-3 w-100">
                                        <Checkbox
                                            className="mb-auto"
                                            defaultChecked={selectedVal1?.includes(item.id)}
                                            checked={selectedVal1?.includes(item.id)}
                                            onChange={handleCheckedModal(item.id)}
                                        ></Checkbox>
                                        <div className="question-cntr border border-dark ms-2 w-100">
                                            <div className="bg-lightOrange d-flex p-2 align-items-center">
                                                <div>
                                                    <span className="text-primary fw-bold pe-3">( {counter1} )</span> <span className="author-name fw-bold text-gray">Author:</span>{' '}
                                                    <span className="text-dark">System Admin</span>
                                                </div>
                                                <div className="ms-5 text-dark">
                                                    <span className=" fw-bold text-gray">Updated on: </span> <span className="text-dark">{date.format('DD-MM-YYYY HH:mm')}</span>
                                                </div>
                                                <div className="ms-auto d-flex qus-opr">
                                                    <div className="pe-1 my-auto">
                                                        <DownCircleFilled
                                                            className={`${item.level_of_difficulty == 'easy' ? 'text-success bg-success' : item.level_of_difficulty == 'hard' ? 'text-warning bg-warning' : 'text-danger bg-danger'} rounded-circle`}
                                                        />
                                                    </div>
                                                    <div className="text-primary ms-1 mb-0 mt-1 pointer my-auto" onClick={() => handleExpand(item.id)}>
                                                        {expandVal?.includes(item.id) ? <DownSquareOutlined className="fs-17" rotate={-90} /> : <DownSquareOutlined className="fs-17" />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`p-2 d-flex question-contn bg-white ${expandVal?.includes(item.id) ? 'incheight' : ''}`}>
                                                <div className="ps-2 pe-4 w-100">
                                                    <span className="question text-dark fs-16">{item.Mdquestions_name}</span>
                                                    <div className="row m-3">
                                                        {item.options?.map((opt: any, i: number) => {
                                                            return (
                                                                <div className={`col-md-6 d-flex ${i % 2 == 0 ? 'ps-0' : 'pe-0'}`} key={i}>
                                                                    <div className={`p-2 w-100 mb-2 fs-15 ${opt.answer_option ? 'text-success fw-bold' : 'text-gray '}`}>
                                                                        <span className={`option-name fw-bold ${opt.answer_option ? 'text-success' : 'text-gray '}`}>
                                                                            {String.fromCharCode(i + 65)}
                                                                        </span>
                                                                        <span className="ms-2 option"> {opt.option_name}</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="p-2 d-flex pointer ms-auto"></div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default AddQuestionPaper;
