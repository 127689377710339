import React from 'react';
import { Modal } from 'components/shared/AntComponent';
import { Button } from 'components/shared/FormComponent';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import 'assets/styles/modal.scss';
import "@cyntler/react-doc-viewer/dist/index.css";

interface ChildProps {
    openModal: boolean;
    filePath: any;
    fileName: string;
    apiUrl: any;
    closeModal: () => void;
}
const ViewDocument: React.FC<ChildProps> = ({ openModal, filePath, fileName, closeModal, apiUrl }) => {
    const docs = [
        {
            uri: apiUrl + filePath,
            fileName: fileName,
        },
    ];
    return (
        <div>
            <Modal
                title={'Document'}
                open={openModal}
                onCancel={closeModal}
                width={800}
                height={500}
                footer={
                    <div className="text-end">
                        <Button className="text-danger border shadow-sm" onClick={() => closeModal()}>
                            Close
                        </Button>
                    </div>
                }
            >
                <DocViewer
                    documents={docs}
                    initialActiveDocument={docs[1]}
                    pluginRenderers={DocViewerRenderers}
                    theme={{
                        primary: '#7F265B',
                        secondary: '#ffffff',
                        tertiary: '#7f265b3b',
                        textPrimary: '#ffffff',
                        textSecondary: '#5a53b2',
                        textTertiary: '#ffffff',
                        disableThemeScrollbar: true,
                    }}
                />
            </Modal>
        </div>
    );
};

export default ViewDocument;
