import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, message, Spin } from 'components/shared/AntComponent';
import { Input, Button } from 'components/shared/FormComponent';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteFilled, SearchOutlined } from 'components/shared/AntIcons';
import { EditIcon, EyeIcon } from 'assets/icons/CustomIcons';
import { getCandidateList, removeCandidate } from 'services/store/actions/candidateAction';
import type { TableProps } from 'antd';
import 'assets/styles/table.scss';

interface DataType {
    key: number;
    regno: string;
    fname: number;
    lname: string;
    gender: string;
    // qualification: any;
    mobile_number: string;
    email: string;
    status: any;
}

const InterviewList: React.FC = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { candidateData, loading, success2, error2, loading2 } = useSelector((state: any) => state.candidate);
    const data = candidateData?.AccountDetail || [];
    const totalRecords = candidateData?.DataFinder?.totalrecords || 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success2 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error2 : false;

    const navigateAddScreen = () => {
        history('/candidate/add-interviewee-candidate', {
            state: {
                title: 'Add Interviewee Candidate',
                id: 1,
                cid: 0,
            },
        });
    };

    function getCandidate(size: number, page: number, searchVal: string) {
        const inputJson = {
            Input: {
                status: 'Active',
                candidate_type: 1,
                batch_id: 0,
                project_id: 1,
            },
            DataFinder: {
                pagesize: size,
                currentpage: page,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: searchVal || '',
            },
        };
        dispatch(getCandidateList(inputJson) as any);
    }

    useEffect(() => {
        getCandidate(pageSize, pageIndex, searchValue);
    }, []);

    const viewDetails = (val: any) => {
        history('/candidate/interviewee-candidate-detail', { state: { data: val } });
    };
    const columns: TableProps<DataType>['columns'] = [
        {
            title: 'Reg No',
            dataIndex: 'regno',
            key: 'regno',
        },
        {
            title: 'First Name',
            dataIndex: 'fname',
            key: 'fname',
            width: 400,
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            key: 'lname',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            align: 'center',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            align: 'center',
        },
        {
            title: 'Email ',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Submitted On',
            dataIndex: 'created_on',
            key: 'created_on',
            align: 'center',
            render: (created_on: any) => {
                const originalDate = new Date(created_on) || null;
                return <div>{candidateData ? originalDate?.toLocaleString() : null}</div>;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (_, record) => {
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="pointer my-auto text-end pb-3" onClick={() => editQuestion(record)}>
                            <EditIcon size={34} />
                        </div>
                        <div className="text-warning pointer eye-icon me-2" onClick={() => viewDetails(record)}>
                            <EyeIcon size={20} />
                        </div>
                        <div className="my-auto delete-icon">
                            <Popconfirm title="Delete Candidate" description="Are you sure to delete this Candidate?" onConfirm={() => deleteQuestion(record)} okText="Yes" cancelText="No">
                                <DeleteFilled className="text-danger pointer del-icon" />
                            </Popconfirm>
                        </div>
                    </div>
                );
            },
        },
    ];

    const editQuestion = (item: any) => {
        history('/candidate/add-interviewee-candidate', {
            state: {
                title: 'Update Interviewee Candidate',
                id: 1,
                cid: item.id,
            },
        });
    };

    const deleteQuestion = (item: any) => {
        dispatch(removeCandidate(item.id) as any);
        setShowSuccessmsg(true);
        setShowErrormsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Candidate Deleted Successfully');
            setShowSuccessmsg(false);
            setPageIndex(1);
            setSearchValue('');
            getCandidate(pageSize, 1, '');
        }
        if (errormsg) {
            message.error("Candidate Couldn't be deleted");
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    const paginationConfig: TableProps<DataType>['pagination'] = {
        position: ['bottomRight'],
        current: pageIndex,
        pageSize: pageSize,
        total: totalRecords,
        onChange: (page: number, pageSize: number) => {
            setPageIndex(page);
            setPageSize(pageSize);
            getCandidate(pageSize, page, searchValue);
        },
        itemRender: (current: any, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <Button className="prev-btn">Previous</Button>;
            }
            if (type === 'next') {
                return <Button className="next-btn">Next</Button>;
            }
            return originalElement;
        },
    };
    const handleSearch = (e: any) => {
        if (e.key === 'Enter') {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getCandidate(pageSize, 1, e.target.value);
        }
    };
    const resetSearch = (e: any) => {
        if (e.key === 'Backspace' && !e.target.value) {
            setSearchValue(e.target.value);
            setPageIndex(1);
            getCandidate(pageSize, 1, e.target.value);
        }
    };
    const searchbyBtn = () => {
        setPageIndex(1);
        setPageIndex(1);
        getCandidate(pageSize, 1, searchValue);
    };

    return (
        <div className="my-3">
            <div className="row mb-2 mx-2 mt-0 mb-0">
                <div className="col p-0">
                    <div className="row m-0 pe-1">
                        <div className="col me-auto ps-2 my-auto">
                            <h5 className="text-start text-secondary fw-600 my-auto">Interviewee List</h5>
                        </div>
                        <div className="col-md-3 ms-auto pe-2">
                            <Input
                                onKeyDown={(e) => handleSearch(e)}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                onKeyUp={(e) => resetSearch(e)}
                                prefix={<SearchOutlined onClick={() => searchbyBtn()} />}
                                placeholder="Search"
                            />
                        </div>
                        {/* <div className="col-md-auto px-0">
                            <Select className="rounded-0 pe-0 stat-select" placeholder="Select Status" />
                        </div> */}
                        <div className="col-auto text-start pe-0 ps-1">
                            <Button type="primary" onClick={navigateAddScreen} className="bg-primary">
                                Add
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-0 custom-table px-0">
                <Spin spinning={loading2} className="text-danger" tip="Deleting...">
                    <Table columns={columns} dataSource={data} rowKey="id" loading={loading} pagination={paginationConfig} />
                </Spin>
            </div>
        </div>
    );
};

export default InterviewList;
