import React, { useEffect, useState } from 'react';
import { Modal, message, Spin, Image, Progress, Popconfirm } from 'components/shared/AntComponent';
import { Button, Form, Checkbox, Select, Input, InputNumber, Radio, DatePicker, Upload } from 'components/shared/FormComponent';
import ImgCrop from 'antd-img-crop';
import type { GetProp, UploadProps, GetProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCandidate, getIndividualCandidate } from 'services/store/actions/candidateAction';
import dayjs from 'dayjs';
import { DeleteFilled, FilePdfOutlined, UploadOutlined } from 'components/shared/AntIcons';
import type { UploadFile } from 'antd/es/upload/interface';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { EyeFilled } from '@ant-design/icons';
import ViewDocument from './ViewDocument';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const { TextArea } = Input;
type RangePickerProps = GetProps<typeof DatePicker>;

const AddCandidateForm: React.FC = () => {
    const history = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const { loading1, success1, error1, candidateInfo, loading5 } = useSelector((state: any) => state.candidate);
    const [checkMaster, setCheckMaster] = useState(false);
    const [checkValue, setCheckValue] = useState(false);
    const [docUpload, setDocUpload]: any = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc]: any = useState([]);
    const [profileImg, setProfileImg] = useState('');
    const [selVal, setSelVal] = useState('');
    const [uploadLogo, setUploadLogo]: any = useState([]);
    const dispatch = useDispatch();
    const candidateType = location?.state.id || 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : false;
    const [showErrMsg, setShowErrMsg] = useState(false);
    const errorMsg = showErrMsg ? error1 : false;
    const [checkedEmp, setCheckedEmp] = useState(false);
    const defaultValue = candidateInfo?.data[0] || null;
    const [showImg, setShowImg] = useState(false);
    const [apiUrl, setApiUrl] = useState('');
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [docDetails, setDocDetails]: any = useState([]);
    const [filePath, setFilePath] = useState('');
    const [fileName, setFileName] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isProject, setIsProject] = useState(false);

    const genderOptions = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
    ];
    const positionOptions = [
        { value: 'Software Developer', label: 'Software Developer' },
        { value: 'Test Analyst', label: 'Test Analyst' },
        { value: 'UI/UX Developer', label: 'UI/UX Developer' },
        { value: 'Mobile App Developer', label: 'Mobile App Developer' },
    ];

    const technologyOptions = [
        { value: 'Angular', label: 'Angular' },
        { value: 'React', label: 'React' },
        { value: 'Java', label: 'Java' },
        { value: '.Net', label: '.Net' },
        { value: 'Python', label: 'Python' },
        { value: 'Vue', label: 'Vue' },
        { value: 'Flutter', label: 'Flutter' },
        { value: 'React Native', label: 'React Native' },
        { value: 'Other', label: 'Other' },
    ];

    const technologyOptionsIntern = [
        { value: 'Angular', label: 'Angular' },
        { value: 'React', label: 'React' },
        { value: 'Java', label: 'Java' },
        { value: '.Net', label: '.Net' },
        { value: 'Python', label: 'Python' },
        { value: 'Vue', label: 'Vue' },
        { value: 'Flutter', label: 'Flutter' },
        { value: 'React Native', label: 'React Native' },
        { value: 'General Intern', label: 'General Intern' },
        { value: 'Other', label: 'Other' },
    ];
    const handleAddressChange = (e: any) => {
        const address1 = form.getFieldValue('paddressLine1');
        const address2 = form.getFieldValue('paddressLine2');
        const city = form.getFieldValue('pcity1');
        const state = form.getFieldValue('pstate1');
        const country = form.getFieldValue('pcountry1');
        const pincode = form.getFieldValue('ppincode1');
        setCheckValue(e.target.checked);
        if (e.target.checked) {
            form.setFieldsValue({
                addressLine1: address1,
                addressLine2: address2,
                city1: city,
                state1: state,
                country1: country,
                pincode1: pincode,
            });
        } else {
            form.setFieldsValue({
                addressLine1: '',
                addressLine2: '',
                city1: '',
                state1: '',
                country1: '',
                pincode1: '',
            });
        }
    };

    const navigateBack = () => {
        if (location.state.id == 1) {
            history('/candidate/interviewee');
        } else if (location.state.id == 2) {
            history('/candidate/internship');
        } else {
            history('/candidate/project');
        }
    };

    function getIndividualCandid() {
        dispatch(getIndividualCandidate(location.state.cid) as any);
    }

    useEffect(() => {
        if (location.state.cid !== 0) {
            getIndividualCandid();
        }
    }, [location.state.cid]);

    const beforeUploadChange = (file: any) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M && false;
    };

    const beforeUploadHandler = (file: UploadFile) => {
        // Your logic here
        return false; // Return true to allow uploading, or false to prevent it
    };
    const updateDocDetails = (selVal: any, info: any) => {
        setDocDetails((prevDocDetails: any) => {
            // Check if the item already exists
            const existingIndex = prevDocDetails.findIndex((doc: any) => doc.id === selVal);

            if (existingIndex !== -1) {
                // Update the existing item
                const updatedDocDetails = [...prevDocDetails];
                updatedDocDetails[existingIndex] = { id: selVal, file: info };
                return updatedDocDetails;
            } else {
                // Add a new item
                return [...prevDocDetails, { id: selVal, file: info }];
            }
        });
    };

    const changeFile: UploadProps['onChange'] = (info: any) => {
        setDocUpload(info?.fileList);

        // Ensure selVal is not null or undefined before proceeding
        if (selVal) {
            const s: any[] = [...selectedDoc, selVal];
            const uniqueArray = Array.from(new Set(s));
            setSelectedDoc(uniqueArray);
            updateDocDetails(selVal, info?.fileList);
            form.setFieldsValue({ documents: '' });
            setSelVal('');
        }
    };

    const changeFile1: UploadProps['onChange'] = (info: any) => {
        setUploadLogo(info?.fileList);
        const reader = new FileReader();
        reader.onload = (e) => {
            getProfileImg(e.target?.result as string);
        };
        reader.readAsDataURL(info?.fileList[0]?.originFileObj);
        form.setFieldsValue({ documents: '' });
        setShowImg(true);
    };

    function getProfileImg(val: any) {
        setProfileImg(val);
    }

    const handleChange = (e: any) => {
        setSelVal(e);
    };

    const removeFile = (i: number, id: any) => {
        const doc = docUpload.filter((ids: any, index: number) => index !== i);
        const doc1 = selectedDoc.filter((val: any) => val != id);
        setDocUpload(doc);
        setSelectedDoc(doc1);
        setDocDetails((prevDocDetails: any) => {
            return prevDocDetails.filter((_: any, index: any) => index !== i);
        });
    };

    const fetchApiUrl = async () => {
        try {
            const response = await fetch('/api/url');
            if (response.ok) {
                const data = await response.json();
                setApiUrl(data.apiUrl);
            } else {
                // Handle errors here if needed
            }
        } catch (error) {
            // Handle network or other errors
        }
    };

    useEffect(() => {
        fetchApiUrl();
    }, [dispatch]);

    const submitCandidate = (values: any) => {
        const formData = new FormData();
        const profilepic = uploadLogo[0]?.originFileObj || "''";

        // Initialize document variables to null
        const resume = docDetails
            ? docDetails[0]?.id == 3
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 3
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 3
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 3
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const id_proof = docDetails
            ? docDetails[0]?.id == 1
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 1
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 1
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 1
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const certificate_proof = docDetails
            ? docDetails[0]?.id == 2
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 2
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 2
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 2
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const other_proof = docDetails
            ? docDetails[0]?.id == 4
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 4
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 4
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 4
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const docMapping: any = {
            1: 'id_proof',
            2: 'certificate_proof',
            3: 'resume',
            4: 'other_proof',
        };

        // Append documents to formData, using null for missing values
        formData.append('Resume', resume ? resume : "''");
        formData.append('Id_proof', id_proof ? id_proof : "''");
        formData.append('Certificate_proof', certificate_proof ? certificate_proof : "''");
        formData.append('Other_proof', other_proof ? other_proof : "''");
        formData.append('Profile_pic', profilepic);

        const inputJson = {
            candidateid: defaultValue?.id || 0,
            candidate_type: location.state.id || 1,
            fname: values?.firstName || '',
            mname: values?.middleName || '',
            lname: values?.lastName || '',
            email: values?.email || '',
            dob: values['dob']?.format('YYYY-MM-DD') || null,
            gender: values.gender,
            mobile_number: values?.mobileNo?.toString() || '',
            alternate_number: values.alternateNo?.toString() || '',
            addressline1: values.addressLine1 || '',
            addressline2: values.addressLine2 || '',
            state: values.state1 || '',
            city: values.city1 || '',
            country: values.country1 || '',
            zip: values.pincode1 || '',
            permanent_addressline1: values.paddressLine1 || '',
            permanent_addressline2: values.paddressLine2 || '',
            permanent_state: values.pstate1 || '',
            permanent_city: values.pcity1 || '',
            permanent_country: values.pcountry1 || '',
            permanent_zip: values.ppincode1 || '',
            UG_qualification: values.courseName || '',
            UG_course: values.courseName || '',
            UG_college: values.collegeName || '',
            UG_university: values.universityName || '',
            UG_percentage: values.percentage || '',
            PG_qualification: values.mcourseName || '',
            PG_course: values.mcourseName || '',
            PG_college: values.mcollegeName || '',
            PG_university: values.muniversityName || '',
            PG_percentage: values.mpercentage || '',
            incharge: 'HR',
            experienced_year: Number(values?.experienceYear) || 0,
            currently_employed: values?.currentEmployed || false,
            previous_company: values?.companyDetails || '',
            position_applied: values?.employmentPosition || '',
            acquired_tech: values?.acquiredTechnology || '',
            reference: values.reference || '',
            isProjectIncluded: values.prjIncluded || false,
            duration: values.duration || '',
            InternLang: values.internLang || '',
            projectLanguage: values.prjIncluded ? values.internLang : values.projectLang || '',
            project_id: values.project || null,
            batch_id: Number(values.batch) || null,
            is_same_address: checkValue || false,
        };
        formData.append('InputJson', JSON.stringify(inputJson));
        dispatch(addCandidate({ formData, apiUrl }) as any);
        setShowSuccessmsg(true);
        setShowErrMsg(true);
    };

    const loadData = () => {
        const sVal = [];
        if (defaultValue?.PG_college && defaultValue?.PG_college !== null && defaultValue?.PG_college !== '') {
            setCheckMaster(true);
        } else {
            setCheckMaster(false);
        }
        if (defaultValue?.id_proof && defaultValue?.id_proof !== '') {
            sVal.push('1');
        }
        if (defaultValue?.resume && defaultValue?.resume !== '') {
            sVal.push('3');
        }
        if (defaultValue?.certificate_proof && defaultValue?.certificate_proof !== '') {
            sVal.push('2');
        }
        if (defaultValue?.other_proof && defaultValue?.other_proof !== '') {
            sVal.push('4');
        }
        setSelectedDoc(sVal);
        if (defaultValue?.profile_pic && defaultValue?.profile_pic !== null && defaultValue?.profile_pic !== '' && defaultValue?.profile_pic !== '/media') {
            setProfileImg(apiUrl + defaultValue?.profile_pic);
            setShowImg(true);
        } else {
            setProfileImg('');
            setShowImg(false);
        }

        form.setFieldsValue({
            firstName: defaultValue?.fname,
            middleName: defaultValue?.mname,
            lastName: defaultValue?.lname,
            gender: defaultValue?.gender,
            dob: defaultValue ? dayjs(defaultValue?.dob) : '',
            email: defaultValue?.email,
            mobileNo: defaultValue?.mobile_number,
            alternateNo: defaultValue?.alternate_number,
            paddressLine1: defaultValue?.permanent_addressline1,
            paddressLine2: defaultValue?.permanent_addressline2,
            pstate1: defaultValue?.permanent_state,
            pcity1: defaultValue?.permanent_city,
            pcountry1: defaultValue?.permanent_country,
            ppincode1: defaultValue?.permanent_zip,
            addressLine1: defaultValue?.addressline1,
            addressLine2: defaultValue?.addressline2,
            state1: defaultValue?.state,
            city1: defaultValue?.city,
            country1: defaultValue?.country,
            pincode1: defaultValue?.zip,
            experienceYear: defaultValue?.experienced_year,
            currentEmployed: defaultValue?.currently_employed,
            companyDetails: defaultValue?.previous_company,
            employmentPosition: defaultValue?.position_applied,
            acquiredTechnology: defaultValue?.acquired_tech,
            reference: defaultValue?.reference,
            prjIncluded: defaultValue?.isProjectIncluded,
            duration: defaultValue?.duration,
            internLang: defaultValue?.InternLang,
            projectLang: defaultValue?.isProjectIncluded ? defaultValue?.InternLang : defaultValue?.projectLanguage,
            courseName: defaultValue?.UG_course,
            collegeName: defaultValue?.UG_college,
            universityName: defaultValue?.UG_university,
            percentage: defaultValue?.UG_percentage,
            mcourseName: defaultValue?.PG_course,
            mcollegeName: defaultValue?.PG_college,
            muniversityName: defaultValue?.PG_university,
            mpercentage: defaultValue?.PG_percentage,
            batch: defaultValue?.batch_id?.toString() || '',
            project: defaultValue?.project_id?.toString() || '',
        });
        if (defaultValue) {
            setCheckValue(defaultValue?.is_same_address);
        } else {
            setCheckValue(false);
        }
    };

    useEffect(() => {
        if (candidateInfo?.data && location.state?.cid !== 0 && form) {
            loadData();
        } else {
            form.resetFields();
            setProfileImg('');
            setUploadLogo('');
            setDocDetails([]);
            setDocUpload([]);
            setSelectedDoc([]);
            setUploadLogo([]);
        }
    }, [candidateInfo?.data, location.state?.cid]);

    useEffect(() => {
        if (successmsg) {
            form.resetFields();
            setSelVal('');
            setUploadLogo([]);
            setCheckValue(false);
            setDocUpload([]);
            setSelectedDoc([]);
            message.success('Candidate saved successfully');
            setShowSuccessmsg(false);
            if (defaultValue?.id !== 0) {
                navigateBack();
            }
        }
        if (errorMsg) {
            message.error("Candidate couldn't be added");
            setShowErrMsg(false);
        }
    }, [successmsg, errorMsg]);

    const changeEmployee = (e: any) => {
        setCheckedEmp(e.target.value);
    };

    function initializeDatepicker(defaultDate: dayjs.Dayjs) {}

    const defaultDate = dayjs().subtract(18, 'year');

    initializeDatepicker(defaultDate);

    const disableDate = (date: dayjs.Dayjs): boolean => {
        return date.isAfter(defaultDate, 'day');
    };
    const validateNumber = (_: any, value: any) => {
        if (!/^\d*$/.test(value)) {
            return Promise.reject(new Error('Enter only numbers!'));
        }
        return Promise.resolve();
    };
    const validatePercentage = (rule: any, value: any) => {
        if (!value) {
            return Promise.reject('This field is required');
        }
        const valueStr = value.toString();
        // Check if it's a two-digit integer
        if (/^\d{2}$/.test(valueStr)) {
            return Promise.resolve();
        }
        // Check if it's a decimal number with one digit after the point
        if (/^\d+\.\d{1}$/.test(valueStr)) {
            return Promise.resolve();
        }
        return Promise.reject('Please enter a valid number (2-digit integer or decimal with one digit after the point)');
    };
    const downloadFile = (file: any, name: string) => {
        setFilePath(file);
        setFileName(name);
        setOpenModal(true);
    };
    const closeModal = () => {
        setOpenModal(false);
    };
    const handleProjectField = (e: any) => {
        setIsProject(e.target.value);
    };
    return (
        <div className="my-3 mx-2">
            <div className="d-flex mb-2">
                <h5 className="text-secondary my-auto fw-bold">{location.state.title}</h5>
                <Button type="primary" className="ms-auto btn-back" onClick={navigateBack}>
                    Back
                </Button>
            </div>
            <div className="bg-white p-2">
                <Spin spinning={loading1 || loading5}>
                    <Form
                        form={form}
                        // initialValues={initialValues}
                        onFinish={submitCandidate}
                        layout="vertical"
                    >
                        <div className="title-card">
                            <h6 className="text-dark my-auto text-start p-2 fw-bold">Personal Details</h6>
                        </div>
                        <div className="px-2 py-3">
                            <div className="d-flex">
                                <div className="col-md-10">
                                    <div className="row m-0">
                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="First Name"
                                            name="firstName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item className="col-md-3 col-lg-3" label="Middle Name" name="middleName">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="Last Name"
                                            name="lastName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="Gender"
                                            name="gender"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Select options={genderOptions} />
                                        </Form.Item>
                                    </div>
                                    <div className="row m-0">
                                        <Form.Item className="col-md-3 col-lg-3" label="DOB" name="dob">
                                            <DatePicker defaultPickerValue={defaultDate} placeholder="Please Select" className="w-100" disabledDate={disableDate} format="DD-MM-YYYY" />
                                        </Form.Item>

                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="Email"
                                            name="email"
                                            rules={[
                                                { type: 'email', message: 'Enter a valid email' },
                                                { required: false, message: 'This field is required' },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="Mobile No"
                                            name="mobileNo"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <InputNumber className="w-100" maxLength={10} />
                                        </Form.Item>
                                        <Form.Item className="col-md-3 col-lg-3" label="Alternate No" name="alternateNo">
                                            <InputNumber className="w-100" maxLength={10} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-md-2 image-upload my-auto">
                                    {showImg ? (
                                        <div className="d-flex justify-content-center">
                                            <Image width="140px" className="border" src={profileImg || ''} />
                                            <DeleteFilled
                                                className="text-danger pointer ms-1"
                                                size={17}
                                                onClick={() => {
                                                    setShowImg(!showImg);
                                                    setProfileImg('');
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <Form.Item label="" name="uploadLogo" className="col-md-12 w-100 my-auto pt-1">
                                            <ImgCrop aspect={1} aspectSlider showReset>
                                                <Upload.Dragger
                                                    listType="picture-card"
                                                    className="w-100 logo-cropp"
                                                    fileList={uploadLogo}
                                                    maxCount={1}
                                                    beforeUpload={beforeUploadChange}
                                                    onChange={changeFile1}
                                                    showUploadList
                                                    accept={'.png,.jpg,.jpeg'}
                                                >
                                                    <UploadOutlined /> Upload Photo
                                                </Upload.Dragger>
                                                {profileImg && <DeleteFilled className="text-danger pointer ms-1" size={17} onClick={() => setShowImg(!showImg)} />}
                                                {/* </div> */}
                                            </ImgCrop>
                                        </Form.Item>
                                    )}
                                </div>
                            </div>
                            <div className="row mx-0 mt-2">
                                <div className="col-md-6 col-lg-6 ps-2">
                                    <h6 className="text-dark text-start fw-bold">Permanent Address</h6>
                                    <div className="border p-3">
                                        <Form.Item
                                            label="Address Line1"
                                            name="paddressLine1"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Address Line2"
                                            name="paddressLine2"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <div className="row">
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="City"
                                                name="pcity1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="State"
                                                name="pstate1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="Country"
                                                name="pcountry1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="Pincode"
                                                name="ppincode1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <InputNumber className="w-100" maxLength={10} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 ps-2">
                                    <div className="d-flex">
                                        <h6 className="text-dark fw-bold text-start">Temporary Address</h6>
                                        <div className="ms-auto">
                                            <Checkbox className="pe-1" defaultChecked={checkValue} checked={checkValue} onClick={(e) => handleAddressChange(e)}>
                                                Same as Permanent Address
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <div className="border p-3">
                                        <div className="d-flex"></div>
                                        <Form.Item
                                            label="Address Line1"
                                            name="addressLine1"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Address Line2"
                                            name="addressLine2"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <div className="row">
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="City"
                                                name="city1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="State"
                                                name="state1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="Country"
                                                name="country1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="Pincode"
                                                name="pincode1"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <InputNumber className="w-100" maxLength={10} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {location.state.id === 1 && (
                            <div className="title-card">
                                <h6 className="text-dark my-auto text-start p-2 fw-bold">Experience</h6>
                            </div>
                        )}
                        {location.state.id === 1 && (
                            <div className="px-2 py-3">
                                <div className="row m-0">
                                    <Form.Item className="col-md-3 col-lg-3" label="No of years experienced" name="experienceYear">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="col-md-3 col-lg-3 text-start" label="Are you currently employed?" name="currentEmployed">
                                        <Radio.Group onChange={changeEmployee}>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        className="col-md-6 col-lg-6 text-start"
                                        label="Previous/Current Company details"
                                        name="companyDetails"
                                        rules={[
                                            {
                                                required: checkedEmp,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <TextArea rows={2} />
                                    </Form.Item>
                                </div>
                            </div>
                        )}
                        <div className="title-card">
                            <h6 className="text-dark my-auto text-start p-2 fw-bold">Applied for</h6>
                        </div>
                        {location.state.id === 1 ? (
                            <div className="px-2 py-3">
                                <div className="row m-0">
                                    <Form.Item
                                        className="col-md-3 col-lg-3"
                                        label="Employment position applied for"
                                        name="employmentPosition"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Select options={positionOptions} />
                                    </Form.Item>
                                    <Form.Item
                                        className="col-md-3 col-lg-3"
                                        label="Acquired Technology"
                                        name="acquiredTechnology"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Select options={technologyOptions} />
                                    </Form.Item>
                                    <Form.Item className="col-md-6 col-lg-6" label="Did anyone refer you to our company? If yes, who:" name="reference">
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                        ) : (
                            <div className="px-2 py-3">
                                <div className="row m-0">
                                    {/* <Form.Item className='col-md-3 col-lg-3' label="Position applied for" name="employmentPosition"
                                    rules={
                                        [
                                            {
                                                required: true,
                                                message: "This field is required"
                                            }
                                        ]
                                    }
                                >
                                    <Select options={positionOptions} />
                                </Form.Item> */}
                                    <Form.Item
                                        className="col-md-2 col-lg-2"
                                        label="Select Technology"
                                        name={location.state.id == 2 ? 'internLang' : location.state.id == 3 ? 'projectLang' : ''}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Select options={technologyOptionsIntern} />
                                    </Form.Item>
                                    {location.state.id == 2 ? (
                                        <Form.Item
                                            className="col-md-auto col-lg-auto text-start"
                                            label="Is project included?"
                                            name="prjIncluded"
                                            rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <Radio.Group onChange={handleProjectField}>
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    ) : (
                                        ''
                                    )}
                                    <Form.Item className="col-md-2 col-lg-2" label="Duration (in days)" name="duration" rules={[{ validator: validateNumber }]}>
                                        <Input />
                                    </Form.Item>
                                    {/* <Form.Item
                                        className="col"
                                        label={location.state.id == 2 ? 'Batch' : 'Project'}
                                        name={location.state.id == 2 ? 'batch' : 'project'}
                                        // rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                        <Select options={location.state.option?.filter((item: any) => item.value !== '0')} />
                                    </Form.Item> */}
                                    {/* {isProject ? (
                                        <Form.Item
                                            className="col"
                                            label={'Project'}
                                            name={'project'}
                                            // rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <Select options={location.state.option?.filter((item: any) => item.value !== '0')} />
                                        </Form.Item>
                                    ) : (
                                        ''
                                    )} */}
                                    <Form.Item className="col" label="If you have reference, who:" name="reference">
                                        <Input />
                                    </Form.Item>
                                </div>
                            </div>
                        )}
                        <div className="title-card">
                            <h6 className="text-dark my-auto text-start p-2 fw-bold">Education Details</h6>
                        </div>
                        <div className="px-2 py-3">
                            <div className="text-start mb-2">
                                <strong className="ps-3">Bachelor Degree</strong>
                            </div>
                            <div className="row m-0">
                                <Form.Item
                                    className="col-md-3 col-lg-3"
                                    label="Course Name"
                                    name="courseName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    className="col-md-3 col-lg-3"
                                    label="College Name & Address"
                                    name="collegeName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    className="col-md-3 col-lg-3"
                                    label="University Name"
                                    name="universityName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This field is required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    className="col-md-3 col-lg-3"
                                    label="Percentage/CGPA"
                                    name="percentage"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'This field is required',
                                        },
                                        { validator: validatePercentage },
                                    ]}
                                >
                                    <InputNumber className="w-100" maxLength={5} />
                                </Form.Item>
                            </div>
                            <div className="text-start mb-2">
                                <strong className="ps-3">
                                    <Checkbox
                                        defaultChecked={checkMaster}
                                        checked={checkMaster}
                                        onChange={(e) => {
                                            setCheckMaster(e.target.checked);
                                        }}
                                    >
                                        Master Degree
                                    </Checkbox>
                                </strong>
                            </div>
                            {checkMaster ? (
                                <div className="row m-0">
                                    <Form.Item
                                        className="col-md-3 col-lg-3"
                                        label="Course Name"
                                        name="mcourseName"
                                        rules={[
                                            {
                                                required: checkMaster ? true : false,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="col-md-3 col-lg-3"
                                        label="College Name & Address"
                                        name="mcollegeName"
                                        rules={[
                                            {
                                                required: checkMaster ? true : false,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="col-md-3 col-lg-3"
                                        label="University Name"
                                        name="muniversityName"
                                        rules={[
                                            {
                                                required: checkMaster ? true : false,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="col-md-3 col-lg-3"
                                        label="Percentage/CGPA"
                                        name="mpercentage"
                                        rules={[
                                            {
                                                required: checkMaster ? false : false,
                                                message: 'This field is required',
                                            },
                                            { validator: validatePercentage },
                                        ]}
                                    >
                                        <InputNumber className="w-100" maxLength={3} />
                                    </Form.Item>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="title-card">
                            <h6 className="text-dark my-auto text-start p-2 fw-bold">Documents </h6>
                        </div>

                        <div className="px-2 py-3">
                            <p className="text-start ps-1 instruction-note">
                                <span className="text-danger">*</span>Kindly upload the following documents: ID proof, Consolidated marksheet (or) provisional certificate (or) Degree
                                certificate and Resume
                            </p>
                            <div className="form-group text-start mt-2 px-2 d-flex">
                                <div className="col-md-3">
                                    <Form.Item label="Select Documents">
                                        <Select
                                            className="w-100"
                                            defaultValue={selVal}
                                            value={selVal}
                                            options={[
                                                { value: '1', label: 'ID Proof', disabled: selectedDoc.includes('1') ? true : false },
                                                {
                                                    value: '2',
                                                    label: `${candidateType === 1 ? 'Certificate' : 'Bonafide Certificate'}`,
                                                    disabled: selectedDoc.includes('2') ? true : false,
                                                },
                                                { value: '3', label: `${candidateType === 1 ? 'Resume' : 'College ID'}`, disabled: selectedDoc.includes('3') ? true : false },
                                                { value: '4', label: 'Other', disabled: selectedDoc.includes('4') ? true : false },
                                            ]}
                                            onChange={handleChange}
                                        />
                                    </Form.Item>
                                    <Upload.Dragger
                                        listType="picture-card"
                                        fileList={docUpload}
                                        className="w-100 doc-upload col-md-12"
                                        beforeUpload={beforeUploadHandler}
                                        onChange={changeFile}
                                        maxCount={1}
                                        showUploadList={false}
                                        disabled={selVal !== '' ? false : true}
                                        accept={'.pdf'}
                                    >
                                        <UploadOutlined /> Upload Documents here
                                    </Upload.Dragger>
                                </div>

                                <div className="col ms-2 me-0 my-3 h-100">
                                    {docDetails?.map((item: any, index: number) => {
                                        return (
                                            <div className="p-3 bg-light col-md-6 ms-5 mb-2" key={index}>
                                                <p className="fw-bold mb-1">
                                                    {item.id == 1
                                                        ? 'ID Proof'
                                                        : item.id == 2
                                                          ? candidateType === 1
                                                              ? 'Certificate'
                                                              : 'Bonafide Certificate'
                                                          : item.id == 3
                                                            ? candidateType === 1
                                                                ? 'Resume'
                                                                : 'College ID'
                                                            : item.id == 4
                                                              ? 'Other'
                                                              : ''}
                                                </p>
                                                <div className="d-flex">
                                                    <FilePdfOutlined className="me-2 text-danger" size={30} />
                                                    <p className="text-dark my-auto">{item?.file[0]?.name}</p>
                                                    <Popconfirm
                                                        title="Delete file"
                                                        description="Are you sure to delete the file?"
                                                        onConfirm={() => removeFile(index, item?.id)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <DeleteFilled className="text-danger ms-auto my-auto" />
                                                    </Popconfirm>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex p-2 mx-0 w-100">
                            {defaultValue?.id_proof && location.state.cid !== 0 ? (
                                <div className="col-md-3 ">
                                    <div className="fw-bold text-start ps-1">ID Proof:</div>
                                    <div className="m-1 border bg-light p-3 border-rounded text-center d-flex">
                                        <div>{defaultValue?.id_proof?.split('/')?.pop()}</div>
                                        <EyeFilled
                                            size={20}
                                            onClick={() => downloadFile(defaultValue?.id_proof, `${defaultValue?.fname} ID Proof`)}
                                            className="ms-auto my-auto text-primary"
                                        />
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {defaultValue?.certificate_proof && location.state.cid !== 0 && (
                                <div className="col-md-3 ">
                                    <div className="fw-bold text-start ps-1">{candidateType === 1 ? 'Certificate' : 'Bonafide Certificate'}</div>
                                    <div className="m-1 border bg-light p-3 border-rounded text-center d-flex">
                                        <div> {defaultValue?.certificate_proof?.split('/')?.pop()}</div>
                                        <EyeFilled
                                            size={20}
                                            onClick={() =>
                                                downloadFile(defaultValue?.certificate_proof, `${defaultValue?.fname} ${candidateType === 1 ? 'Certificate' : 'Bonafide Certificate'} Proof`)
                                            }
                                            className="ms-auto my-auto text-primary"
                                        />
                                    </div>
                                </div>
                            )}
                            {defaultValue?.resume && location.state.cid !== 0 ? (
                                <div className="col-md-3">
                                    <div className="fw-bold text-start ps-1">{candidateType === 1 ? 'Resume' : 'College ID'}</div>
                                    <div className="m-1 border bg-light p-3 border-rounded text-center d-flex">
                                        <div>{defaultValue?.resume?.split('/')?.pop()}</div>
                                        <EyeFilled
                                            size={20}
                                            onClick={() => downloadFile(defaultValue?.resume, `${defaultValue?.fname} ${candidateType === 1 ? 'Resume' : 'College ID'} Proof`)}
                                            className="ms-auto my-auto text-primary"
                                        />
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                            {defaultValue?.other_proof && location.state.cid !== 0 ? (
                                <div className="col-md-3">
                                    <div className="fw-bold text-start ps-1">Other Proof:</div>
                                    <div className="m-1 border bg-light p-3 border-rounded text-center d-flex">
                                        <div>{defaultValue?.other_proof?.split('/')?.pop()}</div>
                                        <EyeFilled
                                            size={20}
                                            onClick={() => downloadFile(defaultValue?.other_proof, `${defaultValue?.fname} Other Proof`)}
                                            className="ms-auto my-auto text-primary"
                                        />
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            <div className="">
                                {downloadProgress > 0 && downloadProgress < 1 && (
                                    //     <Progress
                                    //     percent={`${downloadProgress}`}
                                    //     strokeColor={{
                                    //         '0%': '#1F98DF',
                                    //         '100%': '#87d068',
                                    //     }}

                                    // />
                                    // <Progress type="circle" percent={downloadProgress}/>
                                    <progress value={downloadProgress} max="1">
                                        {Math.round(downloadProgress * 100)}%
                                    </progress>
                                )}
                            </div>
                        </div>
                        <div className="row m-0 px-3 mb-3">
                            <Button type="default" className="col-auto ms-auto bg-secondary text-white rounded-0 me-2" onClick={navigateBack}>
                                Cancel
                            </Button>
                            <Button type="primary" className="col-auto me-1 bg-success" loading={loading1} htmlType="submit">
                                Save
                            </Button>
                        </div>
                    </Form>
                </Spin>
            </div>
            <ViewDocument openModal={openModal} filePath={filePath} fileName={fileName} closeModal={closeModal} apiUrl={apiUrl} />
        </div>
    );
};

export default AddCandidateForm;
