import { createAsyncThunk } from '@reduxjs/toolkit';
import ax from 'services/apiService';

const base_url = window.location.origin + '/';

export const getExam = createAsyncThunk('get-exam', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/exams`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addExam = createAsyncThunk('add-exam', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/add-exam`, payload);

        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const deleteExam = createAsyncThunk('delete-exam', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.delete(`${base_url}api/api/exam_management/remove-exam/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// exam_management/examurl
export const guestLogin = createAsyncThunk('guest-login', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/examurl`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// exam_management/exams/1
export const getExamDetail = createAsyncThunk('get-exam-detail', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/exam_management/exams/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
//submit Exam
export const submitExam = createAsyncThunk('guest-login-submit-exam', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/examsubmit`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// exam_management/examresult
export const getIndividualResult = createAsyncThunk('get-individual-result', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/examsubmitanswer`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
