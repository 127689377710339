import { createAsyncThunk } from '@reduxjs/toolkit';
import ax from 'services/apiService';

const base_url = window.location.origin + '/';

// master_data/batch_mdata
export const getBatchDetails = createAsyncThunk('batch-details', async (payload: any) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/batch_mdata`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    }
});
// api/master_data/add-batch
export const saveBatchDetails = createAsyncThunk('save-batch-details', async (payload: any) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/add-batch`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    }
});
// master_data/project_mdata
export const getProjectDetails = createAsyncThunk('project-details', async (payload: any) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/project_mdata`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    }
});
export const saveProjectMasterDetails = createAsyncThunk('add-project-details', async (payload: any) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/add-project`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    }
});
// master_data/candidatebatchinfo/95
export const getBatchData = createAsyncThunk('get-batch-details', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/master_data/candidatebatchinfo/${id}`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});
