import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import Logo from 'assets/img/logo.png';
import type { MenuProps } from 'antd';
import { Menu, Layout, Dropdown, Modal, Image } from 'components/shared/AntComponent';
import 'assets/styles/layout.scss';
import { FemaleIcon } from 'assets/icons/CustomIcons';
import { CaretDownFilled, CloseOutlined, MenuOutlined } from 'components/shared/AntIcons';
import ChangePassword from './modal/ChangePassword';
import LogoutImg from 'assets/img/logout.png';

const { Header, Sider, Content } = Layout;
const { confirm } = Modal;

type LayoutProps = {
    children: ReactNode;
};

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: 'group'): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
const fetchApiUrl = async () => {
    try {
        const response = await fetch('/api/url');
        if (response.ok) {
            const data = await response.json();
            localStorage?.setItem('apiUrl', data.apiUrl);
        } else {
            // Handle errors here if needed
        }
    } catch (error) {
        // Handle network or other errors
    }
};

const items1: MenuProps['items'] = [
    getItem('Dashboard', 'dashboard', null),
    getItem('Exam', 'exam', null),
    getItem('Question Paper', 'question-paper', null),
    getItem('Candidate', 'sub8', null, [
        getItem('Interviewee', 'candidate/interviewee', null),
        getItem('General Internship', 'candidate/internship', null),
        getItem('Project Internship', 'candidate/project', null),
    ]),
    getItem('Question Bank', 'sub2', null, [getItem('Aptitude', 'aptitude', null), getItem('Analytics', 'analytics', null), getItem('Technical', 'technical', null)]),
    getItem('Batch', 'sub3', null, [getItem('Intern Batch', 'batch', null), getItem('Project Batch', 'project', null)]),
];

const items: MenuProps['items'] = [
    {
        label: 'Change Password',
        key: '1',
    },
    {
        label: 'Logout',
        key: '2',
    },
];

const LayoutPage: React.FC<LayoutProps> = ({ children }) => {
    const history = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [hide, setHide] = useState(true);
    const [openPassModal, setOpenPassModal] = useState(false);
    const parentMatch = useMatch('/technical/*');
    const parentMatch1 = useMatch('/analytics/*');
    const parentMatch2 = useMatch('/aptitude/*');
    const parentMatch3 = useMatch('/candidate/add-interviewee-candidate');
    const parentMatch4 = useMatch('/candidate/add-internship-candidate');
    const parentMatch5 = useMatch('/candidate/add-project-candidate');
    const parentMatch6 = useMatch('/candidate/interviewee-candidate-detail');
    const parentMatch7 = useMatch('/candidate/interviewee-candidate-detail/view-result');
    const parentMatch8 = useMatch('/question-paper/*');
    const parentMatch9 = useMatch('/exam/*');
    const parentMatch10 = useMatch('/candidate/internship-detail');
    const parentMatch11 = useMatch('/batch/*');
    const parentMatch12 = useMatch('/project/*');

    const [current, setCurrent] = useState(() => {
        if (parentMatch2) return 'aptitude';
        if (parentMatch1) return 'analytics';
        if (parentMatch) return 'technical';
        if (parentMatch3 || parentMatch6 || parentMatch7) return 'candidate/interviewee';
        if (parentMatch4 || parentMatch10) return 'candidate/internship';
        if (parentMatch5) return 'candidate/project';
        if (parentMatch8) return 'question-paper';
        if (parentMatch9) return 'exam';
        if (parentMatch11) return 'batch';
        if (parentMatch12) return 'project';

        return location.pathname.replace(/^\/+/, '');
    });

    useEffect(() => {
        if (location.pathname && current !== location.pathname) {
            if (location.pathname === '/aptitude' || parentMatch2) {
                setCurrent('aptitude');
            } else if (location.pathname === '/dashboard') {
                setCurrent('dashboard');
            } else if (location.pathname === '/exam' || parentMatch9) {
                setCurrent('exam');
            } else if (location.pathname === '/analytics' || parentMatch1) {
                setCurrent('analytics');
            } else if (location.pathname === '/technical' || parentMatch) {
                setCurrent('technical');
            } else if (location.pathname === '/question-paper' || parentMatch8) {
                setCurrent('question-paper');
            } else if (location.pathname === '/candidate/interviewee' || parentMatch3 || parentMatch7 || parentMatch6) {
                setCurrent('/candidate/interviewee');
            } else if (location.pathname === '/candidate/internship' || parentMatch4 || parentMatch10) {
                setCurrent('/candidate/internship');
            } else if (location.pathname === '/candidate/project' || parentMatch5) {
                setCurrent('/candidate/project');
            } else if (location.pathname === '/batch' || parentMatch11) {
                setCurrent('batch');
            } else if (location.pathname === '/project' || parentMatch12) {
                setCurrent('project');
            }
        }
        fetchApiUrl();
    }, [location, current]);

    const showConfirm = () => {
        confirm({
            title: '',
            icon: null,
            className: 'custom-logout',
            okText: 'Logout',
            content: (
                <div className="d-flex">
                    <Image src={LogoutImg} className="logout-img" height="100px" width="100px" preview={false} />
                    <h5 className="text-center text-end mx-auto fs-18">Are you sure you want to logout?</h5>
                </div>
            ),
            onOk() {
                window.location.href = '/';
                localStorage.clear();
            },
            onCancel() {},
        });
    };

    const handleMenuClick = (e: any) => {
        if (!hide) {
            setHide(!hide);
        }
        history(`/${e.key}`);
    };

    const handleMenuClick1 = (e: any) => {
        if (e.key === '2') {
            showConfirm();
        }
        if (e.key == '1') {
            setOpenPassModal(true);
        }
    };

    const handleChangePass = () => {
        setOpenPassModal(false);
    };

    return (
        <div>
            <Layout>
                <Layout className="">
                    <Sider className={`${hide} bg-transparent border-0 sider shadow-sm`} collapsible collapsed={collapsed} onCollapse={(value: any) => setCollapsed(value)}>
                        <Header className="d-flex bg-white shadow-sm ps-0 pe-2">
                            <div className="bg-white d-flex text-center m-auto">
                                <img src={Logo} alt="kovinalogo" height="40px" />
                            </div>
                            {!hide ? (
                                <div className="text-end pointer">
                                    <CloseOutlined
                                        onClick={() => setHide(!hide)}
                                        size={40}
                                        className={`${hide} bg-transparent text-start me-auto text-primary fw-bold mt-2 closemenu ms-1 p-1 mb-1`}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </Header>
                        <div className="layout-container">
                            <Menu className="col-auto text-start py-3" style={{ width: 256 }} defaultSelectedKeys={[current]} mode="inline" items={items1} onClick={handleMenuClick} />
                        </div>
                    </Sider>
                    <Layout>
                        <Header className="d-flex bg-white shadow-sm ps-0 pe-2">
                            <div className="my-auto menubar-container">
                                <MenuOutlined size={30} onClick={() => setHide(!hide)} className={`${hide} my-auto text-primary menubar me-2 py-1 ps-3`} />
                            </div>
                            <div className="ms-auto text-end my-auto d-flex header-name-cont">
                                <div className="text-end my-auto cursor-arrow">
                                    <h6 className="fw-bold mb-0 text-secondary">{localStorage?.getItem('firstname') + ' ' + localStorage?.getItem('lastname')}</h6>
                                    <h6 className="text-primary mb-0 text-uppercase fw-600 fs-15">{localStorage.getItem('role')}</h6>
                                </div>
                                <Dropdown menu={{ items, onClick: handleMenuClick1 }} trigger={['click']}>
                                    <div className="d-flex pointer align-items-center justify-content-center pointer">
                                        <span className="avatar-clr my-auto">
                                            <FemaleIcon size={58} />
                                        </span>
                                        <CaretDownFilled color="#888" size={20} className="my-auto" />
                                    </div>
                                </Dropdown>
                            </div>
                        </Header>
                        <Content className="m-2">{children}</Content>
                    </Layout>
                </Layout>
            </Layout>
            <ChangePassword openModal={openPassModal} handleChangePass={handleChangePass} />
        </div>
    );
};

export default LayoutPage;
