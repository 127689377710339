import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, DatePicker } from 'components/shared/FormComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCandidateInternList, getCandidateProjectLists } from 'services/store/actions/candidateAction';
import { LoadingOutlined } from '@ant-design/icons';
import { getBatchData, getProjectDetails, saveBatchDetails, saveProjectMasterDetails } from 'services/store/actions/masterDataAction';
import { message } from 'antd';
import dayjs from 'dayjs';

const { TextArea } = Input;

const AddBatch: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { internListInfo, loading3, prjListInfo, loading4 } = useSelector((state: any) => state.candidate);
    const { success1, error1, loading1, success3, error3, loading3: prjLoading } = useSelector((state: any) => state.master);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 || success3 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error1 || error3 : false;
    console.log('projectInfo', location.state);
    const options = !loading3
        ? internListInfo?.AccountDetail?.map((item: any) => {
              return {
                  label: item.fname + ' ' + item.mname + ' ' + item.lname,
                  value: item.id?.toString(),
              };
          }) || []
        : [];
    const prjOptions = !loading4
        ? prjListInfo?.AccountDetail?.map((item: any) => {
              return {
                  label: item.fname + ' ' + item.mname + ' ' + item.lname,
                  value: item.id?.toString(),
              };
          }) || []
        : [];
    const goToPreviousScreen = () => {
        if (location?.state?.type === '1') {
            navigate('/batch');
        } else {
            navigate('/project');
        }
    };

    const submitBatchForm = (value: any) => {
        console.log('values', value);
        if (location.state.type === '1') {
            const inputJson = {
                batch_id: location?.state?.id || 0,
                batch_name: value.batchName || '',
                description: value.description || '',
                batch_st_date: value['startDate']?.format('YYYY-MM-DD') || '',
                batch_en_date: value['endDate']?.format('YYYY-MM-DD') || null,
                mode_of_applied: value.appliedMode || '',
                associated_candidates: value.associatedCandidates?.toString() || null,
                associated_trainers: value?.associateTrainer || '',
                feedback: value?.feedback || '',
                batch_amount: Number(value.amount) || 0,
                status: value.status || 'Yet to Start',
            };
            dispatch(saveBatchDetails(inputJson) as any);
        } else {
            const inputJson1 = {
                project_id: location?.state?.id || 0,
                project_name: value.batchName || '',
                description: value.description || '',
                project_st_date: value['startDate']?.format('YYYY-MM-DD') || '',
                project_en_date: value['endDate']?.format('YYYY-MM-DD') || null,
                mode_of_applied: value.appliedMode || '',
                associated_candidates: value.associatedCandidates?.toString() || null,
                project_lead: value?.associateTrainer || '',
                associated_employees: '',
                feedback: value?.feedback || '',
                project_amount: Number(value.amount) || 0,
                status: value.status || 'Yet to Start',
            };
            dispatch(saveProjectMasterDetails(inputJson1) as any);
        }
        setShowErrormsg(true);
        setShowSuccessmsg(true);
    };

    const validateAmount = (_: any, value: any) => {
        const numericValue = Number(value); // Convert value to a number

        if (isNaN(numericValue)) {
            return Promise.reject(new Error('Enter a valid number'));
        }
        if (numericValue <= 0) {
            return Promise.reject(new Error('Amount must be greater than zero'));
        }
        return Promise.resolve();
    };
    const modeOptions = [
        { value: 'offline', label: 'Offline' },
        { value: 'online', label: 'Online' },
    ];
    const statusOptions = [
        {
            label: 'Yet to start',
            value: 'Yet to Start',
        },
        {
            label: 'Ongoing',
            value: 'Ongoing',
        },
        {
            label: 'Completed',
            value: 'Completed',
        },
    ];

    function getCandidate(pageSize: number, pageIndex: number, search: string, batch: any) {
        const inputJson = {
            Input: {
                status: 'Active',
                candidate_type: 2,
                batch_id: Number(batch) || 0,
                project_id: 0,
            },
            DataFinder: {
                pagesize: pageSize,
                currentpage: pageIndex,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: search || '',
            },
        };
        dispatch(getCandidateInternList(inputJson) as any);
    }

    function getProject(page: number, size: number, search: string) {
        const inputJson = {
            Input: {
                status: 'Active',
                candidate_type: 3,
                batch_id: 0,
                project_id: 0,
            },
            DataFinder: {
                pagesize: size,
                currentpage: page,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: search || '',
            },
        };
        dispatch(getCandidateProjectLists(inputJson) as any);
    }

    const loadData = () => {
        if (location.state.type === '1') {
            form.setFieldsValue({
                batchName: location.state?.data?.batch_name || '',
                description: location.state?.data?.description || '',
                startDate: location.state?.data ? dayjs(location.state?.data?.batch_st_date) : '',
                endDate: location.state?.data ? dayjs(location.state?.data?.batch_en_date) : '',
                amount: location.state?.data?.batch_amount || '',
                status: location.state?.data?.status || '',
                appliedMode: location?.state?.data?.mode_of_applied || '',
                associatedCandidates: location.state?.data ? location.state?.data?.associated_candidates?.split(',') : [] || [],
                associateTrainer: location?.state?.data?.associated_trainers || '',
                feedback: location?.state?.data?.feedback || '',
            });
        } else {
            form.setFieldsValue({
                batchName: location.state?.data?.project_name || '',
                description: location.state?.data?.description || '',
                startDate: location.state?.data ? dayjs(location.state?.data?.project_st_date) : '',
                endDate: location.state?.data ? dayjs(location.state?.data?.project_en_date) : '',
                amount: location.state?.data?.project_amount || '',
                status: location.state?.data?.status || '',
                appliedMode: location?.state?.data?.mode_of_applied || '',
                associatedCandidates: location.state?.data ? location.state?.data?.associated_candidates?.split(',') : [] || [],
                associateTrainer: location?.state?.data?.project_lead || '',
                feedback: location?.state?.data?.feedback || '',
            });
        }
    };

    useEffect(() => {
        if (location.state) {
            loadData();
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state.type === '1') {
            getCandidate(1000000, 1, '', 0);
        } else {
            getProject(1, 1000000, '');
        }
    }, [location.state]);

    useEffect(() => {
        if (successmsg) {
            if (location?.state?.type === '1') {
                message.success(`Batch ${location?.state?.id === 0 ? 'created' : 'updated'} successfully`);
            } else {
                message.success(`Project ${location?.state?.id === 0 ? 'created' : 'updated'} successfully`);
            }
            if (location?.state?.id !== 0) {
                goToPreviousScreen();
            }
            setShowSuccessmsg(false);
            form.resetFields();
        }
        if (errormsg) {
            if (error1?.data) {
                message.error(error1?.data);
            } else {
                message.error(`Batch couldn't be ${location?.state?.id === 0 ? 'created' : 'updated'}`);
            }
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    return (
        <div className="px-2">
            <div className="my-3 px-0">
                <div className="row m-0">
                    <h5 className="my-auto col text-start ps-0 text-secondary fw-bold">
                        {location?.state?.type === '1' ? (location.state?.id === 0 ? 'Create Batch' : 'Update Batch') : location.state?.id === 0 ? 'Create Project' : 'Update Project'}
                    </h5>
                    <div className="ms-auto col text-end my-auto pe-0">
                        <Button type="primary" className="col-auto ms-2 btn-back" onClick={goToPreviousScreen}>
                            Back
                        </Button>
                    </div>
                </div>
            </div>
            <div className="bg-white px-2 py-3">
                <Form form={form} layout="vertical" onFinish={submitBatchForm}>
                    <div className="row m-0">
                        <Form.Item
                            name="batchName"
                            className="col"
                            label={location?.state?.type === '1' ? 'Batch Name' : 'Project Name'}
                            rules={[{ required: true, message: 'This field is required' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="startDate" className="col-md-2" label="Start Date" rules={[{ required: true, message: 'This field is required' }]}>
                            <DatePicker className="w-100" format="MM-DD-YYYY" />
                        </Form.Item>
                        <Form.Item name="endDate" className="col-md-2" label="End Date" rules={[{ required: true, message: 'This field is required' }]}>
                            <DatePicker className="w-100" format="MM-DD-YYYY" />
                        </Form.Item>
                    </div>
                    <div className="row m-0">
                        <Form.Item name="appliedMode" className="col-md-2" label="Mode of applied" rules={[{ required: true, message: 'This field is required' }]}>
                            <Select className="w-100" options={modeOptions} />
                        </Form.Item>
                        <Form.Item
                            name="amount"
                            className="col-md-2"
                            label={location.state.type === '1' ? 'Fee per individual' : 'Total Amount'}
                            rules={[{ required: true, message: 'This field is required' }, { validator: validateAmount }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="associateTrainer" className="col" label="Associate Trainer">
                            <Input />
                        </Form.Item>
                        <Form.Item name="status" className="col" label="Status" rules={[{ required: true, message: 'This field is required' }]}>
                            <Select options={statusOptions} />
                        </Form.Item>
                    </div>
                    <div className="row m-0">
                        <Form.Item name="associatedCandidates" className="col assoc-candid" label="Associate Candidates">
                            <Select
                                options={location?.state?.type === '1' ? options : prjOptions}
                                mode="multiple"
                                filterOption={(input: any, option: any) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())}
                                notFoundContent={
                                    <div className="text-center p-4">
                                        {loading3 || loading4 ? (
                                            <span>
                                                <LoadingOutlined />
                                                Loading...
                                            </span>
                                        ) : (
                                            <span>No candidates available</span>
                                        )}
                                    </div>
                                }
                            />
                        </Form.Item>
                        <Form.Item name="description" className="col" label="Description">
                            <TextArea rows={2} />
                        </Form.Item>
                        <Form.Item name="feedback" className="col" label="Feedback">
                            <TextArea rows={2} />
                        </Form.Item>
                    </div>
                    <div className="text-end pe-2">
                        <Button type="primary" htmlType="submit" loading={loading1 || prjLoading}>
                            Add
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default AddBatch;
