const base64 = require('base-64');
const utf8 = require('utf8');
import axios, { AxiosInstance } from 'axios';

const base_url = window.location.origin + '/';
const ax: AxiosInstance = axios.create({
    baseURL: base_url, // Replace with your API base URL
    timeout: 1000000, // Timeout of 10 seconds
});

// Add a request interceptor
ax.interceptors.request.use(
    (config: any) => {
        // Check if there is a stored username and password in localStorage
        const user = localStorage.getItem('username');
        const pass = localStorage.getItem('password');

        // If both username and password are available, proceed with authentication
        // if (user && pass) {
        const text = user + ':' + pass;
        const bytes = utf8.encode(text);
        const token = base64.encode(bytes);

        // // Set Authorization header for all requests except auth/login
        // if (!config.url.includes('auth/login')) {
        //     config.headers['Authorization'] = 'Basic ' + token;
        // }
        // }
        const formDataEndpoints = ['menu/addcandidate', 'menu/intern_app_form'];
        if (!formDataEndpoints.some((endpoint) => config.url.includes(endpoint))) {
            if (
                !config.url.includes('auth/login') &&
                !config.url.includes('auth/password_reset') &&
                !config.url.includes('auth/reset-password/verify-token') &&
                !config.url.includes('auth/password_reset/confirm/') &&
                !config.url.includes('exam_management/examurl') &&
                !config.url.includes('auth/password_reset') &&
                !config.url.includes('exam_management/examsubmit')
            ) {
                if (token) {
                    config.headers['Authorization'] = 'Basic ' + token;
                    config.headers['Content-Type'] = 'application/json';
                    config.headers['Accept'] = 'application/json';
                }
            } else {
                config.headers['Content-Type'] = 'application/json';
                config.headers['Accept'] = 'application/json';
            }
        } else {
            if (token && !config.url.includes('menu/intern_app_form')) {
                config.headers['Authorization'] = 'Basic ' + token;
            }
            // Only set Content-Type to multipart/form-data if the request data is FormData
            if (config.data instanceof FormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            } else {
                config.headers['Content-Type'] = 'application/json';
            }
            config.headers['Accept'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default ax;
