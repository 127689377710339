import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Form, Checkbox, Select, Input, DatePicker, Upload, InputNumber } from 'components/shared/FormComponent';
import { message, Popconfirm, Result } from 'components/shared/AntComponent';
import { Divider } from 'antd';
import KosoftLogo from 'assets/img/kologo.svg';
import 'assets/styles/registration.scss';
import dayjs from 'dayjs';
import { CloseCircleOutlined, DeleteFilled, FilePdfOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveRegistrationForm } from 'services/store/actions/candidateAction';

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

const RegistrationForm: React.FC = () => {
    const [form] = Form.useForm();
    const currentDates = new Date().toLocaleDateString();
    const [isChecked, setIsChecked] = useState(false);
    const { success9, loading9, error9 } = useSelector((state: any) => state.candidate);
    const [checkValue, setCheckValue] = useState(false);
    const dispatch = useDispatch();
    const [errorForm, setErrorForm] = useState(false);
    const [showErrmsg, setShowErrmsg] = useState('');
    const defaultDate = dayjs().subtract(18, 'year');
    const [imageUrl, setImageUrl] = useState<null | string>(null);
    const [checkMaster, setCheckMaster] = useState(false);
    const [projectIncluded, setProjectIncluded] = useState<string | null>(null);
    const [docUpload, setDocUpload]: any = useState([]);
    const [selectedDoc, setSelectedDoc] = useState<string[]>([]);
    const [selVal, setSelVal] = useState<string>('');
    const [docDetails, setDocDetails] = useState<any[]>([]);
    const [apiUrl, setApiUrl] = useState('');
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success9 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error9 : false;

    const genderOptions = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
    ];

    const onChange = (e: { target: { checked: boolean } }) => {
        setIsChecked(e.target.checked);
    };

    // const disabledDate = (current: Dayjs | null): boolean => {
    //     return !!current && current.startOf('day').isBefore(dayjs().startOf('day'));
    // };

    function initializeDatepicker(defaultDate: dayjs.Dayjs) {}

    const fetchApiUrl = async () => {
        try {
            const response = await fetch('/api/url');
            if (response.ok) {
                const data = await response.json();
                setApiUrl(data.apiUrl);
            } else {
                // Handle errors here if needed
            }
        } catch (error) {
            // Handle network or other errors
        }
    };

    useEffect(() => {
        fetchApiUrl();
    }, [dispatch]);

    initializeDatepicker(defaultDate);

    const disableDate = (date: dayjs.Dayjs): boolean => {
        return date.isAfter(defaultDate, 'day');
    };

    const handleProjectIncludedChange = (e: ChangeEvent<HTMLInputElement>) => {
        setProjectIncluded(e.target.value);
    };

    const beforeUploadHandler = (file: any) => {
        if (!file) {
            message.error('No file selected.');
            return false;
        }
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            // message.error('You can only upload JPG/PNG file!');
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
            return false;
        }
        return true;
    };

    const updateDocDetails = (selVal: string, info: any) => {
        setDocDetails((prevDocDetails) => {
            const existingIndex = prevDocDetails.findIndex((doc) => doc.id === selVal);
            if (existingIndex !== -1) {
                const updatedDocDetails = [...prevDocDetails];
                updatedDocDetails[existingIndex] = { id: selVal, file: info };
                return updatedDocDetails;
            } else {
                return [...prevDocDetails, { id: selVal, file: info }];
            }
        });
    };
    const validateNumber = (_: any, value: any) => {
        if (!/^\d*$/.test(value)) {
            return Promise.reject(new Error('Enter only numbers!'));
        }
        return Promise.resolve();
    };
    const changeFile = (info: { fileList: any }) => {
        setDocUpload(info.fileList);

        if (selVal) {
            setSelectedDoc((prev) => {
                const updatedSet = new Set([...prev, selVal]);
                const updatedArray = Array.from(updatedSet);
                updateDocDetails(selVal, info.fileList);
                setSelVal('');
                return updatedArray;
            });
        }
    };

    const handleChange = (value: string) => {
        setSelVal(value);
    };

    const removeFile = (index: number, id: string) => {
        setDocUpload((prev: any) => prev.filter((_: any, i: any) => i !== index));
        setSelectedDoc((prev) => prev.filter((val) => val !== id));
        setDocDetails((prevDocDetails) => prevDocDetails.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                if (!isChecked) {
                    setErrorForm(true);
                    setShowErrmsg('Please confirm the declaration before submitting.');
                } else {
                    setErrorForm(false);
                    setShowErrmsg('');
                    submitCandidate(values);
                }
            })
            .catch((error) => {
                console.error('Validation failed:', error);
            });
    };

    const onFinishFailed = () => {
        setErrorForm(true);
        setShowErrmsg('Mandatory field(s) not filled.');
    };

    const submitCandidate = (values: any) => {
        const formData = new FormData();
        const profilepic = values.uploadlogo ? values.uploadlogo?.fileList[0].originFileObj : "''";
        const resume = docDetails
            ? docDetails[0]?.id == 3
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 3
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 3
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 3
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const id_proof = docDetails
            ? docDetails[0]?.id == 1
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 1
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 1
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 1
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const certificate_proof = docDetails
            ? docDetails[0]?.id == 2
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 2
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 2
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 2
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const other_proof = docDetails
            ? docDetails[0]?.id == 4
                ? docDetails[0].file[0]?.originFileObj
                : docDetails[1]?.id == 4
                  ? docDetails[1].file[0]?.originFileObj
                  : docDetails[2]?.id == 4
                    ? docDetails[2].file[0]?.originFileObj
                    : docDetails[3]?.id == 4
                      ? docDetails[3].file[0]?.originFileObj
                      : null
            : '';
        const docMapping: any = {
            1: 'id_proof',
            2: 'certificate_proof',
            3: 'resume',
            4: 'other_proof',
        };

        // Append documents to formData, using null for missing values
        formData.append('Resume', resume ? resume : "''");
        formData.append('Id_proof', id_proof ? id_proof : "''");
        formData.append('Certificate_proof', certificate_proof ? certificate_proof : "''");
        formData.append('Other_proof', other_proof ? other_proof : "''");
        formData.append('Profile_pic', profilepic);

        //const comments = values.comments !== undefined ? values.comments : "";
        const middlename = values.middlename !== undefined ? values.middlename : '';
        const alternateno = values.alternateno !== undefined ? values.alternateno : '';
        const fatheroccupation = values.fatheroccupation !== undefined ? values.fatheroccupation : '';
        const motheroccupation = values.motheroccupation !== undefined ? values.motheroccupation : '';
        const percentage = values.percentage !== undefined ? values.percentage : '';
        const mpercentage = values.mpercentage !== undefined ? values.mpercentage : '';
        const prj_include = values.prj_include !== undefined ? values.prj_include : '';
        const duration = values.duration !== undefined ? values.duration : '';
        const reference = values.reference !== undefined ? values.reference : '';

        const permanent_addressline1 = values.permanent_addressline1 !== undefined ? values.permanent_addressline1 : '';
        const permanent_addressline2 = values.permanent_addressline2 !== undefined ? values.permanent_addressline2 : '';
        const permanent_state = values.permanent_state !== undefined ? values.permanent_state : '';
        const permanent_city = values.permanent_city !== undefined ? values.permanent_city : '';
        const permanent_country = values.permanent_country !== undefined ? values.permanent_country : '';
        const permanent_zip = values.permanent_zip !== undefined ? values.permanent_zip : '';
        const UG_qualification = values.UG_qualification !== undefined ? values.UG_qualification : '';
        const PG_qualification = values.PG_qualification !== undefined ? values.PG_qualification : '';
        const incharge = values.incharge !== undefined ? values.incharge : '';
        const experienced_year = values.experienced_year !== undefined ? values.experienced_year : '';
        const currently_employed = values.currently_employed !== undefined ? values.currently_employed : '';
        const previous_company = values.previous_company !== undefined ? values.previous_company : '';
        const position_applied = values.position_applied !== undefined ? values.position_applied : '';
        const acquired_tech = values.acquired_tech !== undefined ? values.acquired_tech : '';
        const projectLanguage = values.projectLanguage! == undefined ? values.projectLanguage : '';
        const val = {
            ...values,
            middlename,
            alternateno,
            fatheroccupation,
            motheroccupation,
            percentage,
            mpercentage,
            permanent_addressline1,
            permanent_addressline2,
            permanent_state,
            permanent_city,
            permanent_country,
            permanent_zip,
            UG_qualification,
            PG_qualification,
            incharge,
            experienced_year,
            currently_employed,
            previous_company,
            position_applied,
            acquired_tech,
            prj_include,
            projectLanguage,
            duration,
            reference,
        };

        const inputJson = {
            candidateid: 0,
            candidate_type: 2,
            fname: val.firstname,
            mname: val.middlename,
            lname: val.lastname,
            email: val.email,
            dob: val.birthDate ? values['birthDate']?.format('YYYY-MM-DD') || null : '',
            gender: val.gender,
            mobile_number: val.phoneno,
            alternate_number: val.alternateno,
            addressline1: values.addressLine1 || '',
            addressline2: values.addressLine2 || '',
            state: values.state1 || '',
            city: values.city1 || '',
            country: values.country1 || '',
            zip: values.pincode1 || '',
            permanent_addressline1: values.paddressLine1 || '',
            permanent_addressline2: values.paddressLine2 || '',
            permanent_state: values.pstate1 || '',
            permanent_city: values.pcity1 || '',
            permanent_country: values.pcountry1 || '',
            permanent_zip: values.ppincode1 || '',
            UG_qualification: val.courseName,
            UG_course: val.courseName,
            UG_college: val.collegeName,
            UG_university: val.universityName,
            UG_percentage: val.percentage,
            PG_qualification: val.mcourseName,
            PG_course: val.mcourseName,
            PG_college: val.mcollegeName,
            PG_university: val.muniversityName,
            PG_percentage: val.mpercentage,
            incharge: 'HR',
            experienced_year: 0,
            currently_employed: false,
            previous_company: '',
            position_applied: ' ',
            acquired_tech: '',
            reference: val.reference,
            isProjectIncluded: val.prj_include,
            duration: val.duration,
            InternLang: val.selecttech,
            projectLanguage: '',
            father_name: val.fathername,
            mother_name: val.mothername,
            father_occupation: val.fatheroccupation,
            mother_occupation: val.motheroccupation,
            is_same_address: checkValue || false,
        };
        formData.append('InputJson', JSON.stringify(inputJson));
        dispatch(saveRegistrationForm({ formData, apiUrl }) as any);
        // setShowErrmsg(true);
        setShowSuccessmsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            // message.success('Form Submitted Successfully');
            // setShowSuccessmsg(false);
            form.resetFields();
            setSelectedDoc([]);
            setCheckMaster(false);
            setDocDetails([]);
            setIsChecked(false);
            setProjectIncluded(null);
        }
    }, [successmsg]);

    const validatePercentage = (rule: any, value: any) => {
        if (!value) {
            return Promise.reject('This field is required');
        }
        const valueStr = value.toString();
        // Check if it's a two-digit integer
        if (/^\d{2}$/.test(valueStr)) {
            return Promise.resolve();
        }
        // Check if it's a decimal number with one digit after the point
        if (/^\d+\.\d{1}$/.test(valueStr)) {
            return Promise.resolve();
        }
        return Promise.reject('Please enter a valid number (2-digit integer or decimal with one digit after the point)');
    };

    const handleAddressChange = (e: any) => {
        const address1 = form.getFieldValue('paddressLine1');
        const address2 = form.getFieldValue('paddressLine2');
        const city = form.getFieldValue('pcity1');
        const state = form.getFieldValue('pstate1');
        const country = form.getFieldValue('pcountry1');
        const pincode = form.getFieldValue('ppincode1');
        setCheckValue(e.target.checked);
        if (e.target.checked) {
            form.setFieldsValue({
                addressLine1: address1,
                addressLine2: address2,
                city1: city,
                state1: state,
                country1: country,
                pincode1: pincode,
            });
        } else {
            form.setFieldsValue({
                addressLine1: '',
                addressLine2: '',
                city1: '',
                state1: '',
                country1: '',
                pincode1: '',
            });
        }
    };

    return (
        <div className="container reg-app">
            {successmsg ? (
                <div className="p-5 vh-100 bg-light d-flex justify-content-center align-items-center">
                    <Result
                        className="p-5"
                        status="success"
                        title={[
                            <div key="resultinfo">
                                <h3>{` Your Application has been submitted successfully `}</h3>
                                <h2 className="text-success mt-5">Thank You</h2>
                            </div>,
                        ]}
                    />
                </div>
            ) : (
                <div className="regapp p-4">
                    <div className="row m-0 top-header">
                        <div className="col-md-6 logo mt-4 text-start">
                            <img src={KosoftLogo} alt="kosoftlogo" height="100" />
                        </div>
                        <div className="col-md-6 add text-end address-cont">
                            <h5 className="text-secondary fw-bold fs-20 kosoft-name">Kosoft IT Solutions Pvt., Ltd.</h5>
                            <p className="fs-16 fw-bold text-gray">No:208, || Floor, 100 feet road,</p>
                            <p className="fs-16 fw-bold text-gray">Mudaliarpet , Puducherry,</p>
                            <p className="fs-16 fw-bold text-gray">Puducherry - 605004.</p>
                            <p className="fs-16 fw-bold text-gray">+91 99435 88775</p>
                        </div>
                        <Divider />
                    </div>
                    <div className="row m-0">
                        <div className="intern-title mb-3 p-0">
                            <div className="row d-flex">
                                <div className="col-md-6">
                                    <h3 className="in">Internship Application Form</h3>
                                    <p className="mb-0 in-ref">Fill Out the Form carefully for registration</p>
                                </div>
                                <div className="col-md-6 reg pe-4">
                                    <div className="d-flex ms-auto mb-2 reg-content">
                                        <p className="text-end my-auto fw-bold ms-auto">Date Of Registration :</p>
                                        <p className="ms-2 my-auto">{currentDates}</p>
                                    </div>
                                    <div className="d-flex ms-auto mb-2 reg-content">
                                        <p className="text-end my-auto fw-bold ms-auto">Registration ID :</p>
                                        <p className="ms-2 my-auto">23KO202</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row m-0">
                        <Form layout={'vertical'} className="p-0" form={form} onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
                            <div className="">
                                <div className="row m-0">
                                    <div className="title-card p-2">
                                        <h6 className="text-dark my-auto text-start fw-bold fs-18">Personal Details</h6>
                                    </div>
                                    <div className="row first-container px-0 m-0">
                                        <div className="col-md-9 ps-2 pe-0 pt-3 pb-2 pnt-cont">
                                            <div className="row m-0">
                                                <Form.Item
                                                    className="col-md-4 mb-2 ps-0 pe-3"
                                                    label="First Name"
                                                    name="firstname"
                                                    rules={[{ required: true, message: 'This field is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item className="col-md-4 mid pe-3" label="Middle Name" name="middlename">
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-4 mb-2 last pe-3"
                                                    label="Last Name"
                                                    name="lastname"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-4 mb-2 ps-0 pe-3"
                                                    label="Birth Date"
                                                    name="birthDate"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker className="w-100" format="DD-MM-YYYY" defaultPickerValue={defaultDate} placeholder=" " disabledDate={disableDate} />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-4 mb-2 mid pe-3 "
                                                    label="Gender"
                                                    name="gender"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Select options={genderOptions} />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-4 mb-2 last pe-3"
                                                    label="Email"
                                                    name="email"
                                                    rules={[
                                                        { type: 'email', message: 'Enter a valid email' },
                                                        { required: true, message: 'This field is required' },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="col-md-3 pt-3 ps-3   text-end upload-cont public-upload-cont">
                                            <Form.Item label="Upload Photo" name="uploadlogo" valuePropName="uploadlogo" className="col-md-12 m-0">
                                                <Upload
                                                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                    listType="picture-card"
                                                    className="avatar-uploader"
                                                    showUploadList={false}
                                                    beforeUpload={(file) => {
                                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                        if (!isJpgOrPng) {
                                                            message.error('You can only upload JPG/PNG file!');
                                                            return false;
                                                        }
                                                        const isLt2M = file.size / 1024 / 1024 < 2; // 2MB limit
                                                        if (!isLt2M) {
                                                            message.error('Image must be smaller than 2MB!');
                                                            return false;
                                                        }
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(file);
                                                        reader.onload = () => setImageUrl(reader.result as string); // Cast to string
                                                        return false;
                                                    }}
                                                >
                                                    {imageUrl ? (
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
                                                            <div className="remove-image-overlay">
                                                                <CloseCircleOutlined onClick={() => setImageUrl('')} />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="ant-upload-text fs-14 text-gray">Upload here</div>
                                                        </div>
                                                    )}
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div className="row m-0 pb-0 px-2 second-container pt-2">
                                        <Form.Item
                                            className="col-md-3 ps-0 pe-3"
                                            label="Phone No"
                                            name="phoneno"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                                { validator: validateNumber },
                                            ]}
                                        >
                                            <Input className="w-100" maxLength={10} />
                                        </Form.Item>
                                        <Form.Item className="col-md-3 " label="Alternate No" name="alternateNo" rules={[{ validator: validateNumber }]}>
                                            <Input className="w-100" maxLength={10} />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 ps-3"
                                            label="Nationality"
                                            name="nationality"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 ps-3 pe-1"
                                            label="Marital Status"
                                            name="maritalstatus"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Select>
                                                <Select.Option value="Married">Married</Select.Option>
                                                <Select.Option value="Unmarried">UnMarried</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row mx-0 mt-2 mb-4 px-0 address-container">
                                    <div className="col-md-6 col-lg-6 ps-2">
                                        <h6 className="text-dark text-start fw-bold form-sub-title">Permanent Address</h6>
                                        <div className="border p-3">
                                            <Form.Item
                                                label="Address Line1"
                                                name="paddressLine1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Address Line2"
                                                name="paddressLine2"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <div className="row">
                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="City"
                                                    name="pcity1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="State"
                                                    name="pstate1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="Country"
                                                    name="pcountry1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="Pincode"
                                                    name="ppincode1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                        { validator: validateNumber },
                                                    ]}
                                                >
                                                    <Input maxLength={6} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-6 ps-3">
                                        <div className="d-flex">
                                            <h6 className="text-dark fw-bold text-start form-sub-title">Temporary Address</h6>
                                            <div className="ms-auto">
                                                <Checkbox className="pe-1" defaultChecked={checkValue} checked={checkValue} onClick={(e) => handleAddressChange(e)}>
                                                    Same as Permanent Address
                                                </Checkbox>
                                            </div>
                                        </div>
                                        <div className="border p-3">
                                            <div className="d-flex"></div>
                                            <Form.Item
                                                label="Address Line1"
                                                name="addressLine1"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Address Line2"
                                                name="addressLine2"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <div className="row">
                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="City"
                                                    name="city1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="State"
                                                    name="state1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="Country"
                                                    name="country1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item
                                                    className="col-md-6 col-lg-6"
                                                    label="Pincode"
                                                    name="pincode1"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'This field is required',
                                                        },
                                                        { validator: validateNumber },
                                                    ]}
                                                >
                                                    <Input maxLength={6} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="title-card p-2">
                                    <h6 className="text-dark my-auto text-start fw-bold fs-18">Applied for</h6>
                                </div>

                                <div className="row m-0 py-3 applied-for-container">
                                    <Form.Item
                                        className="col-md-3"
                                        label="Select Category"
                                        name="selecttech"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'This field is required',
                                            },
                                        ]}
                                    >
                                        <Select>
                                            <Select.Option value="angular">Angular</Select.Option>
                                            <Select.Option value="react">React</Select.Option>
                                            <Select.Option value="java">Java</Select.Option>
                                            <Select.Option value=".net">.Net</Select.Option>
                                            <Select.Option value="python">Python</Select.Option>
                                            <Select.Option value="vue">Vue</Select.Option>
                                            <Select.Option value="flutter">Flutter</Select.Option>
                                            <Select.Option value="reactnative">React Native</Select.Option>
                                            <Select.Option value="generalintern">General Intern</Select.Option>
                                            <Select.Option value="other">Other</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item className="col-md-3 text-start isProject-cont" label="Is project included?" name="prj_include" rules={[]}>
                                        <div>
                                            <input
                                                type="radio"
                                                id="projectIncludedYes"
                                                name="projectIncluded"
                                                value="yes"
                                                checked={projectIncluded === 'yes'}
                                                onChange={handleProjectIncludedChange}
                                                className="custom-radio"
                                            />
                                            &nbsp; <label htmlFor="projectIncludedYes">Yes</label> &nbsp;
                                            <input
                                                type="radio"
                                                id="projectIncludedNo"
                                                name="projectIncluded"
                                                value="no"
                                                checked={projectIncluded === 'no'}
                                                onChange={handleProjectIncludedChange}
                                                className="custom-radio"
                                            />
                                            &nbsp; <label htmlFor="projectIncludedNo">No</label>
                                        </div>
                                    </Form.Item>
                                    <Form.Item className="col-md-3" label="Duration (in days)" name="duration" rules={[{ validator: validateNumber }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        className="col-md-3"
                                        label="If you have reference, who:"
                                        name="reference"
                                        rules={
                                            [
                                                // {
                                                //     required: true,
                                                //     message: "This field is required"
                                                // }
                                            ]
                                        }
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>

                                <div className="title-card p-2">
                                    <h6 className="text-dark my-auto text-start fw-bold fs-18">Education Details</h6>
                                </div>
                                <div className="py-3 px-2">
                                    <div className="text-start mb-2">
                                        <strong className="">Bachelor Degree</strong>
                                    </div>
                                    <div className="row m-0 px-0 bachelor-cont">
                                        <Form.Item
                                            className="col-md-3 col-lg-3 ps-0"
                                            label="Course Name"
                                            name="courseName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="College Name & Address"
                                            name="collegeName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 col-lg-3"
                                            label="University Name"
                                            name="universityName"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'This field is required',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            className="col-md-3 col-lg-3 pe-0"
                                            label="Percentage/CGPA"
                                            name="percentage"
                                            rules={[
                                                { validator: validatePercentage },
                                                // {
                                                //   required: true,
                                                //   message: "This field is required"
                                                // }
                                            ]}
                                        >
                                            <InputNumber className="w-100" maxLength={4} />
                                        </Form.Item>
                                    </div>
                                    <div className="text-start mb-2">
                                        <strong className="">
                                            <Checkbox
                                                defaultChecked={checkMaster}
                                                onChange={(e) => {
                                                    setCheckMaster(e.target.checked);
                                                }}
                                            >
                                                <strong>Master Degree</strong>
                                            </Checkbox>
                                        </strong>
                                    </div>
                                    {checkMaster ? (
                                        <div className="row m-0 master-cont">
                                            <Form.Item
                                                className="col-md-3 col-lg-3 ps-0"
                                                label="Course Name"
                                                name="mcourseName"
                                                rules={[
                                                    {
                                                        required: checkMaster ? true : false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="College Name & Address"
                                                name="mcollegeName"
                                                rules={[
                                                    {
                                                        required: checkMaster ? true : false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3"
                                                label="University Name"
                                                name="muniversityName"
                                                rules={[
                                                    {
                                                        required: checkMaster ? true : false,
                                                        message: 'This field is required',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                className="col-md-3 col-lg-3 pe-0"
                                                label="Percentage/CGPA"
                                                name="mpercentage"
                                                rules={[
                                                    { validator: validatePercentage },
                                                    // {
                                                    //     required: checkMaster ? true : false,
                                                    //     message: "This field is required"
                                                    // }
                                                ]}
                                            >
                                                <InputNumber className="w-100" maxLength={4} />
                                            </Form.Item>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="title-card p-2">
                                    <h6 className="text-dark my-auto text-start fw-bold fs-18">Documents</h6>
                                </div>
                                <div className="px-2 pt-3">
                                    <p className="text-start ps-1 instruction-note">
                                        {/* <span className="text-danger">*</span>  */}
                                        Kindly upload the following documents: ID proof, Consolidated marksheet (or) provisional certificate (or) Degree certificate and Resume
                                    </p>
                                    <div className="form-group text-start mt-2 px-2 d-flex row">
                                        <div className="doc-up col-md-4 mb-3 uploadDoc-cont">
                                            <Form.Item name="document" valuePropName="document" label="Select Documents" rules={[{ required: true, message: 'This field is required' }]}>
                                                <Select className="w-100" value={selVal} onChange={handleChange}>
                                                    <Option value="1" disabled={selectedDoc.includes('1')}>
                                                        ID Proof
                                                    </Option>
                                                    <Option value="2" disabled={selectedDoc.includes('2')}>
                                                        Bonafide Certificate
                                                    </Option>
                                                    <Option value="3" disabled={selectedDoc.includes('3')}>
                                                        College ID
                                                    </Option>
                                                    <Option value="4" disabled={selectedDoc.includes('4')}>
                                                        Other
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                            <Dragger
                                                listType="picture-card"
                                                fileList={docUpload}
                                                className="w-100 doc-upload col-md-12"
                                                beforeUpload={beforeUploadHandler}
                                                onChange={changeFile}
                                                multiple={true}
                                                showUploadList={false}
                                                accept=".pdf"
                                                disabled={!selVal} // Disable upload if no document type is selected
                                            >
                                                <UploadOutlined /> Upload Documents here
                                            </Dragger>
                                        </div>
                                        <div className="col md-8 me-0 h-100 docList-cont">
                                            {docDetails.map((item, index) => (
                                                <div className="doc p-3 bg-light col-md-8 ms-5 mb-2" key={index}>
                                                    <p className="fw-bold mb-1">{item.id === '1' ? 'ID Proof' : item.id === '2' ? 'Certificate' : item.id === '3' ? 'Resume' : 'Other'}</p>
                                                    <div className="d-flex">
                                                        <FilePdfOutlined className="text-primary" style={{ fontSize: '24px' }} />
                                                        <div className="mx-2 text-muted text-truncate" style={{ maxWidth: '150px' }}>
                                                            {item.file[0]?.name}
                                                        </div>
                                                        <div className="ms-auto">
                                                            <Popconfirm
                                                                title="Are you sure to remove this document?"
                                                                onConfirm={() => removeFile(index, item.id)}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button type="link" className="text-danger ms-auto my-auto">
                                                                    <DeleteFilled />
                                                                </Button>
                                                            </Popconfirm>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <Form.Item className="px-2 mx-1 mt-3 comment-section" label="Additional Comments" name="comments">
                                    <TextArea rows={3} className="mt-0" />
                                </Form.Item>

                                <div className="declare mt-5 text-center">
                                    <h5>DECLARATION</h5>
                                    <Checkbox onChange={onChange} checked={isChecked}>
                                        I hereby declare that all statements in the application are true to the best of my knowledge and belief
                                    </Checkbox>
                                </div>
                                <div className="text-center">
                                    {errorForm && <p className="warning-msg">{showErrmsg}</p>}
                                    {/* <Button type="primary" className='mb-2 mt-4 regsub' htmlType="submit" disabled={!isChecked}> */}
                                    <Button type="primary" className="mb-2 mt-4" htmlType="submit" loading={loading9}>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
            <div className="col-md-6 add p-3 text-end address-cont-bottom bg-lightOrange">
                <h5 className="text-secondary fw-bold fs-17 text-center ">Kosoft IT Solutions Pvt., Ltd.</h5>
                <p className="fs-12 mb-0 text-gray text-center">No:208, || Floor, 100 feet road,</p>
                <p className="fs-12 mb-0 text-gray text-center">Mudaliarpet , Puducherry,</p>
                <p className="fs-12 mb-0 text-gray text-center">Puducherry - 605004.</p>
                <p className="fs-12 mb-0 text-gray text-center">+91 99435 88775</p>
            </div>
        </div>
    );
};

export default RegistrationForm;
