import { createAsyncThunk } from '@reduxjs/toolkit';
import ax from 'services/apiService';
const base_url = window.location.origin + '/';

export const userLogin = createAsyncThunk('user-login', async (payload: any,{ rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/login`, payload);
        localStorage.setItem('userid', data.data.userid);
        localStorage.setItem('userid', data.data.userid);
        localStorage.setItem('role', data.data.role);
        localStorage.setItem('accountid', data.data.user_acctid);
        localStorage.setItem('firstname', data.data.firstname);
        localStorage.setItem('lastname', data.data.lastname);
        localStorage.setItem('username', data.data.username);
        localStorage.setItem('password', data.data.password);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const ChangePass = createAsyncThunk('change-user-password', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.put(`${base_url}api/api/auth/change-password`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// auth/password_reset
export const SendEmail = createAsyncThunk('send-email-link', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/auth/password_reset`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});
// auth/reset-password/verify-token/
export const VerifyToken = createAsyncThunk('verify-the-token', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/auth/reset-password/verify-token`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// auth/password_reset/confirm/
export const ConfirmPassword = createAsyncThunk('confirm-the-password', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/auth/password_reset/confirm/`, payload);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});