import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Input } from 'components/shared/FormComponent';
import { message, Image } from 'components/shared/AntComponent';
import { useDispatch, useSelector } from 'react-redux';
import ForgotPassImg from 'assets/img/f2.png';
import KovinaLogo from 'assets/img/logo.png';
import 'assets/styles/auth.scss';
import { ConfirmPassword, VerifyToken } from 'services/store/actions/authAction';

const ResetPassword: React.FC = () => {
    const { loading4, loading3, error4, success4, tokenInfo } = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const url = window.location.href;
    const lastSegment = url.substring(url.lastIndexOf('/') + 1);
    const [showSuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success4 : null;

    useEffect(() => {
        const reqData = {
            token: lastSegment,
        };
        dispatch(VerifyToken(reqData) as any);
    }, [dispatch]);

    useEffect(() => {
        if (tokenInfo) {
            if (tokenInfo?.status == 'success') {
                console.log('');
            } else {
                navigate('/page-not-found');
            }
        }
        if (error4) {
            navigate('/page-not-found');
        }
    }, [tokenInfo, error4]);

    const onFinish = (values: any) => {
        const reqData = {
            password: values.password,
            token: lastSegment,
        };
        dispatch(ConfirmPassword(reqData) as any);
    };

    useEffect(() => {
        if (loading3) {
            setShowsuccessmsg(true);
        }
    }, [loading3]);

    useEffect(() => {
        if (successmsg) {
            if (successmsg.detail == 'Not found') {
                navigate('/Page404');
            } else {
                setShowsuccessmsg(false);
                message.success('Password Changed Successfully');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        }
    }, [successmsg]);

    const validateConfirmPassword = ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    });

    return (
        <div className="container pt-3 ">
            <div className="row mx-0 forgot-cont">
                <div className="col-md-8 mt-4">
                    <Image src={ForgotPassImg} preview={false} width="900px" />
                </div>
                <div className="col my-auto">
                    <div className="site-card-border-less-wrapper login-btn">
                        <div className="">
                            <Image src={KovinaLogo} preview={false} />
                        </div>
                        <h1 className="mt-5 mb-2 text-secondary">Welcome Back</h1>
                        <h5 className="mb-4 text-primary">Create New Password</h5>
                        <Form
                            form={form}
                            layout="vertical"
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="password"
                                className="text-start"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!',
                                    },
                                    {
                                        pattern: passwordPattern,
                                        message:
                                            'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Password" autoComplete="new-password" />
                            </Form.Item>
                            <Form.Item
                                name="cpassword"
                                className="text-start"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required!',
                                    },
                                    validateConfirmPassword,
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" autoComplete="confirm-password" />
                            </Form.Item>
                            <Form.Item
                                wrapperCol={{
                                    span: 24,
                                }}
                            >
                                <Button block className="mt-4 fs-18 text-white" htmlType="submit" size="large" type='primary' loading={loading4}>
                                    Reset Password
                                </Button>
                                <div className="text-end mt-3 pe-1">
                                    <Link to="/login" className="text-decoration-none ">
                                        Back to Login ?
                                    </Link>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
