import React, { useState, useEffect } from 'react';
import { Image, message, Result } from 'components/shared/AntComponent';
import ForgotPassImg from 'assets/img/f2.png';
import KovinaLogo from 'assets/img/logo.png';
import { Button, Form, Input, Checkbox } from 'components/shared/FormComponent';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SendEmail } from 'services/store/actions/authAction';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading1, success1, error1 } = useSelector((state: any) => state.auth);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success1 : false;
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const errormsg = showErrorMsg ? error1 : false;

    const url = window.origin;

    const submitForm = (values: any) => {
        const inputJson = {
            email: values.email,
            domain: url,
        };
        dispatch(SendEmail(inputJson) as any);
        setShowErrorMsg(true);
        setShowSuccessmsg(true);
    };

    useEffect(() => {
        // if (successmsg) {
        //     // message.success('Email sent successfully');
        //     // setShowSuccessmsg(false);
        // }
        if (errormsg) {
            if (error1?.data) {
                message.error(error1?.data);
            } else {
                message.error("Email couldn't send");
            }
            setShowErrorMsg(false);
        }
    }, [successmsg, errormsg]);

    return (
        <div className="container pt-3 ">
            <div className="row mx-0 forgot-cont">
                <div className="col-md-8 mt-4">
                    <Image src={ForgotPassImg} preview={false} width="900px" />
                </div>
                <div className="col ps-0 my-auto  ps-4 ">
                    <div className="mx-auto text-center">
                        <Image src={KovinaLogo} preview={false} />
                    </div>
                    {successmsg || success1 ? (
                        <Result
                            status="success"
                            title={`Password reset email sent successfully!`}
                            subTitle="We have sent you this email in response to your request to reset your password. We recommend that 
                        you keep your password secure and not share it with anyone."
                            className="px-2"
                            extra={[
                                <Button className="text-white w-75 mt-3 p-2 fs-18 login-btn" type="primary" key="console" onClick={() => navigate('/login')}>
                                    Login Here
                                </Button>,
                            ]}
                        />
                    ) : (
                        <>
                            <h2 className="text-primary mt-5">Reset your password</h2>
                            <p className="fs-14">Enter the email address associated with your account, then check your email for a verification code.</p>
                            <Form form={form} layout="vertical" className="mt-5" onFinish={submitForm}>
                                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'This field is required' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item>
                                    <Button className="w-100 bg-primary mt-1 p-2 fs-16 fw-500 login-btn" type="primary" size="large" htmlType="submit" loading={loading1}>
                                        Send verification code
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div className="text-end mt-3 pe-1">
                                <Link to="/login" className="text-decoration-none ">
                                    Back to Login ?
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
