import { createAsyncThunk } from '@reduxjs/toolkit';
import ax from 'services/apiService';

const base_url = window.location.origin + '/';

// master_data/questions_mdata/2
export const getQuestionPaper = createAsyncThunk('get-question-paper', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/questionpapers`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const getQuestions = createAsyncThunk('get-questions-data1', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/exam_management/questionpapers/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addQuestionPaper = createAsyncThunk('add-questionspaper', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/exam_management/questionpaper_association`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getEachQuestionPaper = createAsyncThunk('get-question-paper-each', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/exam_management/qpdatalist/${id}`);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const removeQuestionPaper = createAsyncThunk('delete-questionspaper', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.delete(`${base_url}api/api/exam_management/remove-questionpaper/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
