import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBatchData, getBatchDetails, getProjectDetails, saveBatchDetails, saveProjectMasterDetails } from '../actions/masterDataAction';

interface MasterState {
    loading: boolean;
    success: boolean;
    error: any;
    batchInfo: any;
    loading1: boolean;
    success1: boolean;
    error1: any;
    saveBatchInfo: any;
    //project master
    loading2: boolean;
    success2: boolean;
    error2: any;
    projectInfo: any;
    loading3: boolean;
    success3: boolean;
    error3: any;
    saveProjectInfo: any;
    //get individual batch data
    loading4: boolean;
    error4: any;
    success4: boolean;
    indvBatchInfo: any;
}

const initialState: MasterState = {
    loading: false,
    success: false,
    error: null,
    batchInfo: null,
    loading1: false,
    success1: false,
    error1: null,
    saveBatchInfo: null,
    loading2: false,
    success2: false,
    projectInfo: null,
    error2: null,
    loading3: false,
    success3: false,
    saveProjectInfo: null,
    error3: null,
    loading4: false,
    error4: null,
    indvBatchInfo: null,
    success4: false,
};

const masterSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // questionpaper user
        builder
            .addCase(getBatchDetails.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
                state.batchInfo = null;
            })
            .addCase(getBatchDetails.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.batchInfo = payload;
                state.success = true;
            })
            .addCase(getBatchDetails.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            .addCase(saveBatchDetails.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
                state.saveBatchInfo = null;
            })
            .addCase(saveBatchDetails.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.saveBatchInfo = payload;
                state.success1 = true;
            })
            .addCase(saveBatchDetails.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            .addCase(getProjectDetails.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
                state.projectInfo = null;
            })
            .addCase(getProjectDetails.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.projectInfo = payload;
                state.success2 = true;
            })
            .addCase(getProjectDetails.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            .addCase(saveProjectMasterDetails.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
                state.saveProjectInfo = null;
            })
            .addCase(saveProjectMasterDetails.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.saveProjectInfo = payload;
                state.success3 = true;
            })
            .addCase(saveProjectMasterDetails.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            .addCase(getBatchData.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
                state.indvBatchInfo = null;
            })
            .addCase(getBatchData.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.indvBatchInfo = payload;
                state.success4 = true;
            })
            .addCase(getBatchData.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            });
    },
});

export default masterSlice.reducer;
