import React, { useEffect, useState } from 'react';
import Logo from 'assets/img/logo.png';
import { Image, message } from 'components/shared/AntComponent';
import { Form, Input, Button } from 'components/shared/FormComponent';
import 'assets/styles/auth.scss';
import LoginImage from 'assets/img/login-bg-2.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { guestLogin } from 'services/store/actions/examAction';

type FieldType = {
    username?: string;
};

const GuestLogin: React.FC = () => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { loading3, guestInfo, success3, error3 } = useSelector((state: any) => state.exam);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success3 : false;
    const [showErrmsg, setShowErrmsg] = useState(false);
    const errormsg = showErrmsg ? error3 : false;
    const url = window.location.href;
    const lastSegment = url.substring(url.lastIndexOf('/') + 1);
    localStorage.setItem('guestInfoData', JSON.stringify(guestInfo || null));

    const submitForm = (val: any) => {
        const inputJson = {
            candidate_email: val.username,
            uid: lastSegment,
            // "password": val.password
        };
        dispatch(guestLogin(inputJson) as any);
        setShowErrmsg(true);
        // history('/guest-details');
        setShowSuccessmsg(true);
    };

    useEffect(() => {
        if (successmsg) {
            history('/guest-details');
            setShowSuccessmsg(false);
        }
        if (errormsg) {
            message.error('Login failed');
            setShowErrmsg(false);
        }
    }, [successmsg, errormsg]);

    return (
        <div className="p-0 login-container">
            <div className="row m-0 h-100">
                <div className="col-md-8 ps-0 pe-0 login-bg-container ">
                    <Image src={LoginImage} preview={false} height="100%" />
                </div>
                <div className="col-md-4 my-auto px-0 mx-auto">
                    <div className="w-75 mx-auto">
                        <img src={Logo} width="auto" height="auto" alt="logo" />
                        <h2 className="text-secondary mt-5 mb-1 text-primary fw-bold">Login </h2>
                        <small className="text-gray fw-bold">See what is going on with your business</small>
                        <div className="my-4">
                            <Form form={form} layout="vertical" onFinish={submitForm}>
                                <Form.Item<FieldType> label="Email" name="username" rules={[{ required: true, message: 'This field is required' }]}>
                                    <Input />
                                </Form.Item>
                                <Button htmlType="submit" type="primary" loading={loading3} className="w-100 login-btn text-white mt-4 p-2 fs-18 fw-bold">
                                    Login
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GuestLogin;
