import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, DatePicker, Radio, InputNumber } from 'components/shared/FormComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { EyeIcon, FemaleIcon, MaleIcon } from 'assets/icons/CustomIcons';
import TestResultScreen from './TestResultScreen';
import { useDispatch } from 'react-redux';
import 'assets/styles/form.scss';
import { useSelector } from 'react-redux';
import { addInterviewDetails, getCandidateDetail, getInterviewDetail, getPaymentDetails } from 'services/store/actions/candidateAction';
import { Image, message, Spin } from 'components/shared/AntComponent';
import dayjs from 'dayjs';

const { TextArea } = Input;

const IntervieweeDetail: React.FC = () => {
    const location = useLocation();
    const history = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState<number>(0);
    const candidateData = location.state?.data;
    const { candidInfo, loading6, loading7, success7, error7, interviewListInfo,  } = useSelector((state: any) => state.candidate);
    const { examsubmitInfo } = useSelector((state: any) => state.exam);
    const [showResult, setShowResult] = useState(false);
    const candData = candidInfo?.data?.candidateinfo || null;
    const examData = candidInfo?.data?.exam_result_info || [];
    const originalDate = new Date(examData ? examData[0]?.exam_date : null) || null;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success7 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error7 : false;
    const [interviewID, setInterviewID] = useState(0);
    const [isOnline, setIsOnline] = useState(false);
    
    const navigate = () => {
        setShowResult(true);
    };

    const goToPreviousScreen = () => {
        history('/candidate/interviewee');
    };

    const showStatusScreen = () => {
        setShowResult(false);
    };

    const getResultInfo = () => {
        dispatch(getCandidateDetail(location.state?.data?.id) as any);
    };

    useEffect(() => {
        getResultInfo();
    }, []);

    const submitFunction = (values: any) => {
        const inputjson = {
            interviewid: interviewID || 0,
            candidateid: location.state?.data?.id,
            interviewer_name: values.interviewerName || '',
            interviewer_comments: values.interviewerComments || '',
            hr_name: values.hrName || '',
            hr_comments: values.hrComments || '',
            interview_type: 'Hr Interview',
            interview_st_time: dayjs(values.startTime)?.format('YYYY-MM-DD HH:mm:ss') || null,
            interview_en_time: dayjs(values.endTime)?.format('YYYY-MM-DD HH:mm:ss') || null,
            interviewer_rating: values.interviewerRating || '',
            hr_rating: values.hrRating || '',
            overall_mark: values.total || 0,
            candidate_mark: values.scored || 0,
            is_online: values.isOnline,
        };
        dispatch(addInterviewDetails(inputjson) as any);
        setShowSuccessmsg(true);
        setShowErrormsg(true);
    };

    const getInterviewData = () => {
        const inputJson = {
            UserInput: {
                candidateid: location.state?.data?.id,
            },
            DataFinder: {
                pagesize: 1000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'asc',
                searchdata: '',
            },
        };
        dispatch(getInterviewDetail(inputJson) as any);
    };

    useEffect(() => {
        getInterviewData();
    }, []);

    useEffect(() => {
        if (successmsg) {
            message.success('Interview details saved successfully');
            setShowSuccessmsg(false);
            getInterviewData();
        }
        if (errormsg) {
            if (error7?.data) {
                message.error(error7?.data);
            } else {
                message.error("Interview details couldn't be saved");
            }
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    const options = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
    ];

    const loadData = () => {
        const data = interviewListInfo?.InterviewDetail[0];
        setInterviewID(data?.id || 0);
        form.setFieldsValue({
            startTime: dayjs(data?.interview_st_time) || null,
            endTime: dayjs(data?.interview_en_time) || null,
            interviewerName: data?.interviewer_name || '',
            interviewerComments: data?.interviewer_comments || '',
            hrName: data?.hr_name || '',
            hrComments: data?.hr_comments || '',
            interviewerRating: data?.interviewer_rating || '',
            hrRating: data?.hr_rating || '',
            isOnline: data?.is_online || false,
            total: !data?.is_online ? data?.overall_mark : '',
            scored: !data?.is_online ? data?.candidate_mark : '',
        });
        if (data?.is_online) {
            setIsOnline(true);
        } else {
            setIsOnline(false);
        }
    };

    useEffect(() => {
        if (interviewListInfo) {
            loadData();
        }
    }, [interviewListInfo]);

    useEffect(() => {
        let correctCount = 0;
        if (examData) {
            examData[0]?.result_question.forEach((questionSet: any[]) => {
                questionSet.forEach((item: any) => {
                    const selectedOption = item?.options?.find((opt: any) => opt.option_id === item.your_answer);
                    const isCorrect = selectedOption?.answer_option === true && item.your_answer === selectedOption?.option_id;

                    if (isCorrect) {
                        correctCount++;
                    }
                });
            });
            setTotalCorrectAnswers(correctCount);
        }
    }, [examData]);

    const handleTestMode = (e: any) => {
        setIsOnline(e.target.value);
    };


    return (
        <div>
            <div className="d-flex mt-3 ">
                <div className="p-2 col">
                    <h5 className="text-secondary fw-bold my-auto">Candidate Details</h5>
                </div>
                <div className="ms-auto mt-auto col-auto">
                    <Button type="primary" className="col-auto mb-2 ms-2 btn-back" onClick={goToPreviousScreen}>
                        Back
                    </Button>
                </div>
            </div>
            <Spin spinning={loading6}>
                <div className="row m-0 ps-2 pe-1 mb-2">
                    <div className="col-md-3 bg-white p-2 shadow-sm">
                        <div className="mx-auto text-center">
                            <span className="avatar-clr">
                                {candidateData?.profile_pic ? (
                                    <Image src={localStorage?.getItem('apiUrl') + candidateData?.profile_pic} height="100px" width="100px" className="rounded-circle border" />
                                ) : (
                                    <>{candidateData?.gender?.toLowerCase() == 'male' ? <MaleIcon size={100} /> : <FemaleIcon size={100} />}</>
                                )}
                            </span>
                        </div>
                        <div className="ms-2 my-auto text-center text-capitalize">
                            <h5 className="mb-0 text-secondary fw-bold">
                                {candidateData?.fname} {candidateData?.mname} {candidateData?.lname}
                            </h5>
                            <p className="text-center mb-0 fs-16">{candidateData?.gender}</p>
                        </div>
                        <h6 className="text-secondary fw-bold mt-2 px-2 pt-2 pb-2 title-card border-bottom">Basic Details</h6>
                        <div className="p-2">
                            <div className="row m-0">
                                <div className="form-group col mb-3 ps-0">
                                    <p className="form-label fw-bold mb-0 fs-16">Email</p>
                                    <span className="fs-15">{candData?.email || '--'}</span>
                                </div>
                                <div className="form-group col mb-3 px-0">
                                    <p className="form-label fw-bold mb-0 fs-16">Mobile No</p>
                                    <span className="fs-15">{candData?.mobile_number || '--'}</span>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="form-group col mb-3 ps-0">
                                    <p className="form-label fw-bold mb-0 fs-16">City</p>
                                    <span className="fs-15 text-capitalize">{candData?.city || '--'}</span>
                                </div>
                                <div className="form-group col mb-3 px-0">
                                    <p className="form-label fw-bold mb-0 fs-16">State</p>
                                    <span className="fs-15 text-capitalize">{candData?.state || '--'}</span>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="form-group col mb-3 ps-0">
                                    <p className="form-label fw-bold mb-0 fs-16">Applied For</p>
                                    <span className="fs-15 text-capitalize">{candData?.position_applied || '--'}</span>
                                </div>
                                <div className="form-group col mb-3 px-0">
                                    <p className="form-label fw-bold mb-0 fs-16">Technology known</p>
                                    <span className="fs-15 text-capitalize">{candData?.acquired_tech || '--'}</span>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="form-group col mb-3 ps-0">
                                    <p className="form-label fw-bold mb-0 fs-16">Under Graduate</p>
                                    <span className="fs-15">{candData?.UG_course || '--'}</span>
                                </div>
                                {candData?.PG_college && (
                                    <div className="form-group col mb-3 px-0">
                                        <p className="form-label fw-bold mb-0 fs-16">Post Graduate</p>
                                        <span className="fs-15">{candData?.PG_course || '--'}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <h6 className="text-secondary fw-bold px-2 pt-2 pb-2 border-bottom title-card">Written Exam Result</h6>
                        {candData?.is_exam_attend ? (
                            <div className="p-2">
                                <div className="row m-0">
                                    <div className="form-group col ps-0 mb-3">
                                        <p className="form-label fw-bold mb-0 fs-16">Exam name</p>
                                        <span className="fs-15">{examData ? examData[0]?.exam_title : '---'}</span>
                                    </div>
                                    <div className="form-group col px-0">
                                        <p className="form-label fw-bold mb-0 fs-16">Held on</p>
                                        <span className="fs-15">{originalDate?.toLocaleString() || '---'}</span>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="form-group col ps-0">
                                        <p className="form-label fw-bold mb-0 fs-16">Marks Scored</p>
                                        <span className="fs-15">
                                            <span className="text-primary fw-bold">{totalCorrectAnswers}</span>/{examData ? examData[0]?.total_marks : 0}
                                        </span>
                                    </div>
                                    <div className="form-group col">
                                        <p className="form-label fw-bold mb-1">View Result</p>
                                        <span className="ms-4 text-blue pointer" onClick={navigate}>
                                            <EyeIcon size={20} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="p-4 text-gray text-center">Exam not attended yet</div>
                        )} */}
                    </div>
                    <div className="col bg-white ms-2 shadow-sm p-3 interview-detail-tab">
                        {!showResult ? (
                            <div>
                                <h6 className="fw-bold text-secondary border-bottom pb-2">Interview Duration</h6>
                                <Form layout="vertical" form={form} onFinish={submitFunction}>
                                    <div className="row m-0 ">
                                        <Form.Item name="startTime" className="col-md-auto p-1 me-1" label="Start Time" rules={[{ required: true, message: 'This field is required' }]}>
                                            <DatePicker showTime />
                                        </Form.Item>
                                        <Form.Item name="endTime" className="col-md-auto p-1 me-1" label="End Time" rules={[{ required: true, message: 'This field is required' }]}>
                                            <DatePicker showTime />
                                        </Form.Item>
                                    </div>
                                    <h6 className="fw-bold text-secondary border-bottom pb-2">Written Exam</h6>
                                    <div className="row mx-0 my-3 px-0">
                                        <Form.Item name="isOnline" label="is test conducted online?" className="col-md-auto" rules={[{ required: true, message: 'This field is required' }]}>
                                            <Radio.Group onChange={handleTestMode}>
                                                <Radio value={true}>Yes</Radio>
                                                <Radio value={false}>No</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        {!isOnline ? (
                                            <>
                                                <Form.Item name="total" className="col-md-2" label="Total Marks" rules={[{ required: !isOnline, message: 'This field is required' }]}>
                                                    <InputNumber className="w-100" maxLength={3} />
                                                </Form.Item>
                                                <Form.Item name="scored" className="col-md-2" label="Scored Marks" rules={[{ required: !isOnline, message: 'This field is required' }]}>
                                                    <InputNumber className="w-100" maxLength={3} />
                                                </Form.Item>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                        {isOnline ? (
                                            <>
                                                {candData?.is_exam_attend ? (
                                                    <div className="p-3 bg-light">
                                                        <div className="row m-0">
                                                            <div className="form-group col ps-0 mb-3">
                                                                <p className="form-label fw-bold mb-0 fs-16">Exam name</p>
                                                                <span className="fs-15">{examData ? examData[0]?.exam_title : '---'}</span>
                                                            </div>
                                                            <div className="form-group col px-0">
                                                                <p className="form-label fw-bold mb-0 fs-16">Held on</p>
                                                                <span className="fs-15">{originalDate?.toLocaleString() || '---'}</span>
                                                            </div>
                                                            <div className="form-group col ps-0">
                                                                <p className="form-label fw-bold mb-0 fs-16">Marks Scored</p>
                                                                <span className="fs-15">
                                                                    <span className="text-primary fw-bold">{totalCorrectAnswers}</span>/{examData ? examData[0]?.total_marks : 0}
                                                                </span>
                                                            </div>
                                                            <div className="form-group col">
                                                                <p className="form-label fw-bold mb-1">View Result</p>
                                                                <span className="ms-4 text-blue pointer" onClick={navigate}>
                                                                    <EyeIcon size={20} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="p-4 text-gray text-center">Exam not attended yet</div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="row m-0"></div>
                                        )}
                                    </div>
                                    <h6 className="fw-bold text-secondary border-bottom pb-2">Technical Round</h6>
                                    <div className="row mx-0 my-3 px-0">
                                        <Form.Item
                                            name="interviewerName"
                                            className="col-md-4 p-1 me-1"
                                            label="Interviewer Name"
                                            // rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name="interviewerRating"
                                            className="col-md-2 p-1 me-1"
                                            label="Rating"
                                            //  rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <Select options={options} />
                                        </Form.Item>
                                        <Form.Item
                                            name="interviewerComments"
                                            className="col p-1 ms-1"
                                            label="Comments"
                                            //  rules={[{ required: true, message: 'This field is required' }]}
                                        >
                                            <TextArea />
                                        </Form.Item>
                                    </div>
                                    <h6 className="fw-bold text-secondary border-bottom pb-2">HR Round</h6>
                                    <div className="row m-0 px-0">
                                        <Form.Item name="hrName" className="col-md-4 p-1 me-1" label="HR Name" rules={[{ required: true, message: 'This field is required' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="hrRating" className="col-md-2 p-1 me-1" label="Rating" rules={[{ required: true, message: 'This field is required' }]}>
                                            <Select options={options} />
                                        </Form.Item>
                                        <Form.Item name="hrComments" className="col p-1 ms-1" label="Comments" rules={[{ required: true, message: 'This field is required' }]}>
                                            <TextArea />
                                        </Form.Item>
                                        <div className="text-end mt-auto">
                                            <Button type="primary" className="mt-5" htmlType="submit" loading={loading7}>
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        ) : (
                            <TestResultScreen goToPreviousScreen={showStatusScreen} data={examData ? examData[0]?.result_question : null} />
                        )}
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default IntervieweeDetail;
