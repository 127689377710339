import React from "react";
import Icon from '@ant-design/icons';
import { ReactComponent as MaleUser } from 'assets/img/man.svg';
import { ReactComponent as FemaleUser } from 'assets/img/women.svg';
import { ReactComponent as PencilImg } from 'assets/icons/pen.svg';
import { ReactComponent as DeleteImg } from 'assets/icons/delete.svg';
import { ReactComponent as EyeImg } from 'assets/icons/eye-icon.svg';
// Define props interface for FemaleIcon component
interface IconProps {
    size: number; // Define size prop as number type
}

const FemaleIcon: React.FC<IconProps> = ({ size }) => (
    <Icon component={FemaleUser} style={{ fontSize: size }} />
);
const MaleIcon: React.FC<IconProps> = ({ size }) => (
    <Icon component={MaleUser} style={{ fontSize: size }} />
);
const EditIcon: React.FC<IconProps> = ({ size }) => (
  <Icon component={PencilImg} style={{ fontSize: size }} />
);
const DeleteIcon: React.FC<IconProps> = ({ size }) => (
  <Icon component={DeleteImg} style={{ fontSize: size }} />
);
const EyeIcon: React.FC<IconProps> = ({ size }) => (
  <Icon component={EyeImg} style={{ fontSize: size }} />
);

export {MaleIcon,FemaleIcon,EditIcon,DeleteIcon,EyeIcon}