import React, { useState, useEffect } from 'react';
import html2pdf from 'html2pdf.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, InputNumber } from 'components/shared/FormComponent';
import { Image, Spin, message } from 'components/shared/AntComponent';
import { FemaleIcon, MaleIcon } from 'assets/icons/CustomIcons';
import { useDispatch, useSelector } from 'react-redux';
import TopbarImg from 'assets/img/kosoft-header.svg';
import 'assets/styles/template.scss';
import dayjs from 'dayjs';
import { getPaymentDetails, payAmount } from 'services/store/actions/candidateAction';
import { getBatchDetails } from 'services/store/actions/masterDataAction';

function formatDate(date: any) {
    return dayjs(date).format('DD.MM.YYYY');
}

const InternPayment: React.FC = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [apiUrl, setApiUrl] = useState('');
    const { batchInfo, loading } = useSelector((state: any) => state.master);
    const candidateData = location.state?.data || null;
    const { paymentInfo, loading11, loading10, error10, success10 } = useSelector((state: any) => state.candidate);
    const totalAmount = !loading && batchInfo?.BatchDetail ? batchInfo?.BatchDetail?.[0]?.batch_amount : 0;
    const balanceAmount =
        paymentInfo?.data && paymentInfo?.data?.length > 0
            ? paymentInfo?.data?.[paymentInfo?.data?.length - 1]?.balance_amount
            : batchInfo?.BatchDetail
              ? batchInfo?.BatchDetail?.[0]?.batch_amount || 0
              : 0;
    const paidAmount = paymentInfo?.data && paymentInfo?.data?.length > 0 ? paymentInfo?.data?.[paymentInfo?.data?.length - 1]?.total_amount : 0;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success10 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error10 : false;

    const goToPreviousScreen = () => {
        navigate('/candidate/internship');
    };

    const validateAmount = (_: any, value: any) => {
        if (value > balanceAmount) {
            return Promise.reject(new Error(`Amount cannot exceed the balance amount of ${balanceAmount}`));
        }
        if (typeof value !== 'number' || isNaN(value)) {
            return Promise.reject(new Error('Enter a valid number'));
        }
        if (value <= 0) {
            return Promise.reject(new Error('Amount must be greater than zero'));
        }
        return Promise.resolve();
    };

    const handleDownload = () => {
        const opt = {
            margin: [0, 0, 0, 0],
            filename: 'report.pdf',
            image: { type: 'jpeg', quality: 0.8 },
            html2canvas: { scale: 2, loggin: true, dpi: 280, letterRendering: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'Portrait' },
            enableLinks: true,
        };
        const element1: any = document.getElementById('page1');
        if (element1) {
            const element1Clone = element1?.cloneNode(true) as HTMLElement;
            const wrapper = document.createElement('div');

            wrapper.appendChild(element1Clone);
            try {
                html2pdf().from(wrapper).set(opt).save();
            } catch (error: any) {
                console.log('');
            }
        }
    };

    const getPaymentInfo = () => {
        dispatch(getPaymentDetails(candidateData?.id) as any);
    };

    useEffect(() => {
        getPaymentInfo();
    }, []);
    function getBatchData() {
        const inputJson = {
            UserInput: {
                batch_id: candidateData?.batch_id || 0,
            },
            DataFinder: {
                pagesize: 10000,
                currentpage: 1,
                sortbycolumn: '',
                sortby: 'desc',
                searchdata: '',
            },
        };
        dispatch(getBatchDetails(inputJson) as any);
    }

    useEffect(() => {
        if (candidateData) {
            getBatchData();
        }
    }, [candidateData]);


    const submitPay = (value: any) => {
        const inputJson = {
            candidateid: candidateData?.id,
            batch_id: candidateData?.batch_id,
            paid_amount: value.amount,
        };
        dispatch(payAmount(inputJson) as any);
        setShowSuccessmsg(true);
        setShowErrormsg(true);
    };
    useEffect(() => {
        if (successmsg) {
            message.success('Payment Done Successfully');
            setShowSuccessmsg(false);
            getPaymentInfo();
            form.resetFields();
        }
        if (errormsg) {
            message.error("Payment couldn't be made");
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);
    const fetchApiUrl = async () => {
        try {
            const response = await fetch('/api/url');
            if (response.ok) {
                const data = await response.json();
                setApiUrl(data.apiUrl);

            } else {
                // Handle errors here if needed
            }
        } catch (error) {
            // Handle network or other errors
        }
    };

    useEffect(() => {
        fetchApiUrl();
    }, []);

    return (
        <div>
            <div className="d-flex mt-3 mb-2">
                <h5 className="text-secondary col ps-2 fw-bold my-auto">Intern Details</h5>
                <div className="ms-auto mt-auto col-auto">
                    <Button type="primary" className="btn-back" onClick={goToPreviousScreen}>
                        Back
                    </Button>
                </div>
            </div>
            <div className="row m-0 ps-2 pe-1 mb-2">
                <div className="col-md-3 bg-white p-2  shadow-sm">
                    <div className="w-100 mt-3 mb-2 ps-2 text-center">
                        <div className="avatar-clr text-center">
                            {candidateData?.profile_pic ? (
                                <Image src={apiUrl + candidateData?.profile_pic} width="70px" height="70px" className="border shadow-sm rounded-circle" />
                            ) : (
                                <>{candidateData?.gender?.toLowerCase() == 'male' ? <MaleIcon size={100} /> : <FemaleIcon size={100} />}</>
                            )}
                        </div>
                        <div className="ms-2 my-2 text-capitalize">
                            <h5 className="mb-0 text-primary fw-bold">
                                {candidateData?.fname} {candidateData?.mname} {candidateData?.lname}
                            </h5>
                            <p className="text-center text-gray fw-bold mb-0 fs-16">{candidateData?.gender}</p>
                        </div>
                    </div>
                    <h6 className="text-secondary fw-bold px-2 pt-2 pb-2 title-card border-bottom">Basic Details</h6>
                    <div className="p-2">
                        <div className="row m-0">
                            <div className="form-group col-md-6 mb-3 ps-0">
                                <p className="form-label fw-bold mb-0 fs-16">Email</p>
                                <span className="fs-15">{candidateData?.email}</span>
                            </div>
                            <div className="form-group col-md-6 mb-3 px-0">
                                <p className="form-label fw-bold mb-0 fs-16">Mobile No</p>
                                <span className="fs-15">{candidateData?.mobile_number}</span>
                            </div>

                            <div className="form-group col-md-6 mb-3 ps-0">
                                <p className="form-label fw-bold mb-0 fs-16">City</p>
                                <span className="fs-15 text-capitalize">{candidateData?.city}</span>
                            </div>
                            <div className="form-group col-md-6 mb-3 px-0">
                                <p className="form-label fw-bold mb-0 fs-16">State</p>
                                <span className="fs-15 text-capitalize">{candidateData?.state}</span>
                            </div>

                            <div className="form-group col-md-6 mb-3 ps-0">
                                <p className="form-label fw-bold mb-0 fs-16">Applied For</p>
                                <span className="fs-15 text-capitalize">{candidateData?.position_applied || '--'}</span>
                            </div>
                            <div className="form-group col-md-6 mb-3 px-0">
                                <p className="form-label fw-bold mb-0 fs-16">Technology known</p>
                                <span className="fs-15 text-capitalize">{candidateData?.acquired_tech || '--'}</span>
                            </div>

                            <div className="form-group col-md-6 mb-3 ps-0">
                                <p className="form-label fw-bold mb-0 fs-16">Under Graduate</p>
                                <span className="fs-15">{candidateData?.UG_course}</span>
                            </div>
                            {candidateData?.PG_college && (
                                <div className="form-group col-md-6 mb-3 px-0">
                                    <p className="form-label fw-bold mb-0 fs-16">Post Graduate</p>
                                    <span className="fs-15">{candidateData?.PG_course}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <h6 className="text-secondary fw-bold px-2 pt-2 pb-2 title-card border-bottom">Certificate</h6>
                    <div className="p-2">
                        Download course completion certificate
                        <a className="text-blue text-decoration-underline ps-2" onClick={handleDownload}>
                            here
                        </a>
                    </div>
                </div>
                <div className="col ms-3 bg-white interview-detail-tab p-3">
                    <h6 className="fw-bold text-secondary border-bottom pb-2">Batch Details</h6>
                    <div className="p-1 d-flex mb-4">
                        <div className="col-md-3 ps-2">
                            <label className="fs-15">Batch #</label>
                            <div className="border ps-3 py-2 mt-1">BA2024</div>
                        </div>
                        <div className="col-md-3 ps-2">
                            <label className="fs-15">Batch Name</label>
                            <div className="border ps-3 py-2 mt-1">Batch-2024</div>
                        </div>
                        <div className="col-md-3 px-2">
                            <label className="fs-15">Start Date</label>
                            <div className="border ps-3 py-2 mt-1">12-05-2024</div>
                        </div>
                        <div className="col-md-3 pe-2">
                            <label className="fs-15">End Date</label>
                            <div className="border ps-3 py-2 mt-1">12-07-2024</div>
                        </div>
                    </div>
                    <h6 className="fw-bold text-secondary border-bottom pb-2">Payment Details</h6>
                    <Spin spinning={loading11}>
                        <div className="p-2 d-flex bg-light">
                            <div className="col-md-3 pe-2">
                                <label className="fs-15">Total Amount</label>
                                <div className="text-gray fw-500 ms-2 mt-1 fs-15">Rs. {totalAmount}</div>
                            </div>
                            <div className="col-md-3 pe-2">
                                <label className="fs-15">Paid Amount</label>
                                <div className="text-gray fw-500 ms-2 mt-1 fs-15">Rs. {paidAmount}</div>
                            </div>
                            <div className="col-md-3 pe-2">
                                <label className="fs-15">Balance Amount</label>
                                <div className="text-gray fw-500 ms-2 mt-1 fs-15">Rs. {balanceAmount}</div>
                            </div>
                        </div>
                    </Spin>
                    {paidAmount == 0 ? (
                        ''
                    ) : (
                        <Form form={form} layout="vertical" onFinish={submitPay} className="w-100 mt-4 p-2 custom-form d-flex w-100">
                            <Form.Item
                                name="amount"
                                label=""
                                className="col-md-2 me-2 mb-auto"
                                rules={[
                                    {
                                        required: true,
                                        message: 'This field is required',
                                    },
                                    {
                                        validator: validateAmount,
                                    },
                                ]}
                            >
                                <InputNumber className="w-100" placeholder="Enter Amount to be paid" />
                            </Form.Item>
                            <Button type="primary" className="mb-auto py-2" htmlType="submit" loading={loading10}>
                                Save
                            </Button>
                        </Form>
                    )}
                </div>
            </div>
            {/* */}
            <div style={{ display: 'none' }}>
                <div className="mx-0 p-0 page-one bg-white" id="page1">
                    <img src={TopbarImg} alt="header" style={{ marginTop: '-20px', visibility: 'hidden' }} />
                    <div className="page-content pt-5">
                        <h5 className="text-center mt-5 fw-bold pt-4" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                            Certificate of Internship Completion
                        </h5>
                        <div className="my-3 text-center p-4 ">
                            <p className="fs-16" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                In Recognition of the commitment to Achieve Professional Excellence, this certificate has been awarded to
                                <strong className="px-1 text-capitalize" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                    {candidateData?.gender === 'male' ? 'Mr.' : 'Ms.'}
                                    {candidateData?.fname + ' ' + candidateData?.lname + ' '}, {candidateData?.PG_college !== '' ? candidateData?.PG_course : candidateData?.UG_course}
                                </strong>
                                Studying in
                                <strong className="ps-1" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                    {candidateData?.PG_college !== '' ? candidateData?.PG_college : candidateData?.UG_college},
                                </strong>{' '}
                                Tindivanam Rd, Vandavasi, Tamil Nadu 604408.
                            </p>
                            <p className="fs-15 px-5 mt-5 fs-16" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                For Successful completion of Internship Program From 19.08.2024 to 30.08.2024
                                <strong className="ps-1" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                    at <br /> Kosoft IT Solutions Pvt Ltd, Pondicherry.
                                </strong>
                            </p>
                        </div>
                        <p className="text-center fs-16" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                            Congratulations and Best Wishes!{' '}
                        </p>
                        <div className="px-5 pt-5 mt-5 mb-5">
                            <p className="text-start mb-1 pt-5" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                Yours,
                            </p>
                            <p className="text-start mb-1 fw-bold fs-15" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                For Kosoft IT Solutions Pvt Ltd,
                            </p>
                        </div>
                        <div className="px-5 pt-5">
                            <p className="text-start mb-1 fs-15 fw-bold pt-4" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                Sarojini Ravi
                            </p>
                            <p className="text-start mb-1 fs-15 fw-bold" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                (HR Manager)
                            </p>
                        </div>
                    </div>
                    <div className="footer" style={{ visibility: 'hidden' }}>
                        <div className="text-center">
                            <p className="mb-0 fw-500 fs-10" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                KOSOFT IT SOLUTIONS PVT.LTD.,
                            </p>
                            <p className="mb-0 fw-500 fs-10 mx-0">
                                <span className="fw-bold pe-2 fs-10" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                    Registered Address:
                                </span>
                                No:109, Montorsier Street, Puducherry-605001. |{' '}
                                <span className="fw-bold px-2" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                    Functional Address:
                                </span>
                                No:208, 2nd Floor, 100 Feet Road, Mudaliarpet, Puducherry-605004.
                            </p>
                            <p className="fs-10 fw-500 mb-1" style={{ fontFamily: 'Times New Roman, Times, serif', fontStyle: 'normal' }}>
                                Mobile:9943588775, 9943588776
                            </p>
                        </div>
                        <div className="bg-red p-2 mb-2"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InternPayment;
