import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import ax from 'services/apiService';

const base_url = window.location.origin + '/';

export const getCandidateList = createAsyncThunk('candidatelist', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/menu/candidate`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const getCandidateInternList = createAsyncThunk('candidatelist2', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/menu/candidate`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const getCandidateProjectLists = createAsyncThunk('candidatelist3', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/menu/candidate`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addCandidate = createAsyncThunk('add-candidate', async ({ formData, apiUrl }: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${apiUrl}/api/menu/addcandidate`, formData);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const removeCandidate = createAsyncThunk('delete-candidate', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.delete(`${base_url}api/api/menu/remove-candidate/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// getIndividualCandidate
export const getIndividualCandidate = createAsyncThunk('get-individual', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/menu/candidate/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getCandidateDetail = createAsyncThunk('get-candidate', async (id: number, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/exam_management/candidateresult/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addInterviewDetails = createAsyncThunk('add-interview-comments', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/menu/interviewinfo`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const getInterviewDetail = createAsyncThunk('get-interview-comments', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/menu/interviewinfolist`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
export const saveRegistrationForm = createAsyncThunk('reg-form', async ({ formData, apiUrl }: any) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { data } = await axios.post(`${apiUrl}/api/menu/intern_app_form`, formData, config);
        return data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return error.response.data;
        }
    }
});
// master_data/c_batchpaidamount
export const payAmount = createAsyncThunk('pay-form', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/c_batchpaidamount`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
// master_data/c_batchpaidamountlist/99
export const getPaymentDetails = createAsyncThunk('payment-form', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/master_data/c_batchpaidamountlist/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
