import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'components/shared/FormComponent';
import { Modal, message } from 'components/shared/AntComponent';
import { useDispatch } from 'react-redux';
import { ChangePass } from 'services/store/actions/authAction';
import { useSelector } from 'react-redux';

interface ChildProps {
    openModal: boolean;
    handleChangePass: () => void;
}

const ChangePassword: React.FC<ChildProps> = ({ openModal, handleChangePass }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [passModal, setPassModal] = useState(openModal);
    const { success5, error5, loading5, changePassInfo } = useSelector((state: any) => state.auth);
    const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success5 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error5 : false;

    const validateConfirmPassword = ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    });

    useEffect(() => {
        setPassModal(openModal);
    }, [openModal]);

    const handleCancel1 = () => {
        setPassModal(false);
        handleChangePass();
    };

    const onFinish = async (values: any) => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();
            const reqData = {
                old_password: values.oldpassword,
                new_password: values.password,
            };
            dispatch(ChangePass(reqData) as any);
            setShowErrormsg(true);
            setShowSuccessmsg(true);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Password changed successfully');
            setShowSuccessmsg(false);
        }
        if (errormsg) {
            if (error5?.old_password) {
                message.error('Old Password is wrong');
            } else {
                message.error("Password couldn't be changed");
            }
            setShowErrormsg(false);
        }
        handleCancel1();
        form.resetFields();
    }, [successmsg, errormsg]);

    return (
        <div>
            <Modal
                title="Change Password"
                open={openModal}
                // onOk={handleOk}
                onCancel={handleCancel1}
                maskClosable={false}
                // confirmLoading={loading2}
                footer={[
                    <Button key="back" onClick={handleCancel1}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={loading5} onClick={onFinish}>
                        Reset Password
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical" name="basic" autoComplete="off">
                    <Form.Item
                        name="oldpassword"
                        label="Old Password"
                        className="w-100"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" autoComplete="old-password" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="New Password"
                        className="w-100"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            {
                                pattern: passwordPattern,
                                message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character',
                            },
                        ]}
                    >
                        <Input.Password placeholder="Password" autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="cpassword"
                        className="w-100"
                        label="New Password"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            validateConfirmPassword,
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" autoComplete="confirm-password" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ChangePassword;
