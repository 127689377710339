import { createAsyncThunk } from '@reduxjs/toolkit';
import ax from 'services/apiService';

const base_url = window.location.origin + '/';

export const getQuestionBank = createAsyncThunk('questionsbank', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/questions_mdata`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

// master_data/questions_mdata/2
export const getQuestionData = createAsyncThunk('get-question-data', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.get(`${base_url}api/api/master_data/questions_mdata/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const addQuestionBank = createAsyncThunk('add-questions', async (payload: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${base_url}api/api/master_data/add-questions`, payload);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});

export const deleteQuestionBank = createAsyncThunk('delete-questions', async (id: any, { rejectWithValue }) => {
    try {
        const { data } = await ax.delete(`${base_url}api/api/master_data/remove-questions/${id}`);
        return data;
    } catch (error: any) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.message);
        }
    }
});
