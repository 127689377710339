import React, { useState, useEffect } from 'react';
import { message, Modal } from 'components/shared/AntComponent';
import { Form, Input, DatePicker, Select } from 'components/shared/FormComponent';
import { useDispatch } from 'react-redux';
import { saveBatchDetails, saveProjectMasterDetails } from 'services/store/actions/masterDataAction';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

interface ChildProps {
    openModal: boolean;
    rowData: any;
    closeModal: () => void;
    callbackFunc: () => void;
}
const AddProjectModal: React.FC<ChildProps> = ({ openModal, rowData, closeModal, callbackFunc }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { success3, error3, loading3 } = useSelector((state: any) => state.master);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success3 : false;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error3 : false;

    const statusOptions = [
        {
            label: 'Completed',
            value: 'Completed',
        },
        {
            label: 'Ongoing',
            value: 'Ongoing',
        },
        {
            label: 'Yet to start',
            value: 'Yet to Start',
        },
    ];

    const submitBatchForm = async () => {
        try {
            await form.validateFields();
            const value = form.getFieldsValue();
            const inputJson = {
                project_id: rowData ? rowData?.id : 0,
                project_name: value.projectname || '',
                description: value.description || '',
                project_st_date: value['startDate']?.format('YYYY-MM-DD') || '',
                project_en_date: value['endDate']?.format('YYYY-MM-DD') || null,
                project_amount: value.amount,
                status: value.status,
            };
            dispatch(saveProjectMasterDetails(inputJson) as any);
            setShowErrormsg(true);
            setShowSuccessmsg(true);
        } catch (error: any) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (successmsg) {
            message.success(`Project ${rowData ? 'updated' : 'created'} successfully`);
            setShowSuccessmsg(false);
            callbackFunc();
            closeModal();
            form.resetFields();
        }
        if (errormsg) {
            if (error3?.data) {
                message.error(error3?.data);
            } else {
                message.error(`Project couldn't be ${rowData ? 'updated' : 'created'}`);
            }
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    const loadData = () => {
        form.setFieldsValue({
            projectname: rowData?.project_name || '',
            description: rowData?.description || '',
            startDate: rowData ? dayjs(rowData?.project_st_date) : '',
            endDate: rowData ? dayjs(rowData?.project_en_date) : '',
            amount: rowData?.project_amount || '',
            status: rowData?.status || '',
        });
    };

    useEffect(() => {
        if (openModal) {
            loadData();
        }
    }, [openModal]);

    return (
        <div>
            <Modal
                title={rowData ? 'Update Project' : 'Create Project'}
                confirmLoading={loading3}
                open={openModal}
                okText={rowData ? 'Update' : 'Save'}
                onCancel={() => closeModal()}
                onOk={submitBatchForm}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="projectname" label="Project Name" rules={[{ required: true, message: 'This field is required' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{ required: true, message: 'This field is required' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: 'This field is required' }]}>
                        <DatePicker className="w-100" format="MM-DD-YYYY" />
                    </Form.Item>
                    <Form.Item name="endDate" label="End Date" rules={[{ required: true, message: 'This field is required' }]}>
                        <DatePicker className="w-100" format="MM-DD-YYYY" />
                    </Form.Item>
                    <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'This field is required' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="status" label="Status" rules={[{ required: true, message: 'This field is required' }]}>
                        <Select options={statusOptions} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default AddProjectModal;
